export const updateIntegrationUsedBy = (
  integrationName,
  schema,
  type,
  appName
) => {
  const integration = schema.findBy('secrets.integration', {
    name: integrationName,
  });

  const apps = schema.where('secrets.app', (app) =>
    app.integrationIds?.include(integration.id)
  );

  let { secrets_count = 0, secrets_count_by_app = {} } =
    integration.used_by[type] || {};

  if (appName) {
    secrets_count_by_app = {
      ...secrets_count_by_app,
      [appName]: (secrets_count_by_app[appName] || 0) + 1,
    };
  }
  integration.update({
    used_by: {
      ...integration.used_by,
      [type]: {
        apps_count: apps.length || 1,
        secrets_count: secrets_count + 1,
        secrets_count_by_app,
      },
    },
    updated_at: new Date(),
  });
};
