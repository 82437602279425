import Controller, { type ControllerQueryParam } from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class PaginatedController extends Controller {
  queryParams: ControllerQueryParam[] = [
    'pageSize',
    'nextPageToken',
    'previousPageToken',
    'sortProperty',
    { sortDescending: { type: 'boolean' } },
    'orderBy',
  ];

  @tracked pageSize?: number;
  @tracked nextPageToken?: string;
  @tracked previousPageToken?: string;
  @tracked sortProperty?: string;
  @tracked sortDescending?: boolean;
  @tracked orderBy?: string[];

  resetPagination() {
    this.pageSize = undefined;
    this.nextPageToken = undefined;
    this.previousPageToken = undefined;
  }
}
