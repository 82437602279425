import consulClusterSizesScenario from './consulClusterSizes';
import { BILLING_ACCOUNT_STATUS } from 'billing-common/constants/status';

export default function (server, options = {}) {
  // Set up user scenario.
  consulClusterSizesScenario(server, {
    hasVerifiedEmail: true,
    billingAccountStatus: BILLING_ACCOUNT_STATUS.DELINQUENT,
    ...options,
  });
}
