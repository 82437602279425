import { faker } from '@faker-js/faker';

import baseScenario from './user';
import { TYPE_NETWORK } from 'common/utils/cloud-resource-types';
import { HashicorpCloudNetwork20200907PeeringState } from '@clients/cloud-network';
import { RegionProviders } from 'core/utils/regions';

const baseOptions = {
  createNetwork: false,
  createVault: false,
  createBilling: true,
  hasVerifiedEmail: true,
  createConsul: true,
  consulPrimaryFederatedCluster: false,
  consulSecondaryFederatedCluster: false,
  createPeeringConnection: null,
  createTgwAttachment: null,
};

export const AWS_PEERING_STATES = Object.values(
  HashicorpCloudNetwork20200907PeeringState
);

// Azure Peerings do not have ACCEPTED OR REJECTED STATES
export const AZURE_PEERING_STATES = [
  HashicorpCloudNetwork20200907PeeringState.PENDINGACCEPTANCE,
  HashicorpCloudNetwork20200907PeeringState.ACTIVE,
  HashicorpCloudNetwork20200907PeeringState.UNSET,
  HashicorpCloudNetwork20200907PeeringState.CREATING,
  HashicorpCloudNetwork20200907PeeringState.DELETING,
  HashicorpCloudNetwork20200907PeeringState.FAILED,
  HashicorpCloudNetwork20200907PeeringState.EXPIRED,
];
export const awsHvnId = `${RegionProviders.aws}-hvn`;
export const azureHvnId = `${RegionProviders.azure}-hvn`;

export default function (server, { options = {} } = {}) {
  /**
   * This scenario creates the following:
   * 1.  AWS HVN ("aws-hvn")
   * 2.  a list of AWS Peerings
   * 3.  Azure HVN ("azure-hvn")
   * 4.  a list of  Azure Peerings
   * 5.  create a public and private Consul cluster
   */

  baseScenario(server, { ...baseOptions, ...options });

  let createNetworkAndLocation = (provider, networkId, state) => {
    let project = server.schema.first('resource-manager.project');
    let providerLocation;
    let providerRegion;
    if (provider === RegionProviders.azure) {
      providerRegion = server.create('location.region', {
        region: 'westus2',
        provider: RegionProviders.azure,
      });
      providerLocation = server.create('location.location', {
        organizationId: project.parent.id,
        projectId: project.id,
        regionId: providerRegion.id,
      });
    }
    if (provider === RegionProviders.aws) {
      providerLocation = server.schema.first('location.location');
    }

    let providerNetwork = server.create('network.network', {
      id: networkId,
      state,
      cidr_block: '10.10.0.0/20',
      location: providerLocation,
    });
    server.create('location.link', {
      id: networkId,
      type: TYPE_NETWORK,
      description: `HashiCorp Virtual Network ("${networkId}")`,
      location: providerLocation,
    });
    return providerNetwork;
  };

  // 1. Create AWS HVN
  let awsHvn = createNetworkAndLocation(
    RegionProviders.aws,
    awsHvnId,
    'STABLE'
  );
  // 2.  Create list AWS Peerings in all states
  AWS_PEERING_STATES.map((state) => {
    server.createList('network.peering', 1, {
      hvn: awsHvn,
      state,
    });
  });

  // 3.  Create Azure HVN
  let azureHvn = createNetworkAndLocation(
    RegionProviders.azure,
    azureHvnId,
    'STABLE'
  );

  // 4.  Create list of Azure Peerings in all states
  AZURE_PEERING_STATES.map((state) => {
    server.createList('network.peering', 1, {
      hvn: azureHvn,
      state,
    });
  });

  server.create('consul.version', {
    version: 'v1.14.0',
    status: 'RECOMMENDED',
  });

  let networkLink = server.schema.findBy('location.link', { id: awsHvn.id });
  let azureNetworkLink = server.schema.findBy('location.link', {
    id: azureHvn.id,
  });

  server.create('consul.cluster', {
    id: 'public-cluster',
    consul_version: 'v1.14.0',
    created_at: '2020-10-14T02:16:17.187Z',
    resourceId: faker.string.uuid(),
    config: {
      capacity_config: {
        size: 'SMALL',
      },
      network_config: {
        network: networkLink,
        private: false,
        ip_allowlist: [],
      },
      tier: 'STANDARD',
      primary: null,
    },
    dns_names: {
      public: `public-cluster.public.consul.${awsHvn.id}.aws.hcp.dev`,
      private: `public-cluster.private.consul.${awsHvn.id}.aws.hcp.dev`,
    },
    location: awsHvn.location,
  });

  server.create('consul.cluster', {
    id: 'private-cluster',
    consul_version: 'v1.14.0',
    created_at: '2020-10-14T02:16:17.187Z',
    resourceId: faker.string.uuid(),
    config: {
      capacity_config: {
        size: 'SMALL',
      },
      network_config: {
        network: networkLink,
        private: true,
        ip_allowlist: [],
      },
      tier: 'STANDARD',
      primary: null,
    },
    dns_names: {
      public: `private-cluster.public.consul.${awsHvn.id}.aws.hcp.dev`,
      private: `private-cluster.private.consul.${awsHvn.id}.aws.hcp.dev`,
    },
    location: awsHvn.location,
  });

  server.create('consul.cluster', {
    id: 'public-cluster-azure',
    consul_version: 'v1.14.0',
    created_at: '2020-10-14T02:16:17.187Z',
    resourceId: faker.string.uuid(),
    config: {
      capacity_config: {
        size: 'SMALL',
      },
      network_config: {
        network: azureNetworkLink,
        private: false,
        ip_allowlist: [],
      },
      tier: 'STANDARD',
      primary: null,
    },
    dns_names: {
      public: `public-cluster.public.consul.${azureHvn.id}.aws.hcp.dev`,
      private: `public-cluster.private.consul.${azureHvn.id}.aws.hcp.dev`,
    },
    location: azureHvn.location,
  });

  server.create('consul.cluster', {
    id: 'private-cluster-azure',
    consul_version: 'v1.14.0',
    created_at: '2020-10-14T02:16:17.187Z',
    resourceId: faker.string.uuid(),
    config: {
      capacity_config: {
        size: 'SMALL',
      },
      network_config: {
        network: azureNetworkLink,
        private: true,
        ip_allowlist: [],
      },
      tier: 'STANDARD',
      primary: null,
    },
    dns_names: {
      public: `test-cluster.public.consul.${azureHvn.id}.azure.hcp.dev`,
      private: `test-cluster.private.consul.${azureHvn.id}.azure.hcp.dev`,
    },
    location: azureHvn.location,
  });
}
