import { faker } from '@faker-js/faker';
import {
  ROLE_TYPE_ADMIN,
  ROLE_TYPE_CONTRIBUTOR,
  ROLE_TYPE_OWNER,
  ROLE_TYPE_VIEWER,
} from 'core/helpers/rbac-roles';
import { HashicorpCloudResourcemanagerPolicyBindingMemberType } from '@clients/cloud-resource-manager';
const { USER } = HashicorpCloudResourcemanagerPolicyBindingMemberType;

export const getAccessibleProjects = (server, projects) => {
  let currentUser = server.schema.findBy('iam.user-principal', {
    current_user: true,
  });
  return projects.filter((project) => {
    let organizationPolicies = server.schema.where(
      'resource-manager.organization.policy',
      (policy) => {
        return policy.organizationId === project.parent.id;
      }
    );
    let projectPolicies = server.schema.where(
      'resource-manager.project.policy',
      (policy) => {
        return policy.projectId === project.id;
      }
    );

    let policies = [...organizationPolicies.models, ...projectPolicies.models];

    let hasMembership = false;

    if (policies.length > 0) {
      for (const policy of policies) {
        hasMembership = policy.policy.bindings.find((binding) => {
          return binding.members.find(
            (member) => member.member_id === currentUser.id
          );
        })
          ? true
          : false;
        if (hasMembership) {
          return true;
        }
      }
    }
    return hasMembership;
  });
};

export function createProjectPolicy(schema, { project, user }) {
  const projectPolicy = {
    bindings: [
      {
        role_id: ROLE_TYPE_OWNER.value,
        members: [],
      },
      {
        role_id: ROLE_TYPE_ADMIN.value,
        members: [],
      },
      {
        role_id: ROLE_TYPE_CONTRIBUTOR.value,
        members: [],
      },
      {
        role_id: ROLE_TYPE_VIEWER.value,
        members: [{ member_type: USER, member_id: user.id }],
      },
    ],
    etag: faker.string.alphanumeric(51),
  };

  schema.create('resource-manager.project.policy', {
    project,
    policy: projectPolicy,
  });
}

export function deleteProjectPolicy({ schema }, { project }) {
  schema
    .where(
      'resource-manager.project.policy',
      (policy) => policy.projectId === project.id
    )
    .destroy();
}
