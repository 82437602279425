import BaseAbility from './base-ability';
import { WAYPOINT_ACTIONS, ACTION_RUN } from '../utils/permission-types/index';

export default class extends BaseAbility {
  prefix = WAYPOINT_ACTIONS;

  get canRun() {
    return this.permissions.has(this.generate(ACTION_RUN));
  }
}
