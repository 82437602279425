/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Unit is an enum with all possible measurement units
 * the system accepts.
 *
 *  - HOUR: Valid for static products.
 * @export
 * @enum {string}
 */
export var Billing20201105Unit;
(function (Billing20201105Unit) {
    Billing20201105Unit["NOUNIT"] = "NO_UNIT";
    Billing20201105Unit["HOUR"] = "HOUR";
    Billing20201105Unit["MINUTE"] = "MINUTE";
    Billing20201105Unit["UNIT"] = "UNIT";
    Billing20201105Unit["MONTH"] = "MONTH";
})(Billing20201105Unit || (Billing20201105Unit = {}));
export function Billing20201105UnitFromJSON(json) {
    return Billing20201105UnitFromJSONTyped(json, false);
}
export function Billing20201105UnitFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Billing20201105UnitToJSON(value) {
    return value;
}
