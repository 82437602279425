// mirage doesn't let you use a field other than id to track identity, but for our slug-id models, the id is only unique either within an org or and org and a project
//
export function slugIdAttrs(attrs) {
  let { organization, project, id } = attrs;

  return {
    ...attrs,
    _id: id,
    id: internalIdFor(id, organization?.id, project?.id),
  };
}

export function internalIdFor(id, orgId, projectId) {
  let parts = [id, orgId, projectId];
  // the internal id takes the form of `id:orgId:projectId` but will leave off project id if it's not passed
  return parts.filter(Boolean).join(':');
}
