import { faker } from '@faker-js/faker';
import consulScenario from './consul';

export default function (server, options = {}) {
  // Set up user scenario.
  consulScenario(server, options);

  // Consul scenario creates a single Standard/Small Consul cluster
  let defaultCluster = server.schema.first('consul.cluster');
  let networkId = defaultCluster.config.network_config.network.id;
  let networkLink = server.schema.findBy('location.link', { id: networkId });
  let loc = server.schema.findBy('location.location', {
    organizationId: defaultCluster.location.organizationId,
    projectId: defaultCluster.location.projectId,
    regionId: defaultCluster.location.regionId,
  });

  // Standard/Medium cluster
  server.create('consul.cluster', {
    id: 'standard-medium',
    consul_version: 'v1.8.6',
    created_at: '2020-10-14T02:16:17.187Z',
    resourceId: faker.string.uuid(),
    config: {
      capacity_config: {
        size: 'MEDIUM',
      },
      network_config: {
        network: networkLink,
        private: false,
        ip_allowlist: [],
      },
      tier: 'STANDARD',
      primary: null,
    },
    dns_names: {
      public: `test-cluster.public.consul.${networkId}.aws.hcp.dev`,
      private: `test-cluster.private.consul.${networkId}.aws.hcp.dev`,
    },
    location: loc,
  });
  // Standard/Large cluster
  server.create('consul.cluster', {
    id: 'standard-large',
    consul_version: 'v1.8.6',
    created_at: '2020-10-14T02:16:17.187Z',
    resourceId: faker.string.uuid(),
    config: {
      capacity_config: {
        size: 'LARGE',
      },
      network_config: {
        network: networkLink,
        private: false,
        ip_allowlist: [],
      },
      tier: 'STANDARD',
      primary: null,
    },
    dns_names: {
      public: `test-cluster.public.consul.${networkId}.aws.hcp.dev`,
      private: `test-cluster.private.consul.${networkId}.aws.hcp.dev`,
    },
    location: loc,
  });
}
