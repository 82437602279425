import Route from '@ember/routing/route';

export type Params = {
  pageSize: number;
  nextPageToken: string;
  previousPageToken: string;
  sortProperty: string;
  sortDescending: boolean;
  orderBy: string[];
};

export default class PaginatedRoute extends Route {
  queryParams = {
    pageSize: {
      refreshModel: true,
      as: 'size',
    },
    nextPageToken: {
      refreshModel: true,
      as: 'next',
    },
    previousPageToken: {
      refreshModel: true,
      as: 'prev',
    },
    sortProperty: {
      refreshModel: true,
      as: 'sortby',
    },
    sortDescending: {
      refreshModel: true,
      as: 'sortdescending',
    },
    orderBy: {
      refreshModel: true,
      as: 'orderby',
    },
  };
}
