import { TYPE_NETWORK_PEERING } from 'common/utils/cloud-resource-types';
import baseScenario from './user';

const baseOptions = {
  createNetwork: false,
  createVault: false,
  createBilling: true,
  hasVerifiedEmail: true,
  createConsul: false,
  consulPrimaryFederatedCluster: false,
  consulSecondaryFederatedCluster: false,
  createPeeringConnection: null,
  createTgwAttachment: null,
};

export const hvn1ID = 'hvn-1';
export const hvn3ID = 'hvn-3';
export const hvn1FederatedPeeringID = 'hvn-1-federated-peering';
export const hvn3FederatedPeeringID = 'hvn-3-federated-peering';

export default function (server, { options = {} } = {}) {
  baseScenario(server, { ...baseOptions, ...options });
  let location = server.schema.first('location.location');
  /**
   * This scenario does the following:
   * 1.  Creates two hvns named 'hvn-1' and 'hvn-3'
   * 2.  Creates a federated Peering connection for 'hvn-1' named 'hvn-1-federated-peering'
   * 3.  Creates a federated Peering connection for 'hvn-3' named 'hvn-3-federated-peering'
   * 4.  Creates a route for hvn-1-federated-peering that points to hvn-3 route 10.30.0.0/20
   */

  // ******************** hvn-1 ************************************** //
  //create network
  let hvn1 = server.create('network.network', {
    id: hvn1ID,
    state: 'STABLE',
    cidr_block: '10.10.0.0/20',
    location,
  });

  // ******************** hvn-3 ************************************** //
  let hvn3 = server.create('network.network', {
    id: hvn3ID,
    state: 'STABLE',
    cidr_block: '10.30.0.0/20',
    location,
  });

  // ********************** Create Peering Connections *************************** //
  // Create a Federated Peering Connection for hvn-1
  // we do this by passing in hvn3 to the federatedPeeringTarget
  // this value is then used by the peering factory to create the target.hvn_target.hvn on afterCreate

  let hvn1FederatedPeering = server.create('network.peering', {
    id: hvn1FederatedPeeringID,
    hvn: hvn1,
    state: 'ACTIVE',
    federatedPeeringTarget: hvn3.id,
  });
  // Create a route that is tied to the federated peering connection
  server.create('network.route', {
    id: `${hvn1.id}-tied-to-federated-peering-connection-route`,
    hvn: hvn1,
    destination: hvn3.cidr_block,
    target: {
      hvn_connection: {
        type: TYPE_NETWORK_PEERING,
        id: hvn1FederatedPeering.id,
        location: hvn1.location,
      },
    },
  });

  // Create Non federated peerings for hvn-1
  // these peerings have an aws_target that is created by the network.peering Factory
  server.createList('network.peering', 2, { hvn: hvn1 });

  // Create a Federated Peering Connection for hvn-3
  // we do this by passing in hvn1 to the federatedPeeringTarget
  // this value is then used by the peering factory to create the target.hvn_target.hvn on afterCreate

  let hvn3FederatedPeering = server.create('network.peering', {
    id: hvn3FederatedPeeringID,
    hvn: hvn3,
    state: 'ACTIVE',
    federatedPeeringTarget: hvn1.id,
  });
  // Create a route that is tied to the federated peering connection
  server.create('network.route', {
    id: `${hvn3.id}-tied-to-federated-peering-connection-route`,
    hvn: hvn3,
    destination: hvn3.cidr_block,
    target: {
      hvn_connection: {
        type: TYPE_NETWORK_PEERING,
        id: hvn3FederatedPeering.id,
        location: hvn1.location,
      },
    },
  });

  // Non federated peering for hvn-3 (peering has an aws_target)
  // Create Non federated peerings for hvn-1
  // these peerings have an aws_target that is created by the network.peering Factory
  server.createList('network.peering', 2, { hvn: hvn3 });
}
