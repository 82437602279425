import baseScenario from './user';
import { BILLING_ACCOUNT_STATUS } from 'billing-common/constants/status';

let options = {
  createBilling: true,
  billingAccountStatus: BILLING_ACCOUNT_STATUS.TRIAL,
  billingRemainingBalance: 'lowBalance',
};

export default function (server) {
  return baseScenario(server, options);
}
