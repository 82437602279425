import userScenario from './user';
import { fullPermissions } from '../helpers/permissions';
import {
  BILLING_ACCOUNT_STATUS,
  BILLING_ON_DEMAND_STATUS,
} from 'billing-common/constants/status';

// This is vault with 0 clusters of any kind.
// Useful for testing templates and creating clusters from the "brand new user" perspective.
export default function (server, options = {}) {
  userScenario(server, {
    // vault-specific items
    createVault: false,
    createNetwork: false,
    vaultPackages: {
      development: true,
      standard: true,
      starter: true,
      plus: true,
    },

    // billing
    createBilling: true,
    hasVerifiedEmail: true,
    billingAccountStatus: BILLING_ACCOUNT_STATUS.ACTIVE,
    onDemandBillingMethodDetails: true,
    billingAccountOnDemandStatus: BILLING_ON_DEMAND_STATUS.ACTIVE,

    // misc
    createConsul: false,
    allowedPermissions: fullPermissions,
    ...options,
  });

  // fake some azure network data
  let region = server.create('location.region', {
    region: 'westus2',
    provider: 'azure',
  });
  let project = server.schema.first('resource-manager.project');

  server.create('location.location', {
    organizationId: project.parent.id,
    projectId: project.id,
    regionId: region.id,
  });
}
