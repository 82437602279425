import BaseAbility from './base-ability';
import {
  WAYPOINT_PROJECTS,
  WAYPOINT_PROJECTS_UPDATE,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  prefix = WAYPOINT_PROJECTS;

  get canDisconnectRepo() {
    return this.permissions.has(WAYPOINT_PROJECTS_UPDATE);
  }

  get canAddRepo() {
    return this.permissions.has(WAYPOINT_PROJECTS_UPDATE);
  }
}
