import { TYPE_CONSUL_SNAPSHOT } from 'common/utils/cloud-resource-types';
import userScenario from './user';

export default function (server, options = {}) {
  // Set up user scenario.
  userScenario(server, { createVault: false, createBilling: true, ...options });

  let currentUser = server.schema.findBy('iam.user-principal', {
    current_user: true,
  });
  let org = server.schema.findBy('resource-manager.organization', {
    ownerId: currentUser.id,
  });
  let project = server.schema.findBy('resource-manager.project', {
    parent: {
      type: 'ORGANIZATION',
      id: org.id,
    },
  });
  let loc = server.schema.findBy('location.location', {
    organizationId: org.id,
    projectId: project.id,
  });
  let cluster = server.schema.first('consul.cluster', {
    location: loc,
  });
  let clusterLink = server.schema.findBy('location.link', {
    id: cluster.id,
    locationId: cluster.locationId,
  });
  let snapshotLink = server.create('location.link', {
    id: '5128c845-126a-4d42-b98c-6f06c60005e8',
    location: loc,
    type: TYPE_CONSUL_SNAPSHOT,
    description: `HashiCorp Consul Snapshot ("5128c845-126a-4d42-b98c-6f06c60005e8")`,
  });

  server.create('consul.snapshot', {
    id: snapshotLink.id,
    name: 'November00',
    location: loc,
    resource: clusterLink,
    type: 'MANUAL',
    state: 'READY',
    self_link: snapshotLink,
    created_at: new Date().toISOString(),
    finished_at: new Date().toISOString(),
    meta: {
      size: '5207',
      product_version: '1.1.1',
    },
  });
}
