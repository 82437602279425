import baseScenario from './user';
import { BILLING_ACCOUNT_STATUS } from 'billing-common/constants/status';
import { faker } from '@faker-js/faker';

let noBillingAccountOptions = {
  createNetwork: false,
  createConsul: true,
  createVault: false,
  createBilling: false,
  billingAccountStatus: BILLING_ACCOUNT_STATUS.TRIAL,
  consulPackages: { development: true, standard: false, plus: false },
  hasVerifiedEmail: true,
  vaultPackages: { development: true },
};

export default function (server, { options = {} } = {}) {
  baseScenario(server, { ...noBillingAccountOptions, ...options });

  let location = server.schema.first('location.location');

  let hvn = server.create('network.network', {
    id: 'hvn',
    state: 'STABLE',
    cidr_block: '10.10.0.0/20',
    location,
  });

  let networkLink = server.schema.findBy('location.link', { id: hvn.id });

  server.create('consul.cluster', {
    id: 'test-1',
    consul_version: 'v1.14.0',
    created_at: '2020-10-14T02:16:17.187Z',
    resourceId: faker.string.uuid(),
    config: {
      capacity_config: {
        size: 'X_SMALL',
      },
      network_config: {
        network: networkLink,
        private: false,
        ip_allowlist: [],
      },
      tier: 'DEVELOPMENT',
      primary: null,
    },
    location: location,
  });

  server.create('consul.cluster', {
    id: 'test-2',
    consul_version: 'v1.14.0',
    created_at: '2020-10-14T02:16:17.187Z',
    resourceId: faker.string.uuid(),
    config: {
      capacity_config: {
        size: 'X_SMALL',
      },
      network_config: {
        network: networkLink,
        private: false,
        ip_allowlist: [],
      },
      tier: 'DEVELOPMENT',
      primary: null,
    },
    location: location,
  });

  server.create('consul.cluster', {
    id: 'test-3',
    consul_version: 'v1.14.0',
    created_at: '2020-10-14T02:16:17.187Z',
    resourceId: faker.string.uuid(),
    config: {
      capacity_config: {
        size: 'X_SMALL',
      },
      network_config: {
        network: networkLink,
        private: false,
        ip_allowlist: [],
      },
      tier: 'DEVELOPMENT',
      primary: null,
    },
    location: location,
  });
}
