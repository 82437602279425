import BaseAbility from './base-ability';
import {
  PREFIX_VAULT_SECRETS,
  SECRETS_APP,
} from '../utils/permission-types/index';

export default class VaultSecretsAppAbility extends BaseAbility {
  prefix = `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}`;

  get canList() {
    // This is intentionally set to true because the list permissions might
    // not be reflecting the actual permissions on the secrets app. We might
    // need to query the endpoint itself to see if the user has access to
    // secrets apps.
    return true;
  }
}
