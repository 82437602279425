/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { LogService20210330StreamingCloudwatchLogsProviderFromJSON, LogService20210330StreamingCloudwatchLogsProviderToJSON, LogService20210330StreamingDatadogProviderFromJSON, LogService20210330StreamingDatadogProviderToJSON, LogService20210330StreamingSplunkCloudProviderFromJSON, LogService20210330StreamingSplunkCloudProviderToJSON, ProtobufFieldMaskFromJSON, ProtobufFieldMaskToJSON, } from './';
export function LogService20210330TestDestinationRequestFromJSON(json) {
    return LogService20210330TestDestinationRequestFromJSONTyped(json, false);
}
export function LogService20210330TestDestinationRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'existingDestinationId': !exists(json, 'existing_destination_id') ? undefined : json['existing_destination_id'],
        'mask': !exists(json, 'mask') ? undefined : ProtobufFieldMaskFromJSON(json['mask']),
        'datadog': !exists(json, 'datadog') ? undefined : LogService20210330StreamingDatadogProviderFromJSON(json['datadog']),
        'cloudwatchLogs': !exists(json, 'cloudwatch_logs') ? undefined : LogService20210330StreamingCloudwatchLogsProviderFromJSON(json['cloudwatch_logs']),
        'splunkCloud': !exists(json, 'splunk_cloud') ? undefined : LogService20210330StreamingSplunkCloudProviderFromJSON(json['splunk_cloud']),
    };
}
export function LogService20210330TestDestinationRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'organization_id': value.organizationId,
        'existing_destination_id': value.existingDestinationId,
        'mask': ProtobufFieldMaskToJSON(value.mask),
        'datadog': LogService20210330StreamingDatadogProviderToJSON(value.datadog),
        'cloudwatch_logs': LogService20210330StreamingCloudwatchLogsProviderToJSON(value.cloudwatchLogs),
        'splunk_cloud': LogService20210330StreamingSplunkCloudProviderToJSON(value.splunkCloud),
    };
}
