import userScenario from './user';
import { BILLING_ON_DEMAND_STATUS } from 'billing-common/constants/status';

let options = {
  createBilling: true,
  billingAccountStatus: BILLING_ON_DEMAND_STATUS.ACTIVE,
  createConsul: false,
  createNetwork: false,
  createVault: false,
  hasVerifiedEmail: true,
  createServicePrincipals: { organization: false, project: false },
};

export default function (server) {
  return userScenario(server, options);
}
