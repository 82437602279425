import getPlatformContext from './get-platform-context';

export function packerRegistry(
  server,
  featureTier = 'STANDARD',
  activated = true
) {
  const { organization, project } = getPlatformContext(server);
  const location = server.create('location.location', {
    organizationId: organization.id,
    projectId: project.id,
    regionId: null,
  });

  server.create('packer.registry', {
    organization,
    project,
    config: { featureTier, activated },
    location,
  });
}

export function packerBasics(server) {
  const { organization, project } = getPlatformContext(server);

  server.create('packer.runtask', {
    organization,
    project,
  });

  const firstBucket = server.create('packer.bucket', 'noDescription', {
    organization,
    project,
  });

  server.create('packer.bucket', { organization, project });
  server.create('packer.bucket', { organization, project });
  server.create('packer.bucket', {
    slug: 'no-platforms',
    platforms: '',
    organization,
    project,
  });

  server.createList('packer.bucket', 8, {
    organization,
    project,
  });

  server.create('packer.iteration', 'revoked', {
    organization,
    project,
    bucket: firstBucket,
  });

  server.create('packer.iteration', 'scheduledRevoke', {
    organization,
    project,
    bucket: firstBucket,
  });
}
