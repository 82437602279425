export function findLocationIdsForProject(
  schema,
  { organizationId, projectId }
) {
  let { models = [] } = schema.where('location.location', {
    organizationId,
    projectId,
  });
  return models.map((model) => model.id);
}

export function findResourceWithLocationId(
  schema,
  { organizationId, projectId, resourcePath },
  predicateFn = () => true
) {
  return schema.findBy(resourcePath, (resource) => {
    let locationIds = findLocationIdsForProject(schema, {
      organizationId,
      projectId,
    });
    return locationIds.includes(resource.locationId) && predicateFn(resource);
  });
}

export function findNetworkAndNetworkLocation(
  schema,
  { networkId, organizationId, projectId }
) {
  let network = findResourceWithLocationId(
    schema,
    { organizationId, projectId, resourcePath: 'network.network' },
    (resource) => resource.id === networkId
  );
  let location = schema.findBy('location.location', {
    id: network.locationId,
    organizationId,
    projectId,
  });

  return { network, location };
}
