import userScenario from './user';
import {
  HashicorpCloudBoundary20211221ClusterState as ClusterState,
  HashicorpCloudOperationOperationState as OperationState,
} from '@clients/cloud-boundary-service';
import { TYPE_BOUNDARY } from 'common/utils/cloud-resource-types';
import { wait } from '../helpers/wait';

export default function (server, options = {}) {
  userScenario(server, {
    createNetwork: false,
    createBilling: true,
    createConsul: false,
    createVault: false,
    hasVerifiedEmail: true,
    ...options,
  });

  let { schema } = server;
  const location = schema.first('location.location');
  const cluster = server.create('boundary.cluster', {
    location,
    state: ClusterState.RUNNING,
  });

  const link = schema.findOrCreateBy('location.link', {
    location,
    id: cluster.id,
    uuid: cluster.id,
    type: TYPE_BOUNDARY,
  });
  const operation = schema.create('operation.operation', {
    location,
    link,
    state: OperationState.PENDING,
    created_at: new Date().toISOString(),
  });

  (async () => {
    await wait(5000);

    cluster.update({ state: ClusterState.UPDATING });
    operation.update({ state: OperationState.RUNNING });

    await wait(7000);

    cluster.update({ state: ClusterState.RUNNING });
    operation.update({
      state: OperationState.DONE,
      updatedAt: new Date().toISOString(),
    });
  })();
}
