import userScenario from './user';
import { fullPermissions } from '../helpers/permissions';
import {
  BILLING_ACCOUNT_STATUS,
  BILLING_ON_DEMAND_STATUS,
} from 'billing-common/constants/status';

/**
 * Base scenario for Vault Radar
 * Currently, this doesn't do much more than the default user scenario other than
 * setting up the resource items for Vault Radar activeness checks, since Vault Radar is
 * a separate app. Once Vault Radar is migrated into HCP, this will be where
 * the Vault Radar-specific setup items will go.
 */

export default function (server, options = {}) {
  userScenario(server, {
    // vault-specific items
    createVault: {
      development: true,
      standard: true,
      starter: true,
      plus: true,
    },
    vaultPackages: {
      development: true,
      standard: true,
      starter: true,
      plus: true,
    },

    // billing
    createBilling: true,
    hasVerifiedEmail: true,
    billingAccountStatus: BILLING_ACCOUNT_STATUS.ACTIVE,
    onDemandBillingMethodDetails: true,
    billingAccountOnDemandStatus: BILLING_ON_DEMAND_STATUS.ACTIVE,

    // misc
    createConsul: false,
    createVaultRadar: true,
    allowedPermissions: fullPermissions,
    ...options,
  });
}
