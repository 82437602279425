import BaseAbility from './base-ability';
import { inject as service } from '@ember/service';
import { variation } from 'ember-launch-darkly';
import { HashicorpCloudIamUserPrincipalIdentityType } from '@clients/cloud-iam';

import {
  PREFIX_IAM_USERS,
  ACTION_DELETE,
} from '../utils/permission-types/index';

const SSO_IDENTITY_TYPES = [
  HashicorpCloudIamUserPrincipalIdentityType.HASHICORPSSO,
  HashicorpCloudIamUserPrincipalIdentityType.SAMLP,
];

export default class extends BaseAbility {
  @service currentUser;

  prefix = PREFIX_IAM_USERS;

  /**
   * Determine if an sso delete is possible on a user based on a feature flag
   * and if some of the user identity types are SSO.
   *
   * @return {boolean}
   */
  get canSsoDelete() {
    const { identityTypes = [] } = this.model ?? {};

    return (
      variation('hcp-iam-sso-user-deletion') &&
      identityTypes.some((type) => {
        return SSO_IDENTITY_TYPES.includes(type);
      })
    );
  }

  /**
   * Determine if update is available based and policy and currentUser.
   *
   * @return {boolean}
   */
  get canUpdate() {
    const { id } = this.model ?? {};
    const { id: currentUserId } = this.currentUser.user;
    const canEditOrganization =
      this.orgPolicy?.canMemberEditPolicy(currentUserId);
    const isOrganizationOwner = this.orgPolicy?.isMemberOwner(id);

    // They cannot edit if they don't have any policy access.
    if (!this.orgPolicy) {
      return false;
    }

    // They cannot edit if they don't have any edit access.
    if (!canEditOrganization) {
      return false;
    }

    // They cannot edit the owner.
    if (isOrganizationOwner) {
      return false;
    }

    // They cannot edit themselves.
    if (currentUserId === id) {
      return false;
    }

    return true;
  }

  /**
   * Currently, all users can query the organization user list. This getter
   * overrides the BaseAbility check for permission strings.
   *
   * @return {boolean}
   */
  get canList() {
    return true;
  }

  /**
   * Determine if delete is available based on permissions and the current user.
   *
   * @return {boolean}
   */
  get canDelete() {
    const { id } = this.model ?? {};
    const isOrganizationOwner = this.orgPolicy?.isMemberOwner(id);

    return (
      !isOrganizationOwner &&
      this.permissions.has(this.generate(ACTION_DELETE)) &&
      this.currentUser?.user?.id !== this.model.id
    );
  }
}
