import { faker } from '@faker-js/faker';

import userScenario from './user';
import createPermissions, {
  createProjectPermissions,
  noRolePermissions,
} from '../helpers/permissions';
import { createProjectPolicy } from '../helpers/project-policy';
const options = {
  createBilling: true,
  createGroups: true,
};

export default function (server) {
  userScenario(server, options);

  const currentUserPrincipal = server.schema.findBy('iam.user-principal', {
    current_user: true,
  });

  currentUserPrincipal.update({ current_user: false });

  const defaultOrganization = server.schema.findBy(
    'resource-manager.organization',
    {
      ownerId: currentUserPrincipal.id,
    }
  );

  const allDefaultOrganizationProjects = server.schema.where(
    'resource-manager.project',
    (model) => {
      return model.parent.id === defaultOrganization.id;
    }
  );

  const noRoleUserPrincipal = server.create('iam.user-principal', {
    full_name: 'Noah Rohl',
    email: 'noaccess@hashicorp.com',
    current_user: true,
  });

  server.create('iam.organization-membership', {
    created_at: faker.date.past().toISOString(),
    organization: defaultOrganization,
    principal: noRoleUserPrincipal,
  });

  createPermissions(server, {
    organization: defaultOrganization,
    user: noRoleUserPrincipal,
    allowedPermissions: noRolePermissions,
  });

  for (let project of allDefaultOrganizationProjects.models) {
    createProjectPermissions(server, {
      organization: defaultOrganization,
      project: project,
      user: noRoleUserPrincipal,
      allowedPermissions: noRolePermissions,
    });

    createProjectPolicy(server, { project, user: noRoleUserPrincipal });
  }

  return defaultOrganization;
}
