// @ts-nocheck
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface MirageDevToolsSignature {
  // Args: {};
}

export default class MirageDevToolsComponent extends Component<MirageDevToolsSignature> {
  @service('mirage-scenario') mirage;
  @tracked showModal = false;
  @tracked isLoggingEnabled = !!window.mirage.logging;
  @tracked logEverything = !!window.mirage.logEverything;
  @tracked fakerSeed = this.mirage.getFakerSeed();

  get destinationElement() {
    return document.body;
  }

  get scenarios() {
    return this.mirage.listScenarios();
  }

  get shouldShowMirageDevTools() {
    return !!window?.mirage;
  }

  get scenario() {
    return this.mirage.getScenario();
  }

  get timings() {
    return this.mirage.listTimings();
  }

  get timing() {
    return this.mirage.getTiming();
  }

  @action
  setScenario(event) {
    const { value } = event.target;
    this.mirage.setScenario(value);
    window.location.reload();
  }

  @action
  setTiming({ target: { value } }) {
    this.mirage.setTiming(value);
    window.location.reload();
  }

  @action
  setFakerSeed({ target: { value } }) {
    this.mirage.setFakerSeed(value);
    this.fakerSeed = this.mirage.getFakerSeed();
    window.location.reload();
  }

  @action
  rollFakerSeed() {
    this.mirage.setFakerSeed(Math.round(Math.random() * 20000));
    this.fakerSeed = this.mirage.getFakerSeed();
    window.location.reload();
  }

  @action
  toggleModal(openVal) {
    this.showModal = openVal ?? !this.showModal;
  }

  @action toggleLogging() {
    window.mirage.logging = !this.isLoggingEnabled;
    this.isLoggingEnabled = !this.isLoggingEnabled;
  }

  @action toggleLogEverything(ev) {
    if (!this.isLoggingEnabled && ev.target.checked) {
      window.mirage.logging = this.isLoggingEnabled = true;
    }
    // This is monkey-patched onto Mirage, it's not an official API
    window.mirage.logEverything = ev.target.checked;
    this.logEverything = ev.target.checked;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    MirageDevTools: typeof MirageDevToolsComponent;
    'mirage-dev-tools': typeof MirageDevToolsComponent;
  }
}
