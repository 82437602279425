import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import { TFC_GEO_REGIONS } from '../utils/constants.ts';
export default class TerraformDeploymentUrl extends Helper {
  @service('terraform-unification') terraformUnification;

  compute([
    geo = TFC_GEO_REGIONS.REGION_US,
    resourceType = null,
    terraformOrganizationName = null,
    projectId = null,
  ]) {
    return this.terraformUnification.getDeploymentUrl({
      geo,
      resourceType,
      resourceIdentifiers: {
        terraformOrganizationName,
        projectId,
      },
    });
  }
}
