import userScenario from './user';

let options = {
  createBilling: true,
  samlSsoConfigured: true,
  scimEnabled: true,
  createGroups: true,
};

export default function (server) {
  return userScenario(server, options);
}
