import { faker } from '@faker-js/faker';

import noRoleScenario from './no-role';
import createPermissions, {
  createProjectPermissions,
  noRolePermissions,
} from '../helpers/permissions';

export default function (server) {
  noRoleScenario(server);

  const secondOrganization = server.schema.findBy(
    'resource-manager.organization',
    {
      name: 'org-the-second',
    }
  );

  const allSecondOrganizationProjects = server.schema.where(
    'resource-manager.project',
    (model) => {
      return model.parent.id === secondOrganization.id;
    }
  );

  const noRoleUserPrincipal = server.schema.findBy('iam.user-principal', {
    current_user: true,
  });

  server.create('iam.organization-membership', {
    created_at: faker.date.past().toISOString(),
    organization: secondOrganization,
    principal: noRoleUserPrincipal,
  });

  createPermissions(server, {
    organization: secondOrganization,
    user: noRoleUserPrincipal,
    allowedPermissions: noRolePermissions,
  });

  for (let project of allSecondOrganizationProjects.models) {
    createProjectPermissions(server, {
      organization: secondOrganization,
      project: project,
      user: noRoleUserPrincipal,
      allowedPermissions: noRolePermissions,
    });
  }
}
