import {
  PREFIX_BILLING_ACCOUNTS,
  ACTION_TOKENIZE_PAYMENT_DETAILS,
  ACTION_UPDATE,
} from 'authz/utils/permission-types/index';
import baseScenario from './user';

export default function (
  server,
  { scenario = baseScenario, serverOptions } = {}
) {
  serverOptions ? scenario(server, serverOptions) : scenario(server);
  let project = server.schema.first('resource-manager.project');
  let orgPermissions = server.schema.findBy(
    'resource-manager.allowed-permission',
    {
      organizationId: project.parent.id,
    }
  );
  let allowedOrgPermissions = orgPermissions.allowed_permissions.filter(
    (permission) =>
      permission !==
        `${PREFIX_BILLING_ACCOUNTS}.${ACTION_TOKENIZE_PAYMENT_DETAILS}` &&
      permission !== `${PREFIX_BILLING_ACCOUNTS}.${ACTION_UPDATE}`
  );
  orgPermissions.update({ allowed_permissions: allowedOrgPermissions });
  let projectPermissions = server.schema.findBy(
    'resource-manager.project.allowed-permission',
    {
      projectId: project.id,
      organizationId: project.parent.id,
    }
  );
  let allowedProjectPermissions = projectPermissions.allowed_permissions.filter(
    (permission) =>
      permission !==
        `${PREFIX_BILLING_ACCOUNTS}.${ACTION_TOKENIZE_PAYMENT_DETAILS}` &&
      permission !== `${PREFIX_BILLING_ACCOUNTS}.${ACTION_UPDATE}`
  );
  projectPermissions.update({
    allowed_permissions: allowedProjectPermissions,
  });
}
