import {
  PREFIX_BILLING_ACCOUNTS,
  PREFIX_NETWORK_HVNS,
  PREFIX_NETWORK_ROUTES,
  PREFIX_NETWORK_PEERINGS,
  PREFIX_NETWORK_TRANSIT_GATEWAY_ATTACHMENTS,
  PREFIX_CONSUL_CLUSTERS,
  PREFIX_CONSUL_GNM_CLUSTERS_SERVERS,
  PREFIX_CONSUL_GNM_CLUSTERS,
  PREFIX_CONSUL_GNM_OBSERVABILITY,
  PREFIX_CONSUL_GNM_PEERING_CONNECTIONS,
  PREFIX_CONSUL_SNAPSHOTS,
  PREFIX_IAM_AUTH_CONNECTION,
  PREFIX_IAM_GROUPS,
  PREFIX_IAM_INVITATIONS,
  PREFIX_IAM_USERS,
  PREFIX_IAM_SERVICE_PRINCIPALS,
  PREFIX_IAM_WORKLOAD_IDENTITY_PROVIDERS,
  PREFIX_IAM_SSO,
  PREFIX_IDP_SCIM,
  PREFIX_LOG_ENTRIES,
  PREFIX_RESOURCE_MANAGER_ORGANIZATIONS,
  PREFIX_RESOURCE_MANAGER_PROJECTS,
  PREFIX_RESOURCE_MANAGER_RESOURCES,
  PREFIX_TERRAFORM_WORKSPACES,
  PREFIX_PACKER_REGISTRIES,
  PREFIX_PACKER_IMAGES,
  PREFIX_PACKER_ITERATIONS,
  PREFIX_PACKER_CHANNELS,
  PREFIX_WEBHOOK_WEBHOOKS,
  ACTION_ASSIGN_PROJECT,
  ACTION_CREATE,
  ACTION_CREATE_ROOT_TOKEN,
  ACTION_CREATE_TOKEN,
  ACTION_DELETE,
  ACTION_DELETE_TOKEN,
  ACTION_DISABLE,
  ACTION_ENABLE,
  ACTION_GET,
  ACTION_GET_CLIENT_CONFIG,
  ACTION_GET_API_INFORMATION,
  ACTION_GET_IAM_POLICY,
  ACTION_GET_SECRETS,
  ACTION_GET_CREDENTIALS,
  ACTION_LIST,
  ACTION_LIST_MEMBERS,
  ACTION_LIST_ROLES,
  ACTION_LIST_TOKENS,
  ACTION_SET_IAM_POLICY,
  ACTION_SET_NAME,
  ACTION_UPDATE_MEMBERS,
  ACTION_TOKENIZE_PAYMENT_DETAILS,
  ACTION_UNASSIGN_PROJECT,
  ACTION_UPDATE,
  SECRETS_ADMIN_ROLE_PERMISSIONS,
  SECRETS_CONTRIBUTOR_ROLE_PERMISSIONS,
  SECRETS_VIEWER_ROLE_PERMISSIONS,
  VAULT_ADMIN_ROLE_PERMISSIONS,
  VAULT_CONTRIBUTOR_ROLE_PERMISSIONS,
  VAULT_VIEWER_ROLE_PERMISSIONS,
  PREFIX_RESOURCE_MANAGER_ROLES,
  PREFIX_BOUNDARY_CLUSTERS,
  WAYPOINT_ADMIN_ROLE_PERMISSIONS,
  WAYPOINT_CONTRIBUTOR_ROLE_PERMISSIONS,
  WAYPOINT_VIEWER_ROLE_PERMISSIONS,
  VAULT_RADAR_ADMIN_PERMISSIONS,
  VAULT_RADAR_CONTRIBUTOR_PERMISSIONS,
  VAULT_RADAR_VIEWER_PERMISSIONS,
} from 'authz/utils/permission-types/index';

import {
  ALL_PERMISSIONS as VAGRANT_ALL_PERMISSIONS,
  CONTRIBUTOR_PERMISSIONS as VAGRANT_CONTRIBUTOR_PERMISSIONS,
  VIEWER_PERMISSIONS as VAGRANT_VIEWER_PERMISSIONS,
} from './vagrant/permissions';

import {
  SECRETS_APP_MANAGER_ROLE_PERMISSIONS,
  SECRETS_SECRET_READER_ROLE_PERMISSIONS,
} from 'authz/utils/permission-types/secrets';

import {
  ALL_PERMISSIONS as WEBHOOKS_ALL_PERMISSIONS,
  CONTRIBUTOR_PERMISSIONS as WEBHOOKS_CONTRIBUTOR_PERMISSIONS,
  VIEWER_PERMISSIONS as WEBHOOKS_VIEWER_PERMISSIONS,
} from './webhooks/permissions';

const extractMirageOverrides = () => {
  const json = sessionStorage.getItem('mirage::hvs-role');

  if (!json) return { organization: { role: '' }, project: { role: '' } };

  try {
    return JSON.parse(json);
  } catch {
    return { organization: { role: '' }, project: { role: '' } };
  }
};

const buildAllowedPermissions = (requestedUiScenario) => {
  switch (requestedUiScenario) {
    case 'admin':
    case 'owner':
      return fullPermissions;
    case 'contributor':
      return contributorPermissions;
    case 'viewer':
      return viewerPermissions;
    case 'secrets.app-secret-reader':
      return SECRETS_SECRET_READER_ROLE_PERMISSIONS;
    case 'secrets.app-manager':
      return SECRETS_APP_MANAGER_ROLE_PERMISSIONS;
    case 'no-role':
      return noRolePermissions;
  }
};

export const fullPermissions = [
  `${PREFIX_NETWORK_ROUTES}.${ACTION_LIST}`,
  `${PREFIX_NETWORK_ROUTES}.${ACTION_CREATE}`,
  `${PREFIX_NETWORK_ROUTES}.${ACTION_DELETE}`,
  `${PREFIX_NETWORK_PEERINGS}.${ACTION_LIST}`,
  `${PREFIX_NETWORK_TRANSIT_GATEWAY_ATTACHMENTS}.${ACTION_LIST}`,
  `${PREFIX_CONSUL_CLUSTERS}.${ACTION_LIST}`,
  `${PREFIX_CONSUL_CLUSTERS}.${ACTION_DELETE}`,
  `${PREFIX_CONSUL_CLUSTERS}.${ACTION_CREATE_ROOT_TOKEN}`,
  `${PREFIX_CONSUL_CLUSTERS}.${ACTION_GET_CLIENT_CONFIG}`,
  `${PREFIX_NETWORK_HVNS}.${ACTION_CREATE}`,
  `${PREFIX_NETWORK_HVNS}.${ACTION_DELETE}`,
  `${PREFIX_NETWORK_HVNS}.${ACTION_LIST}`,
  `${PREFIX_NETWORK_PEERINGS}.${ACTION_CREATE}`,
  `${PREFIX_NETWORK_TRANSIT_GATEWAY_ATTACHMENTS}.${ACTION_CREATE}`,
  `${PREFIX_CONSUL_CLUSTERS}.${ACTION_CREATE}`,
  `${PREFIX_CONSUL_CLUSTERS}.${ACTION_DELETE}`,
  `${PREFIX_CONSUL_CLUSTERS}.${ACTION_UPDATE}`,
  `${PREFIX_CONSUL_SNAPSHOTS}.${ACTION_CREATE}`,
  `${PREFIX_CONSUL_SNAPSHOTS}.${ACTION_DELETE}`,
  `${PREFIX_CONSUL_SNAPSHOTS}.${ACTION_UPDATE}`,
  `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_CREATE}`,
  `${PREFIX_CONSUL_GNM_PEERING_CONNECTIONS}.${ACTION_CREATE}`,
  `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_DELETE}`,
  `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_LIST}`,
  `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_GET}`,
  `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_GET_API_INFORMATION}`,
  `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_GET_SECRETS}`,
  `${PREFIX_CONSUL_GNM_OBSERVABILITY}.${ACTION_GET_CREDENTIALS}`,
  `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_UPDATE}`,
  `${PREFIX_CONSUL_GNM_PEERING_CONNECTIONS}.${ACTION_DELETE}`,
  `${PREFIX_CONSUL_GNM_CLUSTERS_SERVERS}.${ACTION_LIST}`,
  `${PREFIX_IAM_INVITATIONS}.${ACTION_CREATE}`,
  `${PREFIX_IAM_INVITATIONS}.${ACTION_DELETE}`,
  `${PREFIX_IAM_INVITATIONS}.${ACTION_LIST}`,
  `${PREFIX_IAM_USERS}.${ACTION_LIST}`,
  `${PREFIX_BILLING_ACCOUNTS}.${ACTION_ASSIGN_PROJECT}`,
  `${PREFIX_BILLING_ACCOUNTS}.${ACTION_DELETE}`,
  `${PREFIX_BILLING_ACCOUNTS}.${ACTION_GET}`,
  `${PREFIX_BILLING_ACCOUNTS}.${ACTION_LIST}`,
  `${PREFIX_BILLING_ACCOUNTS}.${ACTION_TOKENIZE_PAYMENT_DETAILS}`,
  `${PREFIX_BILLING_ACCOUNTS}.${ACTION_UNASSIGN_PROJECT}`,
  `${PREFIX_BILLING_ACCOUNTS}.${ACTION_UPDATE}`,
  `${PREFIX_IAM_AUTH_CONNECTION}.${ACTION_CREATE}`,
  `${PREFIX_IAM_AUTH_CONNECTION}.${ACTION_DELETE}`,
  `${PREFIX_IAM_AUTH_CONNECTION}.${ACTION_GET}`,
  `${PREFIX_IAM_AUTH_CONNECTION}.${ACTION_UPDATE}`,
  `${PREFIX_IAM_GROUPS}.${ACTION_CREATE}`,
  `${PREFIX_IAM_GROUPS}.${ACTION_DELETE}`,
  `${PREFIX_IAM_GROUPS}.${ACTION_GET}`,
  `${PREFIX_IAM_GROUPS}.${ACTION_LIST_MEMBERS}`,
  `${PREFIX_IAM_GROUPS}.${ACTION_UPDATE}`,
  `${PREFIX_IAM_GROUPS}.${ACTION_UPDATE_MEMBERS}`,
  `${PREFIX_IAM_SERVICE_PRINCIPALS}.${ACTION_CREATE}`,
  `${PREFIX_IAM_SERVICE_PRINCIPALS}.${ACTION_UPDATE}`,
  `${PREFIX_IAM_SERVICE_PRINCIPALS}.${ACTION_DELETE}`,
  `${PREFIX_IAM_SERVICE_PRINCIPALS}.${ACTION_GET}`,
  `${PREFIX_IAM_SERVICE_PRINCIPALS}.${ACTION_LIST}`,
  `${PREFIX_IAM_WORKLOAD_IDENTITY_PROVIDERS}.${ACTION_CREATE}`,
  `${PREFIX_IAM_WORKLOAD_IDENTITY_PROVIDERS}.${ACTION_DELETE}`,
  `${PREFIX_IAM_WORKLOAD_IDENTITY_PROVIDERS}.${ACTION_GET}`,
  `${PREFIX_IAM_WORKLOAD_IDENTITY_PROVIDERS}.${ACTION_LIST}`,
  `${PREFIX_IAM_WORKLOAD_IDENTITY_PROVIDERS}.${ACTION_UPDATE}`,
  `${PREFIX_IAM_SSO}.${ACTION_CREATE}`,
  `${PREFIX_IAM_SSO}.${ACTION_DELETE}`,
  `${PREFIX_IAM_SSO}.${ACTION_GET}`,
  `${PREFIX_IAM_SSO}.${ACTION_LIST}`,
  `${PREFIX_IAM_SSO}.${ACTION_UPDATE}`,
  `${PREFIX_IDP_SCIM}.${ACTION_CREATE_TOKEN}`,
  `${PREFIX_IDP_SCIM}.${ACTION_DELETE_TOKEN}`,
  `${PREFIX_IDP_SCIM}.${ACTION_DISABLE}`,
  `${PREFIX_IDP_SCIM}.${ACTION_ENABLE}`,
  `${PREFIX_IDP_SCIM}.${ACTION_GET}`,
  `${PREFIX_IDP_SCIM}.${ACTION_LIST_TOKENS}`,
  `${PREFIX_IAM_USERS}.${ACTION_DELETE}`,
  `${PREFIX_IAM_USERS}.${ACTION_GET}`,
  `${PREFIX_IAM_USERS}.${ACTION_LIST}`,
  `${PREFIX_LOG_ENTRIES}.${ACTION_GET}`,
  `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_SET_NAME}`,
  `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_LIST_ROLES}`,
  `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_GET_IAM_POLICY}`,
  `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_SET_IAM_POLICY}`,
  `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_CREATE}`,
  `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_DELETE}`,
  `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_GET}`,
  `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_LIST}`,
  `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_UPDATE}`,
  `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_GET_IAM_POLICY}`,
  `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_SET_IAM_POLICY}`,
  `${PREFIX_RESOURCE_MANAGER_RESOURCES}.${ACTION_LIST}`,
  `${PREFIX_RESOURCE_MANAGER_RESOURCES}.${ACTION_LIST_ROLES}`,
  `${PREFIX_TERRAFORM_WORKSPACES}.${ACTION_LIST}`,
  `${PREFIX_PACKER_REGISTRIES}.${ACTION_CREATE}`,
  `${PREFIX_PACKER_REGISTRIES}.${ACTION_UPDATE}`,
  `${PREFIX_PACKER_REGISTRIES}.${ACTION_GET}`,
  `${PREFIX_PACKER_REGISTRIES}.${ACTION_LIST}`,
  `${PREFIX_PACKER_REGISTRIES}.${ACTION_DELETE}`,
  `${PREFIX_PACKER_IMAGES}.${ACTION_CREATE}`,
  `${PREFIX_PACKER_IMAGES}.${ACTION_UPDATE}`,
  `${PREFIX_PACKER_IMAGES}.${ACTION_GET}`,
  `${PREFIX_PACKER_IMAGES}.${ACTION_LIST}`,
  `${PREFIX_PACKER_IMAGES}.${ACTION_DELETE}`,
  `${PREFIX_PACKER_ITERATIONS}.${ACTION_CREATE}`,
  `${PREFIX_PACKER_ITERATIONS}.${ACTION_UPDATE}`,
  `${PREFIX_PACKER_ITERATIONS}.${ACTION_GET}`,
  `${PREFIX_PACKER_ITERATIONS}.${ACTION_LIST}`,
  `${PREFIX_PACKER_ITERATIONS}.${ACTION_DELETE}`,
  `${PREFIX_PACKER_CHANNELS}.${ACTION_CREATE}`,
  `${PREFIX_PACKER_CHANNELS}.${ACTION_UPDATE}`,
  `${PREFIX_PACKER_CHANNELS}.${ACTION_GET}`,
  `${PREFIX_PACKER_CHANNELS}.${ACTION_LIST}`,
  `${PREFIX_PACKER_CHANNELS}.${ACTION_DELETE}`,
  `${PREFIX_RESOURCE_MANAGER_ROLES}.${ACTION_LIST}`,
  `${PREFIX_RESOURCE_MANAGER_ROLES}.${ACTION_GET}`,
  `${PREFIX_BOUNDARY_CLUSTERS}.${ACTION_CREATE}`,
  `${PREFIX_WEBHOOK_WEBHOOKS}.${ACTION_LIST}`,
  ...SECRETS_ADMIN_ROLE_PERMISSIONS,
  ...VAULT_RADAR_ADMIN_PERMISSIONS,
  ...VAULT_ADMIN_ROLE_PERMISSIONS,
  ...WAYPOINT_ADMIN_ROLE_PERMISSIONS,
  ...VAGRANT_ALL_PERMISSIONS,
  ...WEBHOOKS_ALL_PERMISSIONS,
];

export const contributorPermissions = [
  `${PREFIX_NETWORK_ROUTES}.${ACTION_LIST}`,
  `${PREFIX_NETWORK_ROUTES}.${ACTION_CREATE}`,
  `${PREFIX_NETWORK_ROUTES}.${ACTION_DELETE}`,
  `${PREFIX_NETWORK_PEERINGS}.${ACTION_LIST}`,
  `${PREFIX_NETWORK_TRANSIT_GATEWAY_ATTACHMENTS}.${ACTION_LIST}`,
  `${PREFIX_CONSUL_CLUSTERS}.${ACTION_LIST}`,
  `${PREFIX_CONSUL_CLUSTERS}.${ACTION_DELETE}`,
  `${PREFIX_CONSUL_CLUSTERS}.${ACTION_CREATE_ROOT_TOKEN}`,
  `${PREFIX_CONSUL_CLUSTERS}.${ACTION_GET_CLIENT_CONFIG}`,
  `${PREFIX_IAM_INVITATIONS}.${ACTION_LIST}`,
  `${PREFIX_NETWORK_HVNS}.${ACTION_CREATE}`,
  `${PREFIX_NETWORK_HVNS}.${ACTION_DELETE}`,
  `${PREFIX_NETWORK_HVNS}.${ACTION_LIST}`,
  `${PREFIX_NETWORK_PEERINGS}.${ACTION_CREATE}`,
  `${PREFIX_NETWORK_TRANSIT_GATEWAY_ATTACHMENTS}.${ACTION_CREATE}`,
  `${PREFIX_CONSUL_CLUSTERS}.${ACTION_CREATE}`,
  `${PREFIX_CONSUL_CLUSTERS}.${ACTION_DELETE}`,
  `${PREFIX_CONSUL_CLUSTERS}.${ACTION_UPDATE}`,
  `${PREFIX_CONSUL_SNAPSHOTS}.${ACTION_CREATE}`,
  `${PREFIX_CONSUL_SNAPSHOTS}.${ACTION_DELETE}`,
  `${PREFIX_CONSUL_SNAPSHOTS}.${ACTION_UPDATE}`,
  `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_CREATE}`,
  `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_UPDATE}`,
  `${PREFIX_CONSUL_GNM_PEERING_CONNECTIONS}.${ACTION_CREATE}`,
  `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_DELETE}`,
  `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_LIST}`,
  `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_GET}`,
  `${PREFIX_CONSUL_GNM_PEERING_CONNECTIONS}.${ACTION_DELETE}`,
  `${PREFIX_CONSUL_GNM_CLUSTERS_SERVERS}.${ACTION_LIST}`,
  `${PREFIX_IAM_USERS}.${ACTION_LIST}`,
  `${PREFIX_BILLING_ACCOUNTS}.${ACTION_ASSIGN_PROJECT}`,
  `${PREFIX_BILLING_ACCOUNTS}.${ACTION_DELETE}`,
  `${PREFIX_BILLING_ACCOUNTS}.${ACTION_GET}`,
  `${PREFIX_BILLING_ACCOUNTS}.${ACTION_LIST}`,
  `${PREFIX_BILLING_ACCOUNTS}.${ACTION_TOKENIZE_PAYMENT_DETAILS}`,
  `${PREFIX_BILLING_ACCOUNTS}.${ACTION_UNASSIGN_PROJECT}`,
  `${PREFIX_BILLING_ACCOUNTS}.${ACTION_UPDATE}`,
  `${PREFIX_IAM_SSO}.${ACTION_CREATE}`,
  `${PREFIX_IAM_SSO}.${ACTION_DELETE}`,
  `${PREFIX_IAM_SSO}.${ACTION_GET}`,
  `${PREFIX_IAM_SSO}.${ACTION_LIST}`,
  `${PREFIX_IAM_SSO}.${ACTION_UPDATE}`,
  `${PREFIX_IAM_USERS}.${ACTION_LIST}`,
  `${PREFIX_IAM_USERS}.${ACTION_GET}`,
  `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_SET_NAME}`,
  `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_LIST_ROLES}`,
  `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_GET_IAM_POLICY}`,
  `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_SET_IAM_POLICY}`,
  `${PREFIX_TERRAFORM_WORKSPACES}.${ACTION_LIST}`,
  `${PREFIX_PACKER_REGISTRIES}.${ACTION_UPDATE}`,
  `${PREFIX_PACKER_REGISTRIES}.${ACTION_GET}`,
  `${PREFIX_PACKER_REGISTRIES}.${ACTION_LIST}`,
  `${PREFIX_PACKER_IMAGES}.${ACTION_CREATE}`,
  `${PREFIX_PACKER_IMAGES}.${ACTION_UPDATE}`,
  `${PREFIX_PACKER_IMAGES}.${ACTION_GET}`,
  `${PREFIX_PACKER_IMAGES}.${ACTION_LIST}`,
  `${PREFIX_PACKER_IMAGES}.${ACTION_DELETE}`,
  `${PREFIX_PACKER_ITERATIONS}.${ACTION_CREATE}`,
  `${PREFIX_PACKER_ITERATIONS}.${ACTION_UPDATE}`,
  `${PREFIX_PACKER_ITERATIONS}.${ACTION_GET}`,
  `${PREFIX_PACKER_ITERATIONS}.${ACTION_LIST}`,
  `${PREFIX_PACKER_ITERATIONS}.${ACTION_DELETE}`,
  `${PREFIX_PACKER_CHANNELS}.${ACTION_CREATE}`,
  `${PREFIX_PACKER_CHANNELS}.${ACTION_UPDATE}`,
  `${PREFIX_PACKER_CHANNELS}.${ACTION_GET}`,
  `${PREFIX_PACKER_CHANNELS}.${ACTION_LIST}`,
  `${PREFIX_PACKER_CHANNELS}.${ACTION_DELETE}`,
  `${PREFIX_RESOURCE_MANAGER_ROLES}.${ACTION_LIST}`,
  `${PREFIX_RESOURCE_MANAGER_ROLES}.${ACTION_GET}`,
  `${PREFIX_WEBHOOK_WEBHOOKS}.${ACTION_LIST}`,

  ...SECRETS_CONTRIBUTOR_ROLE_PERMISSIONS,
  ...VAULT_RADAR_CONTRIBUTOR_PERMISSIONS,
  ...VAULT_CONTRIBUTOR_ROLE_PERMISSIONS,
  ...WAYPOINT_CONTRIBUTOR_ROLE_PERMISSIONS,
  ...VAGRANT_CONTRIBUTOR_PERMISSIONS,
  ...WEBHOOKS_CONTRIBUTOR_PERMISSIONS,
];

export const viewerPermissions = [
  `${PREFIX_NETWORK_HVNS}.${ACTION_LIST}`,
  `${PREFIX_NETWORK_ROUTES}.${ACTION_LIST}`,
  `${PREFIX_NETWORK_PEERINGS}.${ACTION_LIST}`,
  `${PREFIX_NETWORK_TRANSIT_GATEWAY_ATTACHMENTS}.${ACTION_LIST}`,
  `${PREFIX_CONSUL_CLUSTERS}.${ACTION_LIST}`,
  `${PREFIX_CONSUL_CLUSTERS}.${ACTION_GET_CLIENT_CONFIG}`,
  `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_LIST}`,
  `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_GET}`,
  `${PREFIX_CONSUL_GNM_CLUSTERS_SERVERS}.${ACTION_LIST}`,
  `${PREFIX_IAM_INVITATIONS}.${ACTION_LIST}`,
  `${PREFIX_IAM_USERS}.${ACTION_LIST}`,
  `${PREFIX_BILLING_ACCOUNTS}.${ACTION_GET}`,
  `${PREFIX_BILLING_ACCOUNTS}.${ACTION_LIST}`,
  `${PREFIX_BILLING_ACCOUNTS}.${ACTION_TOKENIZE_PAYMENT_DETAILS}`,
  `${PREFIX_IAM_GROUPS}.${ACTION_GET}`,
  `${PREFIX_IAM_GROUPS}.${ACTION_LIST_MEMBERS}`,
  `${PREFIX_IAM_SSO}.${ACTION_GET}`,
  `${PREFIX_IAM_SSO}.${ACTION_LIST}`,
  `${PREFIX_IAM_USERS}.${ACTION_LIST}`,
  `${PREFIX_IAM_USERS}.${ACTION_GET}`,
  `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_LIST_ROLES}`,
  `${PREFIX_PACKER_REGISTRIES}.${ACTION_GET}`,
  `${PREFIX_PACKER_REGISTRIES}.${ACTION_LIST}`,
  `${PREFIX_PACKER_IMAGES}.${ACTION_GET}`,
  `${PREFIX_PACKER_IMAGES}.${ACTION_LIST}`,
  `${PREFIX_PACKER_ITERATIONS}.${ACTION_GET}`,
  `${PREFIX_PACKER_ITERATIONS}.${ACTION_LIST}`,
  `${PREFIX_PACKER_CHANNELS}.${ACTION_GET}`,
  `${PREFIX_PACKER_CHANNELS}.${ACTION_LIST}`,
  `${PREFIX_RESOURCE_MANAGER_ROLES}.${ACTION_LIST}`,
  `${PREFIX_RESOURCE_MANAGER_ROLES}.${ACTION_GET}`,
  `${PREFIX_WEBHOOK_WEBHOOKS}.${ACTION_LIST}`,

  ...SECRETS_VIEWER_ROLE_PERMISSIONS,
  ...VAULT_RADAR_VIEWER_PERMISSIONS,
  ...VAULT_VIEWER_ROLE_PERMISSIONS,
  ...WAYPOINT_VIEWER_ROLE_PERMISSIONS,
  ...VAGRANT_VIEWER_PERMISSIONS,
  ...WEBHOOKS_VIEWER_PERMISSIONS,
];

export const noRolePermissions = [];

export default function createPermissions(
  schema,
  { organization, user, allowedPermissions = fullPermissions }
) {
  let requestedUiScenario = '';

  const { organization: organizationRoleOverride } = extractMirageOverrides();

  if (organizationRoleOverride?.role) {
    requestedUiScenario = organizationRoleOverride.role;

    console.log('FOUND ORG LEVEL MIRAGE OVERRIDE', requestedUiScenario);
    allowedPermissions = buildAllowedPermissions(requestedUiScenario);
  }

  return schema.create('resource-manager.allowed-permission', {
    organization,
    principal: user,
    allowed_permissions: allowedPermissions,
  });
}

export function createProjectPermissions(
  schema,
  { organization, project, user, allowedPermissions = fullPermissions }
) {
  let requestedUiScenario = '';

  const { project: projectRoleOverride } = extractMirageOverrides();
  if (projectRoleOverride?.role) {
    requestedUiScenario = projectRoleOverride.role;

    console.log('FOUND PROJ LEVEL MIRAGE OVERRIDE', requestedUiScenario);
    allowedPermissions = buildAllowedPermissions(requestedUiScenario);
  }

  return schema.create('resource-manager.project.allowed-permission', {
    organization,
    project,
    principal: user,
    allowed_permissions: allowedPermissions,
  });
}
