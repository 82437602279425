//https://github.com/hashicorp/cloud-consul-service/blob/master/resource/consul.go
export const TYPE_CONSUL = 'hashicorp.consul.cluster';
export const TYPE_CONSUL_GLOBAL_NETWORK_MANAGER =
  'hashicorp.consul.global-network-manager.cluster';
export const TYPE_CONSUL_SNAPSHOT = 'hashicorp.consul.snapshot';
// https://github.com/hashicorp/cloud-vault-service/blob/master/resource/vault.go
export const TYPE_VAULT = 'hashicorp.vault.cluster';
export const TYPE_VAULT_SNAPSHOT = 'hashicorp.vault.snapshot';
// https://github.com/hashicorp/cloud-packer-service/blob/master/resource/packer.go
export const TYPE_PACKER = 'hashicorp.packer.registry';
export const TYPE_PACKER_BUCKET = 'hashicorp.packer.bucket';
export const TYPE_PACKER_VERSION = 'hashicorp.packer.version';
// terraform cloud
export const TYPE_TERRAFORM =
  'hashicorp.terraform_cloud_business.payer_account';
// terraform cloud organization
export const TYPE_TERRAFORM_ORGANIZATION =
  'hashicorp.terraform_cloud.organization';
// terraform cloud resource
export const TYPE_TERRAFORM_WORKSPACE = 'hashicorp.terraform.workspace';
export const TYPE_TERRAFORM_STACK = 'hashicorp.terraform.stack';
// https://github.com/hashicorp/cloud-boundary-service/blob/master/pkg/billing/resource_type_definition.go
export const TYPE_BOUNDARY = 'hashicorp.boundary.cluster';
// waypoint application
export const TYPE_WAYPOINT = 'hashicorp.waypoint.namespace';
//https://github.com/hashicorp/cloud-network/blob/master/resource/network.go
export const TYPE_NETWORK = 'hashicorp.network.hvn';
export const TYPE_NETWORK_ROUTE = 'hashicorp.network.route';
export const TYPE_NETWORK_PEERING = 'hashicorp.network.peering';
export const TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT =
  'hashicorp.network.tgw-attachment';
// https://github.com/hashicorp/cloud-iam
export const TYPE_SERVICE_PRINCIPAL = 'hashicorp.iam.service-principal';
export const TYPE_SERVICE_PRINCIPAL_KEY = 'hashicorp.iam.service-principal-key';
export const TYPE_ORGANIZATION = 'hashicorp.resourcemanager.organization';
export const TYPE_PROJECT = 'hashicorp.resourcemanager.project';
//log streaming implementation
export const TYPE_STREAMING_DESTINATION = 'hashicorp.streaming.destination';
// vagrant
export const TYPE_VAGRANT = 'hashicorp.vagrant.registry';
// vault secrets
export const TYPE_VAULT_SECRETS_APP = 'hashicorp.secrets.app';
export const TYPE_VAULT_SECRETS_GATEWAY_POOL = 'hashicorp.secrets.gateway-pool';
export const TYPE_VAULT_SECRETS_INTEGRATION = 'hashicorp.secrets.integration';
export const TYPE_VAULT_SECRETS_PROJECT = 'hashicorp.secrets.project';
export const TYPE_VAULT_SECRETS_SECRET = 'hashicorp.secrets.secret';

// webhook
export const TYPE_WEBHOOK = 'hashicorp.webhook.webhook';
// vault radar
export const TYPE_VAULT_RADAR = 'hashicorp.vault-radar.scan-target';
