import NO_ROLE_ROLE from '../../../utils/no-role-role.ts';
import {
  ROLE_KEY_ADMIN,
  ROLE_KEY_CONTRIBUTOR,
  ROLE_KEY_VIEWER,
  ROLE_KEY_BROWSER,
} from 'core/utils/cloud-iam-rbac-roles';

// TODO HCPF-2017: fill in ORGANIZATION_SERVICES with correct columnRoleIds based on permissions
// from the server. There is a ticket from BE to return permissions on org level roles.
// https://hashicorp.atlassian.net/browse/HCPF-2017

const roleDefinitionFlyoutTranslationPath =
  'manage-access.components.flyout.role-definitions-flyout.organization';

export const ORGANIZATION_SERVICES = [
  {
    id: 'basic',
    title: `${roleDefinitionFlyoutTranslationPath}.all-services`,
    categories: [
      {
        title: `${roleDefinitionFlyoutTranslationPath}.basic.actions.view-users`,
        actions: [
          {
            title: `${roleDefinitionFlyoutTranslationPath}.basic.actions.view-users`,
            permissions: ['resource-manager.projects.list'],
            icon: 'hcp',
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.basic.actions.add-delete-users`,
            permissions: ['iam.invitations.create', 'iam.users.delete'],
            icon: 'hcp',
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.basic.actions.manage-permissions-users`,
            permissions: ['resource-manager.organizations.set-iam-policy'],
            icon: 'hcp',
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.basic.actions.manage-service-principals`,
            permissions: [
              'iam.principals.list',
              'iam.service-principals.create',
              'iam.service-principals.create-token',
              'iam.service-principals.delete',
              'iam.service-principals.delete-token',
              'iam.service-principals.get',
              'iam.service-principals.list',
              'iam.service-principals.list-token',
              'iam.service-principals.update',
            ],
            icon: 'hcp',
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.basic.actions.view-billing-status`,
            permissions: ['billing.statements.list'],
            icon: 'hcp',
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.basic.actions.view-projects`,
            permissions: ['resource-manager.projects.list'],
            icon: 'hcp',
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.basic.actions.view-project-resources`,
            permissions: ['resource-manager.resources.list'],
            icon: 'hcp',
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.basic.actions.manage-projects`,
            permissions: ['resource-manager.projects.create'],
            icon: 'hcp',
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.basic.actions.view-hcp-resources`,
            permissions: ['resource-manager.resources.list'],
            icon: 'hcp',
          },
        ],
        columnRoleIds: [
          ROLE_KEY_ADMIN,
          ROLE_KEY_CONTRIBUTOR,
          ROLE_KEY_VIEWER,
          ROLE_KEY_BROWSER,
          NO_ROLE_ROLE.id,
        ],
      },
    ],
  },
];
