import Service from '@ember/service';
import * as Sentry from '@sentry/ember';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  ACTION_ASSIGN_PROJECT,
  ACTION_CREATE,
  ACTION_CREATE_ROOT_TOKEN,
  ACTION_CREATE_TOKEN,
  ACTION_DELETE,
  ACTION_DELETE_TOKEN,
  ACTION_DISABLE,
  ACTION_ENABLE,
  ACTION_GET,
  ACTION_GET_API_INFORMATION,
  ACTION_GET_CLIENT_CONFIG,
  ACTION_GET_IAM_POLICY,
  ACTION_GET_SECRETS,
  ACTION_LIST,
  ACTION_LIST_ROLES,
  ACTION_LIST_TOKENS,
  ACTION_SET_IAM_POLICY,
  ACTION_SET_NAME,
  ACTION_TOKENIZE_PAYMENT_DETAILS,
  ACTION_UNASSIGN_PROJECT,
  ACTION_UPDATE,
  ACTION_UPDATE_MEMBERS,
  ACTION_LIST_MEMBERS,
  PREFIX_BILLING_ACCOUNTS,
  PREFIX_BOUNDARY_CLUSTERS,
  PREFIX_CONSUL_CLUSTERS,
  PREFIX_CONSUL_GNM_CLUSTERS,
  PREFIX_CONSUL_GNM_CLUSTERS_SERVERS,
  PREFIX_CONSUL_GNM_PEERING_CONNECTIONS,
  PREFIX_CONSUL_SNAPSHOTS,
  PREFIX_IAM_AUTH_CONNECTION,
  PREFIX_IAM_GROUPS,
  PREFIX_IAM_INVITATIONS,
  PREFIX_IAM_SERVICE_PRINCIPALS,
  PREFIX_IAM_SSO,
  PREFIX_IAM_USERS,
  PREFIX_IDP_SCIM,
  PREFIX_LOG_ENTRIES,
  PREFIX_NETWORK_HVNS,
  PREFIX_NETWORK_PEERINGS,
  PREFIX_NETWORK_ROUTES,
  PREFIX_NETWORK_TRANSIT_GATEWAY_ATTACHMENTS,
  PREFIX_RESOURCE_MANAGER_ORGANIZATIONS,
  PREFIX_RESOURCE_MANAGER_PROJECTS,
  PREFIX_RESOURCE_MANAGER_RESOURCES,
  PREFIX_TERRAFORM_WORKSPACES,
  PREFIX_PACKER_CHANNELS,
  PREFIX_PACKER_IMAGES,
  PREFIX_PACKER_ITERATIONS,
  PREFIX_PACKER_REGISTRIES,
  PREFIX_VAGRANT_REGISTRY,
  PREFIX_WEBHOOK_WEBHOOKS,

  // Vault Secrets permissions are defined in `utils/permissions-types` and imported here
  SECRETS_ALL_PERMISSIONS,
  // Vault permissions are defined in the `utils/permission-types` and imported here
  VAULT_ALL_PERMISSIONS,
  VAULT_RADAR_ALL_PERMISSIONS,
  WAYPOINT_ALL_PERMISSIONS,
  PREFIX_CONSUL_GNM_OBSERVABILITY,
  ACTION_GET_CREDENTIALS,
} from '../utils/permission-types/index';
import {
  TYPE_ORGANIZATION,
  TYPE_PROJECT,
} from 'common/utils/cloud-resource-types';
import AuthzBuilder from 'common/utils/authz';

const vagrantAppScope = new AuthzBuilder('vagrant', { Registry: 'registry' });
vagrantAppScope.addActions('registry', ['create', 'read', 'delete', 'list']);

const PERMISSIONS_QUERY = {
  permissions: [
    `${PREFIX_BILLING_ACCOUNTS}.${ACTION_ASSIGN_PROJECT}`,
    `${PREFIX_BILLING_ACCOUNTS}.${ACTION_DELETE}`,
    `${PREFIX_BILLING_ACCOUNTS}.${ACTION_GET}`,
    `${PREFIX_BILLING_ACCOUNTS}.${ACTION_LIST}`,
    `${PREFIX_BILLING_ACCOUNTS}.${ACTION_TOKENIZE_PAYMENT_DETAILS}`,
    `${PREFIX_BILLING_ACCOUNTS}.${ACTION_UNASSIGN_PROJECT}`,
    `${PREFIX_BILLING_ACCOUNTS}.${ACTION_UPDATE}`,

    `${PREFIX_BOUNDARY_CLUSTERS}.${ACTION_LIST}`,
    `${PREFIX_BOUNDARY_CLUSTERS}.${ACTION_CREATE}`,

    `${PREFIX_CONSUL_CLUSTERS}.${ACTION_CREATE_ROOT_TOKEN}`,
    `${PREFIX_CONSUL_CLUSTERS}.${ACTION_CREATE}`,
    `${PREFIX_CONSUL_CLUSTERS}.${ACTION_DELETE}`,
    `${PREFIX_CONSUL_CLUSTERS}.${ACTION_GET_CLIENT_CONFIG}`,
    `${PREFIX_CONSUL_CLUSTERS}.${ACTION_LIST}`,
    `${PREFIX_CONSUL_CLUSTERS}.${ACTION_UPDATE}`,

    `${PREFIX_CONSUL_GNM_CLUSTERS_SERVERS}.${ACTION_LIST}`,
    `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_CREATE}`,
    `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_DELETE}`,
    `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_GET_API_INFORMATION}`,
    `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_GET_SECRETS}`,
    `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_GET}`,
    `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_LIST}`,
    `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_UPDATE}`,
    `${PREFIX_CONSUL_GNM_PEERING_CONNECTIONS}.${ACTION_CREATE}`,
    `${PREFIX_CONSUL_GNM_PEERING_CONNECTIONS}.${ACTION_DELETE}`,
    `${PREFIX_CONSUL_GNM_OBSERVABILITY}.${ACTION_GET_CREDENTIALS}`,

    `${PREFIX_CONSUL_SNAPSHOTS}.${ACTION_CREATE}`,
    `${PREFIX_CONSUL_SNAPSHOTS}.${ACTION_DELETE}`,
    `${PREFIX_CONSUL_SNAPSHOTS}.${ACTION_UPDATE}`,

    `${PREFIX_IAM_AUTH_CONNECTION}.${ACTION_CREATE}`,
    `${PREFIX_IAM_AUTH_CONNECTION}.${ACTION_DELETE}`,
    `${PREFIX_IAM_AUTH_CONNECTION}.${ACTION_GET}`,
    `${PREFIX_IAM_AUTH_CONNECTION}.${ACTION_UPDATE}`,

    `${PREFIX_IAM_INVITATIONS}.${ACTION_CREATE}`,
    `${PREFIX_IAM_INVITATIONS}.${ACTION_DELETE}`,
    `${PREFIX_IAM_INVITATIONS}.${ACTION_LIST}`,

    `${PREFIX_IAM_GROUPS}.${ACTION_CREATE}`,
    `${PREFIX_IAM_GROUPS}.${ACTION_DELETE}`,
    `${PREFIX_IAM_GROUPS}.${ACTION_GET}`,
    `${PREFIX_IAM_GROUPS}.${ACTION_UPDATE}`,
    `${PREFIX_IAM_GROUPS}.${ACTION_UPDATE_MEMBERS}`,
    `${PREFIX_IAM_GROUPS}.${ACTION_LIST_MEMBERS}`,

    `${PREFIX_IAM_SERVICE_PRINCIPALS}.${ACTION_CREATE}`,
    `${PREFIX_IAM_SERVICE_PRINCIPALS}.${ACTION_DELETE}`,
    `${PREFIX_IAM_SERVICE_PRINCIPALS}.${ACTION_GET}`,
    `${PREFIX_IAM_SERVICE_PRINCIPALS}.${ACTION_LIST}`,
    `${PREFIX_IAM_SERVICE_PRINCIPALS}.${ACTION_UPDATE}`,

    `${PREFIX_IAM_SSO}.${ACTION_CREATE}`,
    `${PREFIX_IAM_SSO}.${ACTION_DELETE}`,
    `${PREFIX_IAM_SSO}.${ACTION_GET}`,
    `${PREFIX_IAM_SSO}.${ACTION_LIST}`,
    `${PREFIX_IAM_SSO}.${ACTION_UPDATE}`,

    `${PREFIX_IAM_USERS}.${ACTION_DELETE}`,
    `${PREFIX_IAM_USERS}.${ACTION_GET}`,
    `${PREFIX_IAM_USERS}.${ACTION_LIST}`,

    `${PREFIX_IDP_SCIM}.${ACTION_CREATE_TOKEN}`,
    `${PREFIX_IDP_SCIM}.${ACTION_DELETE_TOKEN}`,
    `${PREFIX_IDP_SCIM}.${ACTION_DISABLE}`,
    `${PREFIX_IDP_SCIM}.${ACTION_ENABLE}`,
    `${PREFIX_IDP_SCIM}.${ACTION_GET}`,
    `${PREFIX_IDP_SCIM}.${ACTION_LIST_TOKENS}`,

    `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_SET_NAME}`,
    `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_LIST_ROLES}`,
    `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_GET_IAM_POLICY}`,
    `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_SET_IAM_POLICY}`,

    `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_CREATE}`,
    `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_DELETE}`,
    `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_GET}`,
    `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_LIST}`,
    `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_UPDATE}`,
    `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_GET_IAM_POLICY}`,
    `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_SET_IAM_POLICY}`,

    `${PREFIX_RESOURCE_MANAGER_RESOURCES}.${ACTION_LIST}`,
    `${PREFIX_RESOURCE_MANAGER_RESOURCES}.${ACTION_LIST_ROLES}`,

    `${PREFIX_TERRAFORM_WORKSPACES}.${ACTION_LIST}`,

    `${PREFIX_LOG_ENTRIES}.${ACTION_GET}`,

    `${PREFIX_NETWORK_HVNS}.${ACTION_CREATE}`,
    `${PREFIX_NETWORK_HVNS}.${ACTION_DELETE}`,
    `${PREFIX_NETWORK_HVNS}.${ACTION_LIST}`,

    `${PREFIX_NETWORK_PEERINGS}.${ACTION_CREATE}`,

    `${PREFIX_NETWORK_ROUTES}.${ACTION_CREATE}`,
    `${PREFIX_NETWORK_ROUTES}.${ACTION_DELETE}`,

    `${PREFIX_NETWORK_TRANSIT_GATEWAY_ATTACHMENTS}.${ACTION_CREATE}`,

    `${PREFIX_PACKER_CHANNELS}.${ACTION_CREATE}`,
    `${PREFIX_PACKER_CHANNELS}.${ACTION_DELETE}`,
    `${PREFIX_PACKER_CHANNELS}.${ACTION_GET}`,
    `${PREFIX_PACKER_CHANNELS}.${ACTION_LIST}`,
    `${PREFIX_PACKER_CHANNELS}.${ACTION_UPDATE}`,

    `${PREFIX_PACKER_IMAGES}.${ACTION_CREATE}`,
    `${PREFIX_PACKER_IMAGES}.${ACTION_DELETE}`,
    `${PREFIX_PACKER_IMAGES}.${ACTION_GET}`,
    `${PREFIX_PACKER_IMAGES}.${ACTION_LIST}`,
    `${PREFIX_PACKER_IMAGES}.${ACTION_UPDATE}`,

    `${PREFIX_PACKER_ITERATIONS}.${ACTION_CREATE}`,
    `${PREFIX_PACKER_ITERATIONS}.${ACTION_DELETE}`,
    `${PREFIX_PACKER_ITERATIONS}.${ACTION_GET}`,
    `${PREFIX_PACKER_ITERATIONS}.${ACTION_LIST}`,
    `${PREFIX_PACKER_ITERATIONS}.${ACTION_UPDATE}`,

    `${PREFIX_PACKER_REGISTRIES}.${ACTION_CREATE}`,
    `${PREFIX_PACKER_REGISTRIES}.${ACTION_DELETE}`,
    `${PREFIX_PACKER_REGISTRIES}.${ACTION_GET}`,
    `${PREFIX_PACKER_REGISTRIES}.${ACTION_LIST}`,
    `${PREFIX_PACKER_REGISTRIES}.${ACTION_UPDATE}`,

    `${PREFIX_VAGRANT_REGISTRY}.${ACTION_LIST}`,

    `${PREFIX_WEBHOOK_WEBHOOKS}.${ACTION_LIST}`,

    ...SECRETS_ALL_PERMISSIONS,
    ...VAULT_RADAR_ALL_PERMISSIONS,
    ...VAULT_ALL_PERMISSIONS,
    ...WAYPOINT_ALL_PERMISSIONS,
    ...vagrantAppScope.list(),
  ],
};

export default class PermissionsService extends Service {
  @service api;

  @tracked id = null;
  @tracked permissions = [];
  @tracked organizationPermissions = [];
  @tracked queriedAt = null;
  @tracked failOpen = false;
  @tracked resourceType = null;

  /**
   * Checks the presence of a RBAC permissions string in the in-memory permissions list.
   * In the case of an API failure, `this.failOpen` will be true, and `has`
   * will always return true. This is a failure mode which may be confusing for
   * users, but will allow the portal to continue to function with their set of
   * allowed permissions.
   *
   * @param {string} permission - A hashicorp.cloud.resourcemanager RBAC permission string.
   * @return {boolean}
   */
  @action
  has(permission) {
    return this.failOpen || this.permissions.includes(permission);
  }

  /**
   * Combines a prefix and an action to create an RBAC permission string dynamically.
   *
   * @param {string} permissionPrefix - A hashicorp.cloud.resourcemanager RBAC prefix string.
   * @param {string} permissionAction - A hashicorp.cloud.resourcemanager RBAC action string.
   * @return {string}
   */
  generate(permissionPrefix, permissionAction) {
    return `${permissionPrefix}.${permissionAction}`;
  }

  /**
   * Queries resource manager for all RBAC permissions for current user and the current
   * resource type (organization, project).
   *
   * @param {string} organizationId - A hashicorp.cloud.resourcemanager organization id.
   * @param {string} resourceType - Defaults to organization, project is also an accepted/supported type.
   * @return {Object}
   */
  async query(id, resourceType = TYPE_ORGANIZATION) {
    let permissionResponse;
    if (resourceType === TYPE_PROJECT) {
      permissionResponse = await this.queryProject(id);
    } else {
      permissionResponse = await this.queryOrganization(id);
    }

    this.id = id;
    this.permissions = permissionResponse.allowedPermissions || [];
    this.queriedAt = new Date();
    this.resourceType = resourceType;

    return this;
  }

  /**
   * Queries resource manager for all RBAC permissions for current user and an organization.
   *
   * @param {string} organizationId - A hashicorp.cloud.resourcemanager organization id.
   * @return {Object}
   */
  async queryOrganization(organizationId) {
    // Query all permissions.
    let permissionResponse;
    try {
      permissionResponse =
        await this.api.resourceManager.org.organizationServiceTestIamPermissions(
          organizationId,
          PERMISSIONS_QUERY,
        );
    } catch (e) {
      // we shouldn't hide anything if there's an error in calling the `projectTestIamPermissions` endpoint
      this.failOpen = true;
      var style = 'color: red; background:#eee; font-size:16px;';
      console.log(
        '%cIAM Permissions check failed, all client-side permission checks will succeed. This may lead to showing UI elements for actions you do not normally see and do not have permission to carry out.',
        style,
      );
      Sentry.captureException(
        new Error('testIamPermissions API request failed'),
      );
      // rethrow so that downstream try/catch blocks still work
      throw e;
    }

    this.organizationPermissions = permissionResponse.allowedPermissions || [];
    return permissionResponse;
  }

  /**
   * Queries resource manager for all RBAC permissions for current user and a project.
   *
   * @param {string} projectId - A hashicorp.cloud.resourcemanager project id.
   * @return {Object}
   */
  async queryProject(projectId) {
    // Query all permissions.
    let permissionResponse;
    try {
      permissionResponse =
        await this.api.resourceManager.project.projectServiceTestIamPermissions(
          projectId,
          PERMISSIONS_QUERY,
        );
    } catch (e) {
      // we shouldn't hide anything if there's an error in calling the `testIamPermissions` endpoint
      this.failOpen = true;
      var style = 'color: red; background:#eee; font-size:16px;';
      console.log(
        '%cProject IAM Permissions check failed, all client-side permission checks will succeed. This may lead to showing UI elements for actions you do not normally see and do not have permission to carry out.',
        style,
      );
      Sentry.captureException(
        new Error('projectTestIamPermissions API request failed'),
      );
      // rethrow so that downstream try/catch blocks still work
      throw e;
    }

    return permissionResponse;
  }

  get scopedPermissions() {
    return {
      organization: [...this.organizationPermissions],
    };
  }
}
