import { inject as service } from '@ember/service';

import BaseAbility from './base-ability';
import {
  PREFIX_IAM_INVITATIONS,
  ACTION_LIST,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  @service userContext;

  prefix = PREFIX_IAM_INVITATIONS;

  get canList() {
    return (
      !this.userContext.organizationMeta.ssoEnabled &&
      this.permissions.has(this.generate(ACTION_LIST))
    );
  }
}
