import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { assert } from '@ember/debug';
import {
  FieldViolation,
  FormValidationError,
} from '../../../utils/form-validation.ts';

import type { IntlService } from 'ember-intl';
import type { SafeString } from '@ember/template';

class ModalConfirmFormState {
  @tracked confirmText = '';

  constructor(confirmText?: string) {
    if (confirmText) {
      this.confirmText = confirmText;
    }
  }
}

const DEFAULT_MODAL_COLOR = 'critical';
const DEFAULT_CONFIRM_BUTTON_TEXT = 'Delete';

interface CoreModalConfirmSignature {
  Args: {
    confirmButtonColor: string;
    confirmButtonText: string;
    confirmHelp: string;
    confirmLabel: string;
    confirmText: string;
    headerColor: string;
    headerIcon: string;
    headerTagline: string;
    headerText: string | SafeString;
    onCancel: () => void;
    onSubmit: () => void;
  };
  Blocks: {
    body: [];
  };
}

export default class CoreModalConfirmComponent extends Component<CoreModalConfirmSignature> {
  /**
   * String that user must type in modal input in order to successfully validate and submit form
   * @argument confirmText
   * @type {String}
   * @required
   */

  /**
   * Passed in callback function to cancel / close modal
   * @argument onCancel
   * @type {Function}
   * @required
   */

  /**
   * Passed in as async/generator Ember concurrency task with a perform callback
   * function to invoke upon successful form validation & submission
   * @argument onSubmit
   * @type {Function}
   * @required
   */

  /**
   * Optional argument to update the modal input's help text
   * Default is "To confirm, type {confirmText} below"
   * @argument confirmHelp
   * @type {String}
   */

  /**
   * Optional argument to update the modal input's label. Default is "Confirm"
   * @argument confirmLabel
   * @type {String}
   */

  /**
   * Optional argument to change the confirm button color
   * Default is "critical"
   * Additional options: primary, secondary
   * @argument confirmButtonColor
   * @type {String}
   */

  /**
   * Optional argument to change the confirm button text
   * Default is "Delete"
   * @argument confirmButtonText
   * @type {String}
   */

  /**
   * Optional argument to change the modal header color
   * Available options: neutral, warning, critical (default)
   * @argument headerColor
   * @type {String}
   */

  /**
   * Optional argument to include an icon in the modal header
   * @argument headerIcon
   * @type {String}
   */

  /**
   * Required argument to populate the modal header text
   * @argument headerText
   * @type {String}
   * @required
   */

  @service declare readonly intl: IntlService;
  @tracked formState: ModalConfirmFormState | null = null;

  constructor(owner: unknown, args: CoreModalConfirmSignature['Args']) {
    super(owner, args);

    assert(
      '<Core::Modal::Confirm> required argument @onCancel is a defined function',
      typeof this.args.onCancel === 'function'
    );

    assert(
      '<Core::Modal::Confirm> argument @onSubmit is a required Ember concurrency task with a .perform callback function',
      // @ts-expect-error
      typeof this.args.onSubmit?.perform === 'function'
    );

    assert(
      '<Core::Modal::Confirm> required argument @confirmText is a defined string',
      typeof this.args.confirmText === 'string'
    );

    assert(
      '<Core::Modal::Confirm> required argument @headerText is a defined string',
      // in cases where htmlSafe=true is passed in as part of the headerText argument,
      // it is converted to a SafeString and made an object, hence the extra assertion
      typeof this.args.headerText === 'string' ||
        this.args.headerText.constructor.name === 'SafeString'
    );

    this.formState = new ModalConfirmFormState();
  }

  get headerColor() {
    return this.args.headerColor || DEFAULT_MODAL_COLOR;
  }

  get headerIcon() {
    return this.args.headerIcon || null;
  }

  get headerTagline() {
    return this.args.headerTagline || null;
  }

  get confirmButtonColor() {
    return this.args.confirmButtonColor || DEFAULT_MODAL_COLOR;
  }

  get confirmButtonText() {
    return this.args.confirmButtonText || DEFAULT_CONFIRM_BUTTON_TEXT;
  }

  @action
  updateConfirmText(evt: Event & { target: HTMLInputElement }) {
    this.formState = new ModalConfirmFormState(evt.target.value);
  }

  @task
  *submitForm(evt: Event & { target: HTMLInputElement }) {
    evt.preventDefault();
    this.validateForm();
    // @ts-expect-error
    yield this.args.onSubmit.perform(this.formState);
  }

  @action
  cancelForm() {
    this.resetFormState();
    this.args.onCancel();
  }

  @action
  validateForm() {
    const validationError = new FormValidationError(
      this.intl.t('components.core.modal.confirm.invalid-submission', {
        confirmText: this.args.confirmText,
      })
    );
    if (this.formState?.confirmText === '') {
      validationError.details.push(
        new FieldViolation(
          this.intl.t('components.core.modal.confirm.invalid-submission', {
            confirmText: this.args.confirmText,
          }),
          'confirm-input'
        )
      );
    } else if (this.formState?.confirmText !== this.args.confirmText) {
      validationError.details.push(
        new FieldViolation(
          this.intl.t('components.core.modal.confirm.invalid-input', {
            confirmText: this.args.confirmText,
          }),
          'confirm-input'
        )
      );
    }
    if (validationError.details.length) {
      throw validationError;
    }
  }

  @action
  resetFormState() {
    this.formState = new ModalConfirmFormState();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Core::Modal::Confirm': typeof CoreModalConfirmComponent;
    'core/modal/confirm': typeof CoreModalConfirmComponent;
  }
}
