import { faker } from '@faker-js/faker';

import baseScenario from './user';
import { TYPE_STREAMING_DESTINATION } from 'common/utils/cloud-resource-types';

export default function (server) {
  baseScenario(server);

  let user = server.create('iam.user-principal', {
    current_user: true,
  });

  // always seed with the same IDs so that page refreshes work
  let org = server.create('resource-manager.organization', {
    id: '4988ada2-cb2c-4ca5-9e44-e129da0e1cb8',
    name: 'Default Org',
    owner: user,
  });

  let project = server.create('resource-manager.project', {
    id: '97cff5f5-8c88-4275-85fb-b8ad856717cf',
    parent: {
      type: 'ORGANIZATION',
      id: org.id,
    },
  });

  const REGIONS_LOOKUP = {
    ['cloudwatch']: faker.helpers.arrayElement([
      'Cape Town (af-south-1)',
      'Hong Kong (ap-east-1)',
      'Frankfurt (eu-central-1)',
      'Bahrain (me-south-1)',
      'Sao Paulo (sa-east-1)',
      'N. Virginia (us-east-1)',
    ]),
    ['datadog']: faker.helpers.arrayElement([
      'US1 (https://app.datadoghq.com)',
      'EU1 (https://app.datadoghq.eu)',
      'US1-FED (https://app.ddog-gov.com)',
      'US3 (https://us3.datadoghq.com)',
      'US5 (https://us5.datadoghq.com)',
    ]),
  };

  const ENDPOINTS_LOOKUP = {
    ['cloudwatch']: faker.helpers.arrayElement([
      'https://logs.us-east-1.amazonaws.com',
      'https://logs.af-south-1.amazonaws.com',
      'https://logs.ap-east-1.amazonaws.com',
      'https://logs.eu-central-1.amazonaws.com',
      'https://logs.me-south-1.amazonaws.com',
      'https://logs.sa-east-1.amazonaws.com',
    ]),
    ['datadog']: faker.helpers.arrayElement([
      'US1 (https://app.datadoghq.com)',
      'EU1 (https://app.datadoghq.eu)',
      'US1-FED (https://app.ddog-gov.com)',
      'US3 (https://us3.datadoghq.com)',
      'US5 (https://us5.datadoghq.com)',
    ]),
  };

  const provider = faker.helpers.arrayElement(['cloudwatch', 'datadog']);

  server.create('log.streamingCreateDestination', {
    organizationId: org.id,
    projectId: project.id,
    destination: {
      resource: {
        type: TYPE_STREAMING_DESTINATION,
        id: faker.string.uuid(),
        uuid: faker.string.uuid(),
        location: {
          organization_id: org.id,
          project_id: project.id,
          region: {
            provider: provider,
            region: faker.internet.url(),
          },
        },
      },
      name: provider,
      filter: {},
      datadog_provider: {},
      cloudwatchlogs_provider: {},
    },
  });

  server.create('log.streamingListDestination', {
    organizationId: org.id,
    projectId: project.id,
    destinations: [
      {
        id: faker.string.uuid(),
        resource: {
          type: TYPE_STREAMING_DESTINATION,
          id: faker.string.uuid(),
          uuid: faker.string.uuid(),
          location: {
            organization_id: org.id,
            project_id: project.id,
            region: {
              provider: provider,
              region: REGIONS_LOOKUP[provider],
            },
          },
        },
        source: 'DESTINATION_SOURCE_TERRAFORM',
        in_error: faker.datatype.boolean(),
        name: faker.hacker.noun(),
        endpoint: 'https://logs.us-east-1.amazonaws.com',
        filter: {},
      },
      {
        id: faker.string.uuid(),
        resource: {
          type: TYPE_STREAMING_DESTINATION,
          id: faker.string.uuid(),
          uuid: faker.string.uuid(),
          location: {
            organization_id: org.id,
            project_id: project.id,
            region: {
              provider: provider,
              region: REGIONS_LOOKUP[provider],
            },
          },
        },
        source: 'DESTINATION_SOURCE_HCP_UI',
        endpoint: 'https://logs.us-east-1.datadog.com',
        in_error: faker.datatype.boolean(),
        name: faker.hacker.noun(),
        status: 'pending',
        filter: {},
      },
      {
        id: faker.string.uuid(),
        resource: {
          type: TYPE_STREAMING_DESTINATION,
          id: faker.string.uuid(),
          uuid: faker.string.uuid(),
          location: {
            organization_id: org.id,
            project_id: project.id,
            region: {
              provider: provider,
              region: REGIONS_LOOKUP[provider],
            },
          },
        },
        source: 'DESTINATION_SOURCE_HCP_UI',
        endpoint: 'https://logs.us-east-1.splunk.com',
        in_error: faker.datatype.boolean(),
        name: faker.hacker.noun(),
        status: 'error',
        filter: {},
      },
    ],
  });

  server.create('log.streamingDestinationForResource', {
    organizationId: org.id,
    projectId: project.id,
    destinations: [
      {
        resource: {
          type: TYPE_STREAMING_DESTINATION,
          id: faker.string.uuid(),
          uuid: faker.string.uuid(),
          location: {
            organization_id: org.id,
            project_id: project.id,
            region: {
              provider: provider,
              region: REGIONS_LOOKUP[provider],
            },
          },
        },
        endpoint: ENDPOINTS_LOOKUP[provider],
        in_error: faker.datatype.boolean(),
        name: faker.hacker.noun(),
        filter: {},
      },
    ],
  });

  server.create('log.streamingGetDestination', {
    organizationId: org.id,
    projectId: project.id,
    destination: {
      resource: {
        type: TYPE_STREAMING_DESTINATION,
        id: faker.string.uuid(),
        uuid: faker.string.uuid(),
        location: {
          organization_id: org.id,
          project_id: project.id,
          region: {
            provider: provider,
            region: REGIONS_LOOKUP[provider],
          },
        },
      },
      in_error: faker.datatype.boolean(),
      name: faker.hacker.noun(),
      filter: {},
    },
  });

  server.create('log.streamingDeleteDestination', {
    organizationId: org.id,
    projectId: project.id,
    success: faker.datatype.boolean(),
  });
}
