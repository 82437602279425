import Service from '@ember/service';

import {
  ROLE_KEY_ADMIN,
  ROLE_KEY_CONTRIBUTOR,
  ROLE_KEY_NO_ROLE_UI_ONLY,
  ROLE_KEY_OWNER,
  ROLE_KEY_VIEWER,
} from '../helpers/rbac-roles.ts';

import {
  TYPE_ORGANIZATION,
  TYPE_PROJECT,
} from 'common/utils/cloud-resource-types';

export interface Role {
  /**
   * The role assigned to a user.
   * Available options:
   * - roles/admin
   * - roles/contributor
   * - roles/no-role
   * - roles/owner
   * - roles/viewer
   */
  roleId:
    | 'roles/admin'
    | 'roles/contributor'
    | 'roles/no-role'
    | 'roles/owner'
    | 'roles/viewer';

  /**
   * The link key object that provides additional information about the role.
   */
  link: RoleLink;
}

export interface RoleLink {
  /**
   * The ID associated with the role link.
   */
  id: string;

  /**
   * The name of the role link.
   */
  name: string;

  /**
   * The type of the role link.
   * Available options:
   * - hashicorp.resourcemanager.organization
   * - hashicorp.resourcemanager.project
   */
  type:
    | 'hashicorp.resourcemanager.organization'
    | 'hashicorp.resourcemanager.project';
}

// Weights are stepped by 10 to allow for the type to adjust the sorting weight
// to allow for projects to always outweight organizations of the same role.
export const ROLE_ACCESS_VALUE_WEIGHTS = {
  [ROLE_KEY_ADMIN]: 3,
  [ROLE_KEY_CONTRIBUTOR]: 2,
  [ROLE_KEY_NO_ROLE_UI_ONLY]: 0,
  [ROLE_KEY_OWNER]: 4,
  [ROLE_KEY_VIEWER]: 1,
};

export const TYPE_ACCESS_VALUE_WEIGHTS = {
  [TYPE_ORGANIZATION]: 1,
  [TYPE_PROJECT]: 2,
};

export const WEIGHT_MODIFIER = 10;

/**
 * Calculates a weight for effectiveness based on the role and where the role
 * has access to. The weight of the role is multiplied by the source.
 *
 * @param {Object} role - an object that has an RBAC role string and a link to
 *     to source of where the role has access to. Usually a project or an org.
 * @return {Number}
 */
function calculateWeight(role: Role) {
  const accessWeight =
    (ROLE_ACCESS_VALUE_WEIGHTS[role?.roleId] || 0) * WEIGHT_MODIFIER;
  const typeWeight =
    (TYPE_ACCESS_VALUE_WEIGHTS[role?.link?.type] || 0) * (WEIGHT_MODIFIER / 2);
  return accessWeight + typeWeight;
}

/**
 * A sorting function that compares the weights of two roles and orders them in
 * the most permissive based on weight and where the role is attached at.
 *
 * @param {Object} a - an object that has an RBAC role string and a link to
 *     to source of where the role has access to. Usually a project or an org.
 * @param {Object} b - an object that has an RBAC role string and a link to
 *     to source of where the role has access to. Usually a project or an org.
 * @return {Number}
 */
function compareRolesByEffectiveness(a: Role, b: Role) {
  const leftWeight = calculateWeight(a);
  const rightWeight = calculateWeight(b);
  return rightWeight - leftWeight;
}

/**
 * Sorts the roles by effectiveness in descending order of most effective.
 *
 * @param {Array} roles - a list of roles in any order.
 * @return {Array}
 */
function sortEffectiveRoles(roles: Array<Role>) {
  const effectiveRoles = roles && roles.length ? [...roles] : [];

  if (roles && roles.length) {
    // If there is only one role then it's already sorted.
    if (roles.length !== 1) {
      // We should sort the array by the weights of both the role
      // and where the role is attached to. The project role should be
      // displayed over the same organization role.
      effectiveRoles.sort(compareRolesByEffectiveness);
    }
  }

  return effectiveRoles;
}

export default class RolesService extends Service {
  sortEffectiveRoles(roles: Array<Role>) {
    return sortEffectiveRoles(roles);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    roles: RolesService;
  }
}
