/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointRefAddOnDefinitionFromJSON, HashicorpCloudWaypointRefAddOnDefinitionToJSON, HashicorpCloudWaypointRefApplicationFromJSON, HashicorpCloudWaypointRefApplicationToJSON, HashicorpCloudWaypointTFOutputValueFromJSON, HashicorpCloudWaypointTFOutputValueToJSON, HashicorpCloudWaypointTagFromJSON, HashicorpCloudWaypointTagToJSON, HashicorpCloudWaypointTerraformNocodeModuleFromJSON, HashicorpCloudWaypointTerraformNocodeModuleToJSON, HashicorpCloudWaypointTerraformTFRunStateFromJSON, HashicorpCloudWaypointTerraformTFRunStateToJSON, } from './';
export function HashicorpCloudWaypointAddOnFromJSON(json) {
    return HashicorpCloudWaypointAddOnFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointAddOnFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'application': !exists(json, 'application') ? undefined : HashicorpCloudWaypointRefApplicationFromJSON(json['application']),
        'definition': !exists(json, 'definition') ? undefined : HashicorpCloudWaypointRefAddOnDefinitionFromJSON(json['definition']),
        'readmeMarkdown': !exists(json, 'readme_markdown') ? undefined : json['readme_markdown'],
        'readme': !exists(json, 'readme') ? undefined : json['readme'],
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'terraformNocodeModule': !exists(json, 'terraform_nocode_module') ? undefined : HashicorpCloudWaypointTerraformNocodeModuleFromJSON(json['terraform_nocode_module']),
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'createdBy': !exists(json, 'created_by') ? undefined : json['created_by'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'count': !exists(json, 'count') ? undefined : json['count'],
        'status': !exists(json, 'status') ? undefined : HashicorpCloudWaypointTerraformTFRunStateFromJSON(json['status']),
        'tags': !exists(json, 'tags') ? undefined : (json['tags'].map(HashicorpCloudWaypointTagFromJSON)),
        'outputValues': !exists(json, 'output_values') ? undefined : (json['output_values'].map(HashicorpCloudWaypointTFOutputValueFromJSON)),
        'moduleSource': !exists(json, 'module_source') ? undefined : json['module_source'],
    };
}
export function HashicorpCloudWaypointAddOnToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'application': HashicorpCloudWaypointRefApplicationToJSON(value.application),
        'definition': HashicorpCloudWaypointRefAddOnDefinitionToJSON(value.definition),
        'readme_markdown': value.readmeMarkdown,
        'readme': value.readme,
        'labels': value.labels,
        'terraform_nocode_module': HashicorpCloudWaypointTerraformNocodeModuleToJSON(value.terraformNocodeModule),
        'summary': value.summary,
        'description': value.description,
        'created_by': value.createdBy,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'count': value.count,
        'status': HashicorpCloudWaypointTerraformTFRunStateToJSON(value.status),
        'tags': value.tags === undefined ? undefined : (value.tags.map(HashicorpCloudWaypointTagToJSON)),
        'output_values': value.outputValues === undefined ? undefined : (value.outputValues.map(HashicorpCloudWaypointTFOutputValueToJSON)),
        'module_source': value.moduleSource,
    };
}
