import _ from 'lodash';

const OrganizationTypePart = 'organization';
const ProjectTypePart = 'project';

// ParseResourceName will parse a resource name into its namespace and
// type-name-tuples.
export function ParseResourceName(resourceName) {
  const [namespace, ...parts] = resourceName.split('/');

  if (!namespace) {
    return null;
  }

  // Handle organization and project resource names (which consist of two parts)
  if (
    parts.length === 1 &&
    [ProjectTypePart, OrganizationTypePart].includes(namespace)
  ) {
    return { namespace: 'resource-manager', parts: [[namespace, parts[0]]] };
  }

  if (parts.length === 0 || parts.length % 2 !== 0) {
    return null;
  }

  return { namespace, parts: _.chunk(parts, 2) };
}

// CollapseResourceName can be used to collapse a resource name to a shorter
// version.
export function CollapseResourceName(resourceName, context, collapseType) {
  // Parse resource name to validate it
  if (!ParseResourceName(resourceName)) {
    return null;
  }

  // Replace type parts with "..." if the type should be collapsed
  if (collapseType) {
    resourceName = replaceTypesWithEllipses(resourceName);

    if (!resourceName) {
      return null;
    }
  }

  // Replace context with "..." if context is provided
  if (context) {
    // Parse context to validate it
    if (!ParseResourceName(context)) {
      return null;
    }

    // If the type should be collapsed also collapse the type on the context to
    // ensure it matches the collapsed resource name.
    if (collapseType) {
      context = replaceTypesWithEllipses(context);
      if (!context) {
        return null;
      }
    }

    // Replace the context in the resource name
    resourceName = resourceName.replace(context, '...');
  }

  // Replace all doubled up ellipses
  resourceName = resourceName.replaceAll('.../...', '...');

  // Check if the only remaining part is the last name (e.g. .../my-cluster) and
  // return that part only if it is
  const parts = resourceName.split('/');
  if (parts.length === 2 && parts[0] === '...') {
    return parts[1];
  }

  return resourceName;
}

// replaceTypesWithEllipses will replace any type parts in a resource name with
// ellipsis. E.g. vault/project/a70fffe7-4c14-4f4f-bbc7-18aa9e3a221c/cluster/my-cluster
// -> .../.../a70fffe7-4c14-4f4f-bbc7-18aa9e3a221c/.../my-cluster
function replaceTypesWithEllipses(resourceName) {
  const parsed = ParseResourceName(resourceName);
  if (!parsed) {
    return null;
  }

  parsed.namespace = '...';
  parsed.parts = parsed.parts.map((part) => ['...', part[1]]);

  return _.flattenDeep([parsed.namespace, parsed.parts]).join('/');
}
