import vaultScenario from './vault';

// A scenario with clusters in a variety of states
// Running, Creating, Updating, Deleting, Locked and Failed
// These states impact what shows on the page and what clusters are able to do.
export default function (server) {
  vaultScenario(server, {
    // we don't need clusters set up for us since we are going to set them up below
    createVault: false,
  });

  const loc = server.schema.first('location.location');
  const network = server.schema.findBy('network.network', {
    id: 'test-network',
  });

  server.create('vault.cluster', 'starter', {
    id: 'creating-cluster',
    state: 'CREATING',
    location: loc,
    $network_id: network.id,
  });

  server.create('vault.cluster', 'starter', {
    id: 'running-cluster',
    state: 'RUNNING',
    location: loc,
    $network_id: network.id,
  });

  server.create('vault.cluster', 'starter', {
    id: 'updating-cluster',
    state: 'UPDATING',
    location: loc,
    $network_id: network.id,
  });

  server.create('vault.cluster', 'starter', {
    id: 'deleting-cluster',
    state: 'DELETING',
    location: loc,
    $network_id: network.id,
  });

  server.create('vault.cluster', 'starter', {
    id: 'failed-cluster',
    state: 'FAILED',
    location: loc,
    $network_id: network.id,
  });

  server.create('vault.cluster', 'starter', {
    id: 'locked-cluster',
    state: 'LOCKED',
    location: loc,
    $network_id: network.id,
  });

  server.create('vault.cluster', 'plus', 'upgrade-type.manual', {
    id: 'needs-update',
    state: 'RUNNING',
    location: loc,
    $network_id: network.id,
    notifications: [
      {
        topic: 'NEW_MAJOR_VERSION_AVAILABLE',
        meta_data: {
          available_major_version: '1.10',
          available_minor_version: '',
          change_log_url:
            'https://github.com/hashicorp/vault/blob/main/CHANGELOG.md',
          end_of_cycle_date: '19/06/2022',
          upgrade_guide_url:
            'https://www.vaultproject.io/docs/upgrading/upgrade-to-1.10.x',
        },
      },
    ],
  });

  // Disaster Recovery
  const project = server.schema.first('resource-manager.project');
  const awsRegion = server.create('location.region', {
    region: 'us-east-1',
    provider: 'aws',
  });
  const location = server.create('location.location', {
    organizationId: project.parent.id,
    projectId: project.id,
    regionId: awsRegion.id,
  });

  server.create('vault.cluster', 'plus', 'disaster-recovery', {
    id: 'disaster-recovery-cluster',
    state: 'RUNNING',
    location: location,
    $network_id: network.id,
  });

  // Create secondary cluster
  // Create aws network with unique cidr
  const secondaryNetwork = server.create('network.network', {
    id: 'Unique network',
    state: 'STABLE',
    cidr_block: '10.12.2.2/20',
    location: loc,
  });
  server.create('vault.cluster', 'asSecondary', {
    id: 'Secondary-Cluster-1',
    $primary_id: 'needs-update',
    location: loc,
    $network_id: secondaryNetwork.id,
  });
}
