import observabilityScenario from './consulObservability';
import { viewerPermissions } from '../helpers/permissions';

export default function (server, options = {}) {
  // Set up user scenario.
  observabilityScenario(server, {
    allowedPermissions: viewerPermissions,
    ...options,
  });
}
