/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105ProjectOverviewFromJSON, Billing20201105ProjectOverviewToJSON, } from './';
export function Billing20201105ListStatementProjectOverviewsResponseFromJSON(json) {
    return Billing20201105ListStatementProjectOverviewsResponseFromJSONTyped(json, false);
}
export function Billing20201105ListStatementProjectOverviewsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'projectOverviews': !exists(json, 'project_overviews') ? undefined : (json['project_overviews'].map(Billing20201105ProjectOverviewFromJSON)),
    };
}
export function Billing20201105ListStatementProjectOverviewsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'project_overviews': value.projectOverviews === undefined ? undefined : (value.projectOverviews.map(Billing20201105ProjectOverviewToJSON)),
    };
}
