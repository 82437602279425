import { DateTime } from 'luxon';
import { assert } from '@ember/debug';
import BillingCredits from './billing-credits';
import BillingStatus from './billing-status';
import BillingFCPBalance from './billing-fcp-balance';
import { PRICING_MODEL } from '../constants/billing';
import { BILLING_ACCOUNT_STANDINGS } from '../constants/status';

// hours in day * minutes in hour * seconds in minute * ms in seconds
const millisecondsInDay = 24 * 60 * 60 * 1000;
const sevenDays = 7 * millisecondsInDay;
const thirtyDays = 30 * millisecondsInDay;

function getTimeUntilExpiration(expirationDate) {
  let expiryDate = DateTime.fromJSDate(expirationDate, { zone: 'utc' });
  return expiryDate.diff(DateTime.utc()).toObject();
}

function getTimeSinceActivation(activationDate) {
  let activeFrom = DateTime.fromJSDate(activationDate, { zone: 'utc' });
  return DateTime.utc().diff(activeFrom).toObject();
}

// TODO: Enable either private or static method Babel transpilation.
function isPaymentExpired({ expMonth, expYear }) {
  if (!expMonth || !expYear) {
    assert('Must provide expiry month and year');
  }
  let expiration = getPaymentExpiration({ month: expMonth, year: expYear });
  return DateTime.utc() > expiration;
}

// TODO: Enable either private or static method Babel transpilation.
function getPaymentExpiration({ month, year }) {
  return DateTime.fromObject({ month, year }).toUTC().endOf('month');
}

export default class Billing {
  #billingAccount;
  #onDemandBillingMethodDetails;
  #entitlementBillingMethodDetails;
  #flexibleConsumptionBillingMethodDetails;
  #fcp;

  /**
   * @param {object} [seed={}]
   * @param {object} [seed.billingAccount]
   * @param {string} [seed.billingAccount.id]
   * @param {object} [seed.billingAccount.status]
   * @param {boolean} [seed.billingAccount.status.isTrial]
   * @param {object} [seed.onDemandBillingMethodDetails]
   * @param {object} [seed.entitlementBillingMethodDetails]
   * @param {object} [seed.flexibleConsumptionBillingMethodDetails]
   * @param {object} [seed.fcp]
   * @param {object} [seed.transitions]
   */
  constructor({
    billingAccount = null,
    onDemandBillingMethodDetails = null,
    entitlementBillingMethodDetails = null,
    flexibleConsumptionBillingMethodDetails = null,
    fcp = null,
  } = {}) {
    this.#billingAccount = billingAccount;
    this.#onDemandBillingMethodDetails = onDemandBillingMethodDetails;
    this.#entitlementBillingMethodDetails = entitlementBillingMethodDetails;
    this.#flexibleConsumptionBillingMethodDetails =
      flexibleConsumptionBillingMethodDetails;
    this.#fcp = fcp;
  }

  get billingAccount() {
    return this.#billingAccount;
  }

  /** @type {string} */
  get pricingModel() {
    return this.billingAccount?.pricingModel;
  }

  /** @type {BillingCredits} */
  get credits() {
    // estimatedRemainingCredits is an empty string if not OnDemand or Trial account
    return this.billingAccount?.estimatedRemainingCredits
      ? new BillingCredits(this.billingAccount?.estimatedRemainingCredits)
      : null;
  }

  /** @type {object|null} */
  get cardDetails() {
    return this.#onDemandBillingMethodDetails?.cardDetails || null;
  }

  /** @type {object|null} */
  get billingMethodDetails() {
    return this.#onDemandBillingMethodDetails?.billingMethod || null;
  }

  /** @type {BillingStatus} */
  get status() {
    return new BillingStatus(
      this.billingAccount?.status,
      this.billingAccount?.onDemandStatus,
    );
  }

  /** @type {object|null} */
  // upcomingContract is the contract starting on some future date.
  // It returns null if there are no upcoming contracts.
  get upcomingContract() {
    return this.#fcp?.upcomingContract || null;
  }

  /** @type {object|null} */
  // latestContract will return the most recent contract that has already started,
  // including expired contracts if they exist.
  get latestContract() {
    return this.#fcp?.latestContract || null;
  }

  /** @type {boolean} */
  // hasExpiredContract will return the existence of expired contract(s), which indicates that the active contract is a renewal.
  get hasExpiredContract() {
    return this.#fcp?.hasExpiredContract;
  }

  /** @type {BillingFCPBalance} */
  get fcpBalance() {
    return this.hasConsumptionPayment
      ? new BillingFCPBalance(
          this.latestContract.flexDetails?.initialAmount,
          this.#flexibleConsumptionBillingMethodDetails?.estimatedRemainingBalance,
        )
      : null;
  }

  /** @type {boolean} */
  get hasCardError() {
    return this.status?.isDelinquent || this.cardIsExpired;
  }

  /** @type {boolean} */
  get cardIsExpired() {
    return this.cardDetails && isPaymentExpired(this.cardDetails);
  }

  /** @type {boolean} */
  get renewalRecentlyActivated() {
    if (
      this.latestContract &&
      this.latestContract.status === BILLING_ACCOUNT_STANDINGS.ACTIVE &&
      this.hasExpiredContract
    ) {
      let timeSinceActivation = getTimeSinceActivation(
        this.latestContract.activeFrom,
      );

      return (
        this.hasConsumptionPayment &&
        timeSinceActivation.milliseconds <= sevenDays
      );
    }

    return false;
  }

  /** @type {boolean} */
  get contractExpiringSoon() {
    if (this.latestContract) {
      let timeUntilExpiration = getTimeUntilExpiration(
        this.latestContract.activeUntil,
      );

      return (
        this.hasConsumptionPayment &&
        timeUntilExpiration.milliseconds <= thirtyDays &&
        timeUntilExpiration.milliseconds > 0
      );
    }

    return false;
  }

  /** @type {boolean} */
  get contractExpired() {
    let timeUntilExpiration = getTimeUntilExpiration(
      this.latestContract.activeUntil,
    );
    return (
      this.hasConsumptionPayment &&
      this.latestContract &&
      timeUntilExpiration.milliseconds <= 0
    );
  }

  /** @type {boolean} */
  get expiredDepletedNoRenewal() {
    return (
      this.contractExpired &&
      !this.upcomingContract &&
      this.fcpBalance.estimatedRemainingBalance <= 0
    );
  }

  // Contract here refers to an Entitlement contract
  /** @type {boolean} */
  get depletedNoRenewal() {
    return (
      !this.upcomingContract && this.fcpBalance.estimatedRemainingBalance <= 0
    );
  }

  /** @type {boolean} */
  get hasContractPayment() {
    return this.pricingModel === PRICING_MODEL.ENTITLEMENT;
  }

  // We can return the entitlement contract's details from the
  // entitlementBillingMethodDetails for now, but will need to
  // consider the concept of latestEntitlementContract,
  // expired, future, etc. once the BE infrastructure supports this
  /** @type {object|null} */
  get entitlementContractDetails() {
    return this.hasContractPayment
      ? this.#entitlementBillingMethodDetails?.billingMethod
      : null;
  }

  /** @type {boolean} */
  get hasConsumptionPayment() {
    return this.pricingModel === PRICING_MODEL.FLEX;
  }

  // Will return true for both Trial and On Demand accounts
  // To check for On Demand only, use hasOnDemandPayment
  /** @type {boolean} */
  get hasTrialOrOnDemandPayment() {
    return this.pricingModel === PRICING_MODEL.PAYG;
  }

  // Both Trial and On Demand accounts will return PRICING_MODEL_PAYG
  // so we also need to check that the On Demand status is not `UNSET`
  // to confirm that it is not a Trial account
  /** @type {boolean} */
  get hasOnDemandPayment() {
    return (
      this.pricingModel === PRICING_MODEL.PAYG && !this.status.isOnDemandUnset
    );
  }
}
