import {
  TYPE_BOUNDARY,
  TYPE_CONSUL,
  TYPE_PACKER,
  TYPE_VAULT,
  TYPE_VAULT_SECRETS_PROJECT,
  TYPE_TERRAFORM,
  TYPE_TERRAFORM_ORGANIZATION,
} from 'common/utils/cloud-resource-types';

export const BILLED_RESOURCES = [
  TYPE_BOUNDARY,
  TYPE_CONSUL,
  TYPE_PACKER,
  TYPE_VAULT,
  TYPE_VAULT_SECRETS_PROJECT,
  TYPE_TERRAFORM,
  TYPE_TERRAFORM_ORGANIZATION,
];

export const UNIT_DISPLAY_KEYS = {
  hour: 'hr',
  op: 'op',
  session: 'session',
  image: 'image',
  request: 'request',
  apply: 'apply',
  client: 'client',
};

export const FLEX_CLUSTER_TIER_SIZE = [
  'Development Tier',
  'Starter tier (Extra small)',
  'Standard tier (Small)',
  'Standard tier (Medium)',
  'Standard tier (Large)',
  'Plus tier (Small)',
  'Plus tier (Medium)',
  'Plus tier (Large)',
];

export const PRICING_MODEL = {
  // PRICING_MODEL_UNSPECIFIED is an invalid pricing model, which
  // is used to flag when the pricing model was left blank.
  UNSPECIFIED: 'PRICING_MODEL_UNSPECIFIED',
  // PRICING_MODEL_PAYG represents the PAYG pricing model
  // (OnDemand and Trial are considered subsets of PAYG by the backend).
  PAYG: 'PRICING_MODEL_PAYG',
  // PRICING_MODEL_FLEX represents the FLEX (or FCP) pricing model.
  FLEX: 'PRICING_MODEL_FLEX',
  // PRICING_MODEL_ENTITLEMENT represents the Entitlement (or contracts) pricing model.
  ENTITLEMENT: 'PRICING_MODEL_ENTITLEMENT',
};

// STATEMENT_STATE represents the current state of a statement.
export const STATEMENT_STATE = {
  // STATEMENT_STATE_PAID for FLEX is when a statement is recognized.
  // STATEMENT_STATE_PAID for PAYG is when a stripe invoice is paid.
  PAID: 'STATEMENT_STATE_PAID',

  // STATEMENT_STATE_RUNNING is for the latest current billing period statement.
  // There should be only one running statement per billing period per billing account at a time.
  RUNNING: 'STATEMENT_STATE_RUNNING',

  // STATEMENT_STATE_SUBMITTED means the statement was sent for collection and can no longer
  // be changed. It's yet to be paid.
  SUBMITTED: 'STATEMENT_STATE_SUBMITTED',

  // STATEMENT_STATE_VOID means that this statement no longer requires action from the user.
  // This statement will be ignored by the backend and the user will not be charged.
  VOID: 'STATEMENT_STATE_VOID',

  // STATEMENT_STATE_WONTPAY means that attempts to collect money for the payment were exhausted.
  // This also means the account could enter the delinquency flow because of this.
  WONTPAY: 'STATEMENT_STATE_WONTPAY',

  // STATEMENT_STATE_UNSET means the current state of the statement is not set or not specified.
  // This indicates an invalid statement and should not be a state that is returned from the backend,
  // but is noted here for awareness.
  // UNSET: 'STATEMENT_STATE_UNSET',
};
