/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128CapabilityFromJSON, Secrets20231128CapabilityToJSON, Secrets20231128GcpFederatedWorkloadIdentityRequestFromJSON, Secrets20231128GcpFederatedWorkloadIdentityRequestToJSON, Secrets20231128GcpServiceAccountKeyRequestFromJSON, Secrets20231128GcpServiceAccountKeyRequestToJSON, } from './';
export function SecretServiceUpdateGcpIntegrationBodyFromJSON(json) {
    return SecretServiceUpdateGcpIntegrationBodyFromJSONTyped(json, false);
}
export function SecretServiceUpdateGcpIntegrationBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'federatedWorkloadIdentity': !exists(json, 'federated_workload_identity') ? undefined : Secrets20231128GcpFederatedWorkloadIdentityRequestFromJSON(json['federated_workload_identity']),
        'serviceAccountKey': !exists(json, 'service_account_key') ? undefined : Secrets20231128GcpServiceAccountKeyRequestFromJSON(json['service_account_key']),
        'capabilities': !exists(json, 'capabilities') ? undefined : (json['capabilities'].map(Secrets20231128CapabilityFromJSON)),
    };
}
export function SecretServiceUpdateGcpIntegrationBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'federated_workload_identity': Secrets20231128GcpFederatedWorkloadIdentityRequestToJSON(value.federatedWorkloadIdentity),
        'service_account_key': Secrets20231128GcpServiceAccountKeyRequestToJSON(value.serviceAccountKey),
        'capabilities': value.capabilities === undefined ? undefined : (value.capabilities.map(Secrets20231128CapabilityToJSON)),
    };
}
