import AuthzBuilder from 'common/utils/authz';

const Features = {
  Architecture: 'architecture',
  Webhooks: 'webhooks',
  Deliveries: 'deliveries',
};

export function allPermissions() {
  const builder = new AuthzBuilder('webhook', Features);
  builder.addCRUDLActions(Features.Webhooks, ['get']);
  builder.addCRUDLActions(Features.Deliveries);

  return builder;
}

export function contributorPermissions() {
  const builder = new AuthzBuilder('webhook', Features);
  builder.addCRUDLActions(Features.Webhooks, ['get']);
  builder.addCRUDLActions(Features.Deliveries);

  return builder;
}

export function viewerPermissions() {
  const builder = new AuthzBuilder('webhook', Features);
  builder.addRLActions(Features.Webhooks, ['get']);
  builder.addRLActions(Features.Deliveries);

  return builder;
}

// Eagerly generate flat permissions list since this is the most common use case.
export const ALL_PERMISSIONS = allPermissions().list();
export const CONTRIBUTOR_PERMISSIONS = contributorPermissions().list();
export const VIEWER_PERMISSIONS = viewerPermissions().list();
