import baseScenario from './user';

export default function (server) {
  baseScenario(server, {
    createBilling: false,
    onDemandBillingMethodDetails: false,
    createConsul: false,
    createNetwork: false,
    hasNoOwnedOrg: true,
    hasMultipleOrgMemberships: false,
    hasVerifiedEmail: true,
  });
}
