import userScenario from './user';
import {
  createProjectPermissions,
  fullPermissions,
} from '../helpers/permissions';
import { createProjectPolicy } from '../helpers/project-policy';
import getPlatformContext from '../helpers/get-platform-context';
import { faker } from '@faker-js/faker';

export default function (server, options = { projectCount: 1 }) {
  userScenario(server, options);

  let org = server.schema.first('resource-manager.organization');
  org.update({
    tfc_synced: true,
  });

  let current_user = server.schema.findBy('iam.user-principal', {
    current_user: true,
  });
  current_user.update({
    tfc_synchronized_at: faker.date.past().toISOString(),
  });

  const { user, organization } = getPlatformContext(server);

  server.create('idp.flag', {
    $organization: organization,
    flag_name: 'hcp-tfc-unified-experience',
    flag_enabled: true,
  });

  const { projectCount } = options;

  for (let i = 0; i < projectCount; i++) {
    let project = server.create('resource-manager.project', {
      parent: {
        type: 'ORGANIZATION',
        id: organization.id,
      },
    });

    createProjectPermissions(server, {
      organization: organization,
      project: project,
      user: user,
      allowedPermissions: fullPermissions,
    });

    createProjectPolicy(server, { project, user });

    server.create('tfc-synchronization.notification');
  }
}
