import noRoleScenario from './no-role';
import { deleteProjectPolicy } from '../helpers/project-policy';
const options = {
  createBilling: true,
};

export default function (server) {
  const defaultOrganization = noRoleScenario(server, options);

  const allDefaultOrganizationProjects = server.schema.where(
    'resource-manager.project',
    (model) => {
      return model.parent.id === defaultOrganization.id;
    }
  );

  for (let project of allDefaultOrganizationProjects.models) {
    deleteProjectPolicy(server, { project });
  }
}
