/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105StatementResourceFromJSON, Billing20201105StatementResourceToJSON, Billing20201105StatementStateFromJSON, Billing20201105StatementStateToJSON, StatementStatementMetadataFromJSON, StatementStatementMetadataToJSON, } from './';
export function Billing20201105StatementFromJSON(json) {
    return Billing20201105StatementFromJSONTyped(json, false);
}
export function Billing20201105StatementFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'billingPeriodStart': !exists(json, 'billing_period_start') ? undefined : (new Date(json['billing_period_start'])),
        'billingPeriodEnd': !exists(json, 'billing_period_end') ? undefined : (new Date(json['billing_period_end'])),
        'total': !exists(json, 'total') ? undefined : json['total'],
        'resources': !exists(json, 'resources') ? undefined : (json['resources'].map(Billing20201105StatementResourceFromJSON)),
        'lastUpdatedAt': !exists(json, 'last_updated_at') ? undefined : (new Date(json['last_updated_at'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'nonUsageCharges': !exists(json, 'non_usage_charges') ? undefined : (json['non_usage_charges'].map(Billing20201105StatementResourceFromJSON)),
        'statementMetadata': !exists(json, 'statement_metadata') ? undefined : StatementStatementMetadataFromJSON(json['statement_metadata']),
        'state': !exists(json, 'state') ? undefined : Billing20201105StatementStateFromJSON(json['state']),
    };
}
export function Billing20201105StatementToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'billing_period_start': value.billingPeriodStart === undefined ? undefined : (value.billingPeriodStart.toISOString()),
        'billing_period_end': value.billingPeriodEnd === undefined ? undefined : (value.billingPeriodEnd.toISOString()),
        'total': value.total,
        'resources': value.resources === undefined ? undefined : (value.resources.map(Billing20201105StatementResourceToJSON)),
        'last_updated_at': value.lastUpdatedAt === undefined ? undefined : (value.lastUpdatedAt.toISOString()),
        'id': value.id,
        'non_usage_charges': value.nonUsageCharges === undefined ? undefined : (value.nonUsageCharges.map(Billing20201105StatementResourceToJSON)),
        'statement_metadata': StatementStatementMetadataToJSON(value.statementMetadata),
        'state': Billing20201105StatementStateToJSON(value.state),
    };
}
