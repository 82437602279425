/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import Service from '@ember/service';
import Cookies from 'js-cookie';
import { warn } from '@ember/debug';
import { isTesting } from '@embroider/macros';
import { faker } from '@faker-js/faker';
import scenarios from '../mirage/scenarios/index';

const SCENARIO_COOKIE_NAME = 'mirage-scenario';
const SCENARIO_BLOCK_LIST = ['default', 'snapshots'];

const TIMING_COOKIE_NAME = 'mirage-timing';
const TIMINGS = Object.freeze([
  { label: 'Instant (0ms)', value: 0 },
  { label: 'Fast (100ms)', value: 100 },
  { label: 'Okay (300ms)', value: 300 },
  { label: 'Slow (1000ms)', value: 1000 },
]);
const DEFAULT_TIMING = TIMINGS[0];

const FAKER_COOKIE_NAME = 'mirage-faker';
const DEFAULT_FAKER_SEED = 0;
const SCENARIOS = scenarios;
const SCENARIO_SCALE = Object.keys(SCENARIOS).filter(
  (scenario) => !SCENARIO_BLOCK_LIST.includes(scenario)
);
const DEFAULT_SCENARIO_NAME = 'user';

export default class MirageScenarioService extends Service {
  scenarioScale = SCENARIO_SCALE;
  defaultScenario = DEFAULT_SCENARIO_NAME;

  /**
   * Starts the Mirage server with the specified configuration.
   *
   * @param server - The Mirage server instance.
   * @param options - The options for configuring the server.
   * @param options.scenarioScale - The scale of the scenario to use. If not provided, the default scale will be used. Loading all scenarios.
   * @param options.defaultScenario - The default scenario to use. If not provided, the first scenario in the scale will be used.
   */
  start(
    server,
    {
      scenarioScale,
      defaultScenario,
    }: { scenarioScale?: string[]; defaultScenario?: string } = {}
  ) {
    this.scenarioScale = scenarioScale || this.scenarioScale;
    this.defaultScenario = defaultScenario || this.defaultScenario;

    if (scenarioScale?.length && !defaultScenario) {
      this.defaultScenario = this.scenarioScale[0];
    }

    if (isTesting()) return;

    const scenarios = this.getScenarioModules();
    const seed = this.getFakerSeed();

    if (seed) {
      faker.seed(seed);
    }

    const scenario = this.getScenario();
    const timing = this.getTiming();

    warn(`Mirage: using scenario "${scenario}".`, false, {
      id: 'mirage-scenario-announcement',
    });

    warn(`Mirage: using timing "${timing.label}".`, false, {
      id: 'mirage-timing-announcement',
    });

    const seedMessage = seed
      ? `Mirage: using faker seed "${seed}"`
      : 'Mirage: no faker seed! Random data';
    warn(seedMessage, false, {
      id: 'mirage-faker-seed-announcement',
    });

    // Override the default pretender logging to filter out
    // operations polling by default
    server.pretender.handledRequest = this.customRequestLogger(server);
    server.timing = timing.value;
    scenarios[scenario](server);
    console.log('mirage scenario', scenario);
  }

  customRequestLogger(server) {
    // Copied and modified from mirage source:
    // https://github.com/miragejs/miragejs/blob/27cdfb57377e866ad73cd01bc70589a81a6f7c23/lib/mock-server/pretender-config.js#L316-L352
    return function (verb, path, request) {
      const noisyReqs = /\/operation\/.+?\/operations$/;
      if (
        server.shouldLog() &&
        (!noisyReqs.test(path) || server.logEverything)
      ) {
        console.groupCollapsed(
          `Mirage: [${request.status}] ${verb.toUpperCase()} ${request.url}`
        );
        const { requestBody, responseText } = request;
        let loggedRequest, loggedResponse;

        try {
          loggedRequest = JSON.parse(requestBody);
        } catch (e) {
          loggedRequest = requestBody;
        }

        try {
          loggedResponse = JSON.parse(responseText);
        } catch (e) {
          loggedResponse = responseText;
        }

        console.groupCollapsed('Response');
        console.log(loggedResponse);
        console.groupEnd();

        console.groupCollapsed('Request (data)');
        console.log(loggedRequest);
        console.groupEnd();

        console.groupCollapsed('Request (raw)');
        console.log(request);
        console.groupEnd();

        console.groupEnd();
      }
    };
  }
  sanitizeScenario(scenario) {
    const isRecognizedScenario = this.scenarioScale.includes(scenario);

    if (!isRecognizedScenario) {
      warn(
        `Mirage: scenario "${scenario}" is invalid, falling back to default scenario "${this.defaultScenario}".`,
        false,
        { id: 'mirage-scenario-invalid-using-default' }
      );

      scenario = this.defaultScenario;
      this.setScenario(this.defaultScenario);
    }

    return scenario;
  }

  getScenario() {
    const cookieScenario = Cookies.get(SCENARIO_COOKIE_NAME);
    const scenario = this.sanitizeScenario(cookieScenario);

    return scenario;
  }

  setScenario(candidateScenario) {
    const scenario = this.sanitizeScenario(candidateScenario);
    const path = '/';
    const expires = 1; // Days

    Cookies.set(SCENARIO_COOKIE_NAME, scenario, { path, expires });
  }

  listScenarios() {
    return this.scenarioScale;
  }

  getScenarioModules() {
    return SCENARIOS;
  }

  sanitizeTiming(value) {
    if (value instanceof Object) value = value.value;
    let timing = TIMINGS.find((item) => item.value === +value);
    if (!timing) {
      warn(
        `Mirage: timing "${value}" is not a predefined time, falling back to the default timing of "${DEFAULT_TIMING.label}."`,
        false,
        { id: 'mirage-timing-invalid-using-default' }
      );

      timing = DEFAULT_TIMING;
      this.setTiming(timing);
    }

    return timing;
  }

  getTiming() {
    const cookieTiming = Cookies.get(TIMING_COOKIE_NAME);
    const timing = this.sanitizeTiming(cookieTiming);

    return timing;
  }

  setTiming(candidateTiming) {
    console.log('Setting timing', candidateTiming);
    const timing = this.sanitizeTiming(candidateTiming);
    const path = '/';
    const expires = 1; // Days

    Cookies.set(TIMING_COOKIE_NAME, timing.value, { path, expires });
  }

  listTimings() {
    return TIMINGS;
  }

  sanitizeFakerSeed(value) {
    return value ? +value : DEFAULT_FAKER_SEED;
  }

  getFakerSeed() {
    const cookieFaker = Cookies.get(FAKER_COOKIE_NAME);
    const seed = this.sanitizeFakerSeed(cookieFaker);
    return seed;
  }

  setFakerSeed(candidateSeed) {
    const seed = this.sanitizeFakerSeed(candidateSeed);
    const path = '/';
    const expires = 100; // Days

    Cookies.set(FAKER_COOKIE_NAME, seed, { path, expires });
  }
}
