import consulScenario from './consul';
import { STATUSPAGE_IO_STATUS } from 'core/utils/constants';
import { faker } from '@faker-js/faker';
import {
  BILLING_ACCOUNT_STATUS,
  BILLING_ON_DEMAND_STATUS,
} from 'billing-common/constants/status';

export default function (server, options = {}) {
  // Set up user scenario.
  consulScenario(server, {
    systemStatus: {
      hcp: STATUSPAGE_IO_STATUS.OPERATIONAL,
      consul: STATUSPAGE_IO_STATUS.OPERATIONAL,
      packer: STATUSPAGE_IO_STATUS.OPERATIONAL,
      vault: STATUSPAGE_IO_STATUS.OPERATIONAL,
    },
    createConsul: true,
    numConsulConnectedClients: 1,
    consulPackages: {
      development: true,
      standard: true,
      plus: true,
    },
    consulPrimaryFederatedCluster: false,
    consulSecondaryFederatedCluster: false,
    createNetwork: true,
    createPeeringConnection: {
      state: 'PENDING_ACCEPTANCE',
      hvnRoutes: true,
    },
    createTgwAttachment: {
      state: 'PENDING_ACCEPTANCE',
      hvnRoutes: false,
    },
    // billing
    billingAccountOnDemandStatus: BILLING_ON_DEMAND_STATUS.UNSET,
    billingAccountStatus: BILLING_ACCOUNT_STATUS.TRIAL,
    billingRemainingBalance: undefined,
    billingZeroUsage: false,
    createBilling: true,
    hasExpiredCreditCard: false,
    onDemandBillingMethodDetails: true,
    // flex
    // end billing
    hasNoOwnedOrg: false,
    hasVerifiedEmail: true,
    // don't create vault stuff because mirage will freak out
    vaultPackages: false,
    createVault: false,
    ...options,
  });

  let existingHcpManagedCluster = server.schema.first('consul.cluster');
  let hcpLoc = server.schema.findBy('location.location', {
    id: existingHcpManagedCluster.locationId,
  });
  let networkId = existingHcpManagedCluster.config.network_config.network.id;
  let networkLink = server.schema.findBy('location.link', { id: networkId });
  server.create('consul.cluster', {
    id: 'test-hcp-observability',
    consul_version: 'v1.16.1',
    created_at: '2020-10-14T02:16:17.187Z',
    resourceId: faker.string.uuid(),
    config: {
      capacity_config: {
        size: 'MEDIUM',
      },
      network_config: {
        network: networkLink,
        private: false,
        ip_allowlist: [],
      },
      tier: 'STANDARD',
      primary: null,
    },
    dns_names: {
      public: `test-cluster.public.consul.${networkId}.aws.hcp.dev`,
      private: `test-cluster.private.consul.${networkId}.aws.hcp.dev`,
    },
    location: hcpLoc,
  });

  let firstSelfManagedCluster = server.schema.first(
    'consul-global-network-manager.cluster'
  );
  let loc = server.schema.findBy('location.location', {
    id: firstSelfManagedCluster.locationId,
  });

  let secondSelfManagedConsulCluster = server.create(
    'consul-global-network-manager.cluster',
    {
      id: 'test-sm-observability-single-node',
      name: 'test-sm-observability-single-node',
      consul_version: 'v1.15.3',
      location: loc,
      envoy_configured: true,
    }
  );
  server.createList('consul-global-network-manager.cluster.server', 1, {
    cluster: secondSelfManagedConsulCluster,
  });

  let thirdSelfManagedConsulCluster = server.create(
    'consul-global-network-manager.cluster',
    {
      id: 'test-sm-observability',
      name: 'test-sm-observability',
      consul_version: 'v1.15.3',
      location: loc,
      envoy_configured: true,
    }
  );
  server.createList('consul-global-network-manager.cluster.server', 5, {
    cluster: thirdSelfManagedConsulCluster,
  });

  let fourthSelfManagedConsulCluster = server.create(
    'consul-global-network-manager.cluster',
    {
      id: 'test-sm-observability-no-envoy',
      name: 'test-sm-observability-no-envoy',
      consul_version: 'v1.15.5',
      location: loc,
      envoy_configured: false,
      collector_deployed: true,
    }
  );
  server.createList('consul-global-network-manager.cluster.server', 5, {
    cluster: fourthSelfManagedConsulCluster,
  });

  let selfManagedClusterWithoutCdpMetricsConfigured = server.create(
    'consul-global-network-manager.cluster',
    {
      id: 'test-sm-no-cdp-metrics',
      name: 'test-sm-no-cdp-metrics',
      consul_version: 'v1.18.0',
      location: loc,
      cdp_metrics_configured: false,
    }
  );
  server.createList('consul-global-network-manager.cluster.server', 5, {
    cluster: selfManagedClusterWithoutCdpMetricsConfigured,
  });

  let selfManagedClusterWithCdpMetricsConfigured = server.create(
    'consul-global-network-manager.cluster',
    {
      id: 'test-sm-cdp-metrics',
      name: 'test-sm-cdp-metrics',
      consul_version: 'v1.18.0',
      location: loc,
      cdp_metrics_configured: true,
    }
  );
  server.createList('consul-global-network-manager.cluster.server', 5, {
    cluster: selfManagedClusterWithCdpMetricsConfigured,
  });

  let fifthSelfManagedConsulCluster = server.create(
    'consul-global-network-manager.cluster',
    {
      id: 'test-sm-observability-no-collector',
      name: 'test-sm-observability-no-collector',
      consul_version: 'v1.15.5',
      location: loc,
      envoy_configured: false,
      collector_deployed: false,
    }
  );
  server.createList('consul-global-network-manager.cluster.server', 5, {
    cluster: fifthSelfManagedConsulCluster,
  });

  let sixthSelfManagedConsulCluster = server.create(
    'consul-global-network-manager.cluster',
    {
      id: 'test-sm-observability-data-with-gaps',
      name: 'test-sm-observability-data-with-gaps',
      consul_version: 'v1.15.3',
      location: loc,
      envoy_configured: true,
    }
  );
  server.createList('consul-global-network-manager.cluster.server', 5, {
    cluster: sixthSelfManagedConsulCluster,
  });

  let selfManagedConsulClusterWithServiceMetrics = server.create(
    'consul-global-network-manager.cluster',
    {
      id: 'test-sm-service-observability',
      name: 'test-sm-service-observability',
      consul_version: 'v1.15.5',
      location: loc,
      envoy_configured: true,
      collector_deployed: true,
    }
  );
  server.createList('consul-global-network-manager.cluster.server', 5, {
    cluster: selfManagedConsulClusterWithServiceMetrics,
  });
  server.create('consul-global-network-manager.cluster.service-v2', {
    cluster_id: selfManagedConsulClusterWithServiceMetrics.id,
    name: 'ingress-gateway-service',
    kind: 'ingress-gateway',
    namespace: 'consul',
    partition: 'default',
    cluster_resource_name: `consul/project/${selfManagedConsulClusterWithServiceMetrics.location.projectId}/cluster/${selfManagedConsulClusterWithServiceMetrics.id}`,
  });
  server.create('consul-global-network-manager.cluster.service-v2', {
    cluster_id: selfManagedConsulClusterWithServiceMetrics.id,
    name: 'mesh-gateway-service',
    kind: 'mesh-gateway',
    namespace: 'consul',
    partition: 'default',
    cluster_resource_name: `consul/project/${selfManagedConsulClusterWithServiceMetrics.location.projectId}/cluster/${selfManagedConsulClusterWithServiceMetrics.id}`,
  });
  server.create('consul-global-network-manager.cluster.service-v2', {
    cluster_id: selfManagedConsulClusterWithServiceMetrics.id,
    name: 'api-gateway-service',
    kind: 'api-gateway',
    namespace: 'consul',
    partition: 'default',
    cluster_resource_name: `consul/project/${selfManagedConsulClusterWithServiceMetrics.location.projectId}/cluster/${selfManagedConsulClusterWithServiceMetrics.id}`,
  });
  server.create('consul-global-network-manager.cluster.service-v2', {
    cluster_id: selfManagedConsulClusterWithServiceMetrics.id,
    name: 'connext-proxy-service',
    kind: 'connext-proxy',
    namespace: 'consul',
    partition: 'default',
    cluster_resource_name: `consul/project/${selfManagedConsulClusterWithServiceMetrics.location.projectId}/cluster/${selfManagedConsulClusterWithServiceMetrics.id}`,
  });
  server.create('consul-global-network-manager.cluster.service-v2', {
    cluster_id: selfManagedConsulClusterWithServiceMetrics.id,
    name: 'typical-service',
    kind: 'typical',
    namespace: 'consul',
    partition: 'default',
    cluster_resource_name: `consul/project/${selfManagedConsulClusterWithServiceMetrics.location.projectId}/cluster/${selfManagedConsulClusterWithServiceMetrics.id}`,
  });
  server.create('consul-global-network-manager.cluster.service-v2', {
    cluster_id: selfManagedConsulClusterWithServiceMetrics.id,
    name: 'static',
    kind: 'typical',
    namespace: 'consul',
    partition: 'default',
    cluster_resource_name: `consul/project/${selfManagedConsulClusterWithServiceMetrics.location.projectId}/cluster/${selfManagedConsulClusterWithServiceMetrics.id}`,
  });
  server.create('consul-global-network-manager.cluster.service-v2', {
    cluster_id: selfManagedConsulClusterWithServiceMetrics.id,
    name: 'small',
    kind: 'typical',
    namespace: 'consul',
    partition: 'default',
    cluster_resource_name: `consul/project/${selfManagedConsulClusterWithServiceMetrics.location.projectId}/cluster/${selfManagedConsulClusterWithServiceMetrics.id}`,
  });
  server.create('consul-global-network-manager.cluster.service-v2', {
    cluster_id: selfManagedConsulClusterWithServiceMetrics.id,
    name: 'medium',
    kind: 'typical',
    namespace: 'consul',
    partition: 'default',
    cluster_resource_name: `consul/project/${selfManagedConsulClusterWithServiceMetrics.location.projectId}/cluster/${selfManagedConsulClusterWithServiceMetrics.id}`,
  });
  server.create('consul-global-network-manager.cluster.service-v2', {
    cluster_id: selfManagedConsulClusterWithServiceMetrics.id,
    name: 'large',
    kind: 'typical',
    namespace: 'consul',
    partition: 'default',
    cluster_resource_name: `consul/project/${selfManagedConsulClusterWithServiceMetrics.location.projectId}/cluster/${selfManagedConsulClusterWithServiceMetrics.id}`,
  });

  let selfManagedConsulClusterWithServiceMetricsNoEnvoy = server.create(
    'consul-global-network-manager.cluster',
    {
      id: 'test-sm-service-no-envoy-collector-deployed',
      name: 'test-sm-service-no-envoy-collector-deployed',
      consul_version: 'v1.15.5',
      location: loc,
      envoy_configured: false,
      collector_deployed: true,
    }
  );
  server.createList('consul-global-network-manager.cluster.server', 5, {
    cluster: selfManagedConsulClusterWithServiceMetricsNoEnvoy,
  });

  let selfManagedConsulClusterWithServiceMetricsNoEnvoyNoCollector =
    server.create('consul-global-network-manager.cluster', {
      id: 'test-sm-service-no-envoy-no-collector',
      name: 'test-sm-service-no-envoy-no-collector',
      consul_version: 'v1.15.5',
      location: loc,
      envoy_configured: false,
      collector_deployed: false,
    });
  server.createList('consul-global-network-manager.cluster.server', 5, {
    cluster: selfManagedConsulClusterWithServiceMetricsNoEnvoyNoCollector,
  });
}
