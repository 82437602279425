import consulScenario from './consul';
import {
  HashicorpCloudGlobalNetworkManager20220215Licensing,
  HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus,
  HashicorpCloudGlobalNetworkManager20220215PeeringIneligibilityReason,
} from '@clients/cloud-global-network-manager-service';
import { BILLING_ACCOUNT_STATUS } from 'billing-common/constants/status';
import { findLocationIdsForProject } from '../helpers/multi-network';

export default function (
  server,
  options = {
    billingAccountStatus: BILLING_ACCOUNT_STATUS.TRIAL,
  }
) {
  // Set up consul scenario.
  consulScenario(server, options);

  const currentUser = server.schema.findBy('iam.user-principal', {
    current_user: true,
  });
  const org = server.schema.findBy('resource-manager.organization', {
    ownerId: currentUser.id,
  });
  const location = server.schema.findBy('location.location', {
    organizationId: org.id,
  });
  const project = server.schema.findBy('resource-manager.project', {
    parent: {
      type: 'ORGANIZATION',
      id: org.id,
    },
  });
  const locationIds = findLocationIdsForProject(server.schema, {
    organizationId: org.id,
    projectId: project.id,
  });

  let communityCluster = server.create(
    'consul-global-network-manager.cluster',
    {
      id: 'community-cluster',
      name: 'community-cluster',
      consul_version: 'v1.16.1',
      location: location,
      licensing: HashicorpCloudGlobalNetworkManager20220215Licensing.COMMUNITY,
      peering_acceptor_ineligibility_codes: [
        HashicorpCloudGlobalNetworkManager20220215PeeringIneligibilityReason.INVALIDLICENSE,
      ],
    }
  );
  server.createList('consul-global-network-manager.cluster.server', 3, {
    cluster: communityCluster,
    version: 'v1.16.1',
  });

  const clusters = server.schema.where(
    'consul-global-network-manager.cluster',
    (cluster) => locationIds.includes(cluster.locationId)
  );

  const source_cluster = clusters.models.find((c) => c.id === 'test-cluster');
  const target_cluster = clusters.models.find(
    (c) => c.id === 'community-cluster'
  );
  // Updating HCP-managed cluster model with licensing
  source_cluster.update({
    licensing: HashicorpCloudGlobalNetworkManager20220215Licensing.ENTERPRISE,
  });
  const sourceClusterLocation = server.schema.findBy('location.location', {
    organizationId: source_cluster.location.organizationId,
    projectId: source_cluster.location.projectId,
    regionId: source_cluster.location.regionId,
  });

  server.create('consul-global-network-manager.cluster.peer', {
    source_cluster,
    source_partition: `${source_cluster.id}-ap`,
    source_licensing: source_cluster.licensing,
    target_cluster: target_cluster,
    target_partition: `${target_cluster.id}-ap`,
    target_licensing: target_cluster.licensing,
    peer_name: `${source_cluster.id}-${target_cluster.id}`,
    peering_connection_status:
      HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus.UNSYNCED,
    location: sourceClusterLocation,
    peer_licensing: target_cluster.licensing,
  });
}
