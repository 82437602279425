import userScenario from './user';
import { STATUSPAGE_IO_STATUS } from 'core/utils/constants';

export default function (server, options = {}) {
  // Set up user scenario.
  userScenario(server, {
    systemStatus: {
      hcp: STATUSPAGE_IO_STATUS.OPERATIONAL,
      consul: STATUSPAGE_IO_STATUS.OPERATIONAL,
      packer: STATUSPAGE_IO_STATUS.OPERATIONAL,
      vault: STATUSPAGE_IO_STATUS.OPERATIONAL,
    },
    billingRemainingBalance: undefined,
    createConsul: true,
    numConsulConnectedClients: 1,
    consulPackages: {
      development: true,
      standard: true,
      plus: true,
      premium: true,
    },
    consulPrimaryFederatedCluster: false,
    consulSecondaryFederatedCluster: false,
    createNetwork: true,
    createPeeringConnection: {
      state: 'PENDING_ACCEPTANCE',
      hvnRoutes: true,
    },
    createTgwAttachment: {
      state: 'PENDING_ACCEPTANCE',
      hvnRoutes: false,
    },
    createBilling: true,
    createServicePrincipals: { organization: true, project: true },
    hasExpiredCreditCard: false,
    hasNoOwnedOrg: false,
    hasVerifiedEmail: false,
    onDemandBillingMethodDetails: false,
    // don't create vault stuff because mirage will freak out
    vaultPackages: false,
    createVault: false,
    ...options,
  });

  server.create('consul.upgrade-version', {
    version: 'v1.16.1',
    status: 'AVAILABLE',
  });
  server.create('consul.upgrade-version', {
    version: 'v1.16.0',
    status: 'AVAILABLE',
  });
  server.create('consul.upgrade-version', {
    version: 'v1.15.5',
    status: 'RECOMMENDED',
  });
  server.create('consul.upgrade-version', {
    version: 'v1.15.3',
    status: 'AVAILABLE',
  });
  server.create('consul.upgrade-version', {
    version: 'v1.10.4-beta3',
    status: 'AVAILABLE',
  });

  server.create('consul.upgrade-version', {
    version: 'v1.10.3',
    status: 'AVAILABLE',
  });

  server.create('consul.upgrade-version', {
    version: 'v1.10.2',
    status: 'AVAILABLE',
  });

  server.create('consul.upgrade-version', {
    version: 'v1.10.1',
    status: 'AVAILABLE',
  });
}
