/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointActionCfgRefFromJSON, HashicorpCloudWaypointActionCfgRefToJSON, HashicorpCloudWaypointTFModuleVariableFromJSON, HashicorpCloudWaypointTFModuleVariableToJSON, HashicorpCloudWaypointTagFromJSON, HashicorpCloudWaypointTagToJSON, HashicorpCloudWaypointTerraformCloudWorkspaceDetailsFromJSON, HashicorpCloudWaypointTerraformCloudWorkspaceDetailsToJSON, HashicorpCloudWaypointTerraformNocodeModuleFromJSON, HashicorpCloudWaypointTerraformNocodeModuleToJSON, } from './';
export function HashicorpCloudWaypointApplicationTemplateFromJSON(json) {
    return HashicorpCloudWaypointApplicationTemplateFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointApplicationTemplateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'readmeMarkdownTemplate': !exists(json, 'readme_markdown_template') ? undefined : json['readme_markdown_template'],
        'readmeTemplate': !exists(json, 'readme_template') ? undefined : json['readme_template'],
        'terraformNocodeModule': !exists(json, 'terraform_nocode_module') ? undefined : HashicorpCloudWaypointTerraformNocodeModuleFromJSON(json['terraform_nocode_module']),
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'terraformCloudWorkspaceDetails': !exists(json, 'terraform_cloud_workspace_details') ? undefined : HashicorpCloudWaypointTerraformCloudWorkspaceDetailsFromJSON(json['terraform_cloud_workspace_details']),
        'tags': !exists(json, 'tags') ? undefined : (json['tags'].map(HashicorpCloudWaypointTagFromJSON)),
        'actionCfgRefs': !exists(json, 'action_cfg_refs') ? undefined : (json['action_cfg_refs'].map(HashicorpCloudWaypointActionCfgRefFromJSON)),
        'variableOptions': !exists(json, 'variable_options') ? undefined : (json['variable_options'].map(HashicorpCloudWaypointTFModuleVariableFromJSON)),
        'variableOptionsOutOfSync': !exists(json, 'variable_options_out_of_sync') ? undefined : json['variable_options_out_of_sync'],
        'moduleSource': !exists(json, 'module_source') ? undefined : json['module_source'],
    };
}
export function HashicorpCloudWaypointApplicationTemplateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'summary': value.summary,
        'description': value.description,
        'readme_markdown_template': value.readmeMarkdownTemplate,
        'readme_template': value.readmeTemplate,
        'terraform_nocode_module': HashicorpCloudWaypointTerraformNocodeModuleToJSON(value.terraformNocodeModule),
        'labels': value.labels,
        'terraform_cloud_workspace_details': HashicorpCloudWaypointTerraformCloudWorkspaceDetailsToJSON(value.terraformCloudWorkspaceDetails),
        'tags': value.tags === undefined ? undefined : (value.tags.map(HashicorpCloudWaypointTagToJSON)),
        'action_cfg_refs': value.actionCfgRefs === undefined ? undefined : (value.actionCfgRefs.map(HashicorpCloudWaypointActionCfgRefToJSON)),
        'variable_options': value.variableOptions === undefined ? undefined : (value.variableOptions.map(HashicorpCloudWaypointTFModuleVariableToJSON)),
        'module_source': value.moduleSource,
    };
}
