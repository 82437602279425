import userScenario from './user';
import { faker } from '@faker-js/faker';

let options = {
  createBilling: true,
  samlSsoConfigured: true,
  createGroups: true,
};

export default function (server) {
  userScenario(server, options);

  const org = server.schema.findBy('resource-manager.organization', {
    name: 'default-org',
  });

  // fetch user
  let adminUser = server.schema.findBy('iam.user-principal', {
    current_user: true,
  });

  adminUser.update({
    current_user: false,
  });

  // Create a new user with no org role and set them as the current user
  const currentUser = server.create('iam.user-principal', {
    current_user: true,
    email: 'noRoleCurrentUser@gmail.com',
    full_name: 'Jim No Role',
    id: '1234f5e5-8o88-4275-85fb-b8cb856717f9',
  });

  server.create('iam.organization-membership', {
    organization: org,
    principal: currentUser,
  });

  // import userPrincipalFive
  const userPrincipalFive = server.schema.findBy('iam.user-principal', {
    email: 'sso_user@sso.com',
  });

  // group name
  let groupResourceName = `iam/organization/${org.id}/group/group-with-members-project-admin`;

  // import group
  const groupWithManagers = server.schema.findBy('iam.group', {
    resource_name: groupResourceName,
  });

  // add additional users to group
  let currentMembers = groupWithManagers.member_principal_ids;

  groupWithManagers.update({
    member_principal_ids: [
      ...currentMembers,
      currentUser.id,
      userPrincipalFive.id,
    ],
    display_name: 'Group Managed by Current User',
  });

  //update group policy to include user and userPrincipalFive as managers

  // policy to be set
  const resourcePolicy = {
    bindings: [
      {
        role_id: 'roles/iam.group-manager',
        // assign a group manager on group
        members: [
          {
            memberType: 'USER',
            // org contributor, now receiving manager privileges on the group
            memberId: userPrincipalFive.id,
          },
          {
            memberType: 'USER',
            // current user, no org role, now receiving manager privileges on the group
            memberId: currentUser.id,
          },
        ],
      },
    ],
    etag: faker.string.alphanumeric(51),
  };
  // Group IAM policy - only adds iam.user-principals to the group resource policy after some action to assign roles in the UI
  server.create('resource-manager.resource.policy', {
    $resource_name: groupResourceName,
    policy: resourcePolicy,
  });
  // for new group managers, grant group manager scoped permissions
  server.create('resource-manager.resource.allowed-permission', {
    $resource_name: groupResourceName,
    $principal_id: userPrincipalFive.id,
    allowed_permissions: [
      'iam.groups.update',
      'iam.groups.update-members',
      'iam.groups.list-members',
    ],
  });
  server.create('resource-manager.resource.allowed-permission', {
    $resource_name: groupResourceName,
    $principal_id: currentUser.id,
    allowed_permissions: [
      'iam.groups.update',
      'iam.groups.update-members',
      'iam.groups.list-members',
    ],
  });
}
