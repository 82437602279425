import billingConsumption from './billing-flex';

export default function (server, options = {}) {
  // Set up billing scenario.
  billingConsumption(server, {
    createNetwork: true,
    createConsul: true,
    // don't create vault stuff because mirage will freak out
    vaultPackages: false,
    createVault: false,
    ...options,
  });
}
