import {
  ACTION_CREATE,
  ACTION_DELETE,
  ACTION_UPDATE,
  ACTION_GET,
  ACTION_LIST,
  ACTION_READ,
  ACTION_RUN,
} from '../permission-types/base';

// Prefixes
export const WAYPOINT_PROJECTS = 'waypoint.projects';
export const WAYPOINT_TEMPLATES = 'waypoint.templates';
export const WAYPOINT_CONFIGS = 'waypoint.configs';
export const WAYPOINT_CONFIG_SOURCERS = 'waypoint.configsourcers';
export const WAYPOINT_NAMESPACES = 'waypoint.namespaces';
export const WAYPOINT_RUNNERS = 'waypoint.runners';
export const WAYPOINT_INPUT_VARIABLES = 'waypoint.projectinputvariables';
export const WAYPOINT_PIPELINES = 'waypoint.pipelines';
export const WAYPOINT_PROJECT_FROM_TEMPLATES = 'waypoint.projectfromtemplates';
export const WAYPOINT_ADDON_DEFINITIONS = 'waypoint.addondefinitions';
export const WAYPOINT_ADDONS = 'waypoint.addons';
export const WAYPOINT_APPLICATIONS = 'waypoint.applications';
export const WAYPOINT_ACTIONS = 'waypoint.action';
export const WAYPOINT_TFCCONFIG = 'waypoint.tfcconfig';

// Namespaces permissions
export const WAYPOINT_NAMESPACES_GET = `${WAYPOINT_NAMESPACES}.${ACTION_GET}`;
export const WAYPOINT_NAMESPACES_LIST = `${WAYPOINT_NAMESPACES}.${ACTION_LIST}`;
export const WAYPOINT_NAMESPACES_UPDATE = `${WAYPOINT_NAMESPACES}.${ACTION_UPDATE}`;
export const WAYPOINT_NAMESPACES_CREATE = `${WAYPOINT_NAMESPACES}.${ACTION_CREATE}`;
export const WAYPOINT_NAMESPACES_DELETE = `${WAYPOINT_NAMESPACES}.${ACTION_DELETE}`;

// Projects permissions
export const WAYPOINT_PROJECTS_READ = `${WAYPOINT_PROJECTS}.${ACTION_READ}`;
export const WAYPOINT_PROJECTS_UPDATE = `${WAYPOINT_PROJECTS}.${ACTION_UPDATE}`;
export const WAYPOINT_PROJECTS_CREATE = `${WAYPOINT_PROJECTS}.${ACTION_CREATE}`;
export const WAYPOINT_PROJECTS_DELETE = `${WAYPOINT_PROJECTS}.${ACTION_DELETE}`;

// Templates permissions
export const WAYPOINT_TEMPLATES_READ = `${WAYPOINT_TEMPLATES}.${ACTION_READ}`;
export const WAYPOINT_TEMPLATES_UPDATE = `${WAYPOINT_TEMPLATES}.${ACTION_UPDATE}`;
export const WAYPOINT_TEMPLATES_CREATE = `${WAYPOINT_TEMPLATES}.${ACTION_CREATE}`;
export const WAYPOINT_TEMPLATES_DELETE = `${WAYPOINT_TEMPLATES}.${ACTION_DELETE}`;

// Configs permissions
export const WAYPOINT_CONFIGS_READ = `${WAYPOINT_CONFIGS}.${ACTION_READ}`;
export const WAYPOINT_CONFIGS_UPDATE = `${WAYPOINT_CONFIGS}.${ACTION_UPDATE}`;
export const WAYPOINT_CONFIGS_CREATE = `${WAYPOINT_CONFIGS}.${ACTION_CREATE}`;
export const WAYPOINT_CONFIGS_DELETE = `${WAYPOINT_CONFIGS}.${ACTION_DELETE}`;

// Config sourcers permissions
export const WAYPOINT_CONFIG_SOURCERS_READ = `${WAYPOINT_CONFIG_SOURCERS}.${ACTION_READ}`;
export const WAYPOINT_CONFIG_SOURCERS_UPDATE = `${WAYPOINT_CONFIG_SOURCERS}.${ACTION_UPDATE}`;
export const WAYPOINT_CONFIG_SOURCERS_CREATE = `${WAYPOINT_CONFIG_SOURCERS}.${ACTION_CREATE}`;
export const WAYPOINT_CONFIG_SOURCERS_DELETE = `${WAYPOINT_CONFIG_SOURCERS}.${ACTION_DELETE}`;

// Runners permissions
export const WAYPOINT_RUNNERS_READ = `${WAYPOINT_RUNNERS}.${ACTION_READ}`;
export const WAYPOINT_RUNNERS_UPDATE = `${WAYPOINT_RUNNERS}.${ACTION_UPDATE}`;
export const WAYPOINT_RUNNERS_CREATE = `${WAYPOINT_RUNNERS}.${ACTION_CREATE}`;
export const WAYPOINT_RUNNERS_DELETE = `${WAYPOINT_RUNNERS}.${ACTION_DELETE}`;

// Input variables permissions
export const WAYPOINT_INPUT_VARIABLES_READ = `${WAYPOINT_INPUT_VARIABLES}.${ACTION_READ}`;
export const WAYPOINT_INPUT_VARIABLES_UPDATE = `${WAYPOINT_INPUT_VARIABLES}.${ACTION_UPDATE}`;
export const WAYPOINT_INPUT_VARIABLES_CREATE = `${WAYPOINT_INPUT_VARIABLES}.${ACTION_CREATE}`;
export const WAYPOINT_INPUT_VARIABLES_DELETE = `${WAYPOINT_INPUT_VARIABLES}.${ACTION_DELETE}`;

// Pipelines permissions
export const WAYPOINT_PIPELINES_READ = `${WAYPOINT_PIPELINES}.${ACTION_READ}`;
export const WAYPOINT_PIPELINES_UPDATE = `${WAYPOINT_PIPELINES}.${ACTION_UPDATE}`;
export const WAYPOINT_PIPELINES_CREATE = `${WAYPOINT_PIPELINES}.${ACTION_CREATE}`;
export const WAYPOINT_PIPELINES_DELETE = `${WAYPOINT_PIPELINES}.${ACTION_DELETE}`;

// Project from templates permmissions
export const WAYPOINT_PROJECT_FROM_TEMPLATES_CREATE = `${WAYPOINT_PROJECT_FROM_TEMPLATES}.${ACTION_CREATE}`;

// Addon definitions permissions
export const WAYPOINT_ADDON_DEFINITIONS_READ = `${WAYPOINT_ADDON_DEFINITIONS}.${ACTION_READ}`;
export const WAYPOINT_ADDON_DEFINITIONS_UPDATE = `${WAYPOINT_ADDON_DEFINITIONS}.${ACTION_UPDATE}`;
export const WAYPOINT_ADDON_DEFINITIONS_CREATE = `${WAYPOINT_ADDON_DEFINITIONS}.${ACTION_CREATE}`;
export const WAYPOINT_ADDON_DEFINITIONS_DELETE = `${WAYPOINT_ADDON_DEFINITIONS}.${ACTION_DELETE}`;

// Addon definitions permissions
export const WAYPOINT_ADDONS_READ = `${WAYPOINT_ADDONS}.${ACTION_READ}`;
export const WAYPOINT_ADDONS_UPDATE = `${WAYPOINT_ADDONS}.${ACTION_UPDATE}`;
export const WAYPOINT_ADDONS_CREATE = `${WAYPOINT_ADDONS}.${ACTION_CREATE}`;
export const WAYPOINT_ADDONS_DELETE = `${WAYPOINT_ADDONS}.${ACTION_DELETE}`;

// Application permissions
export const WAYPOINT_APPLICATIONS_READ = `${WAYPOINT_APPLICATIONS}.${ACTION_READ}`;
export const WAYPOINT_APPLICATIONS_UPDATE = `${WAYPOINT_APPLICATIONS}.${ACTION_UPDATE}`;
export const WAYPOINT_APPLICATIONS_CREATE = `${WAYPOINT_APPLICATIONS}.${ACTION_CREATE}`;
export const WAYPOINT_APPLICATIONS_DELETE = `${WAYPOINT_APPLICATIONS}.${ACTION_DELETE}`;

// Action permissions
export const WAYPOINT_ACTIONS_READ = `${WAYPOINT_ACTIONS}.${ACTION_READ}`;
export const WAYPOINT_ACTIONS_UPDATE = `${WAYPOINT_ACTIONS}.${ACTION_UPDATE}`;
export const WAYPOINT_ACTIONS_CREATE = `${WAYPOINT_ACTIONS}.${ACTION_CREATE}`;
export const WAYPOINT_ACTIONS_DELETE = `${WAYPOINT_ACTIONS}.${ACTION_DELETE}`;
export const WAYPOINT_ACTIONS_RUN = `${WAYPOINT_ACTIONS}.${ACTION_RUN}`;

// TFC Config permissions
export const WAYPOINT_TFCCONFIG_READ = `${WAYPOINT_TFCCONFIG}.${ACTION_READ}`;
export const WAYPOINT_TFCCONFIG_UPDATE = `${WAYPOINT_TFCCONFIG}.${ACTION_UPDATE}`;
export const WAYPOINT_TFCCONFIG_CREATE = `${WAYPOINT_TFCCONFIG}.${ACTION_CREATE}`;
export const WAYPOINT_TFCCONFIG_DELETE = `${WAYPOINT_TFCCONFIG}.${ACTION_DELETE}`;

export const WAYPOINT_VIEWER_ROLE_PERMISSIONS = [
  WAYPOINT_NAMESPACES_GET,
  WAYPOINT_NAMESPACES_LIST,
  WAYPOINT_CONFIGS_READ,
  WAYPOINT_CONFIG_SOURCERS_READ,
  WAYPOINT_PROJECTS_READ,
  WAYPOINT_INPUT_VARIABLES_READ,
  WAYPOINT_RUNNERS_READ,
  WAYPOINT_TEMPLATES_READ,
  WAYPOINT_PIPELINES_READ,
  WAYPOINT_ADDON_DEFINITIONS_READ,
  WAYPOINT_ADDONS_READ,
  WAYPOINT_APPLICATIONS_READ,
  WAYPOINT_ACTIONS_READ,
  WAYPOINT_ACTIONS_RUN,
  WAYPOINT_TFCCONFIG_READ,
];

export const WAYPOINT_CONTRIBUTOR_ROLE_PERMISSIONS = [
  ...WAYPOINT_VIEWER_ROLE_PERMISSIONS,
  WAYPOINT_NAMESPACES_CREATE,
  WAYPOINT_NAMESPACES_DELETE,
  WAYPOINT_NAMESPACES_UPDATE,
  WAYPOINT_CONFIGS_CREATE,
  WAYPOINT_CONFIGS_UPDATE,
  WAYPOINT_CONFIG_SOURCERS_CREATE,
  WAYPOINT_CONFIG_SOURCERS_UPDATE,
  WAYPOINT_PROJECTS_UPDATE,
  WAYPOINT_PROJECTS_DELETE,
  WAYPOINT_INPUT_VARIABLES_UPDATE,
  WAYPOINT_INPUT_VARIABLES_DELETE,
  WAYPOINT_PROJECT_FROM_TEMPLATES_CREATE,
  WAYPOINT_RUNNERS_CREATE,
  WAYPOINT_RUNNERS_UPDATE,
  WAYPOINT_PIPELINES_CREATE,
  WAYPOINT_PIPELINES_UPDATE,
  WAYPOINT_PIPELINES_DELETE,
  WAYPOINT_ADDONS_CREATE,
  WAYPOINT_ADDONS_UPDATE,
  WAYPOINT_ADDONS_DELETE,
  WAYPOINT_APPLICATIONS_CREATE,
  WAYPOINT_APPLICATIONS_UPDATE,
  WAYPOINT_APPLICATIONS_DELETE,
  WAYPOINT_ACTIONS_CREATE,
  WAYPOINT_ACTIONS_UPDATE,
  WAYPOINT_ACTIONS_DELETE,
];

// Roles
export const WAYPOINT_ADMIN_ROLE_PERMISSIONS = [
  ...WAYPOINT_CONTRIBUTOR_ROLE_PERMISSIONS,
  WAYPOINT_CONFIGS_DELETE,
  WAYPOINT_CONFIG_SOURCERS_DELETE,
  WAYPOINT_PROJECTS_CREATE,
  WAYPOINT_INPUT_VARIABLES_CREATE,
  WAYPOINT_RUNNERS_DELETE,
  WAYPOINT_TEMPLATES_CREATE,
  WAYPOINT_TEMPLATES_UPDATE,
  WAYPOINT_TEMPLATES_DELETE,
  WAYPOINT_TFCCONFIG_CREATE,
  WAYPOINT_TFCCONFIG_UPDATE,
  WAYPOINT_TFCCONFIG_DELETE,
  WAYPOINT_ADDON_DEFINITIONS_CREATE,
  WAYPOINT_ADDON_DEFINITIONS_UPDATE,
  WAYPOINT_ADDON_DEFINITIONS_DELETE,
];

export const WAYPOINT_ALL_PERMISSIONS = [...WAYPOINT_ADMIN_ROLE_PERMISSIONS];
