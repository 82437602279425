import { faker } from '@faker-js/faker';
import consulGnmScenario from './consulGnm';
import { findLocationIdsForProject } from '../helpers/multi-network';
import {
  HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus,
  HashicorpCloudGlobalNetworkManager20220215PeeringIneligibilityReason,
  HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOption,
  HashicorpCloudGlobalNetworkManager20220215PartitionPeeringIneligibilityReason,
} from '@clients/cloud-global-network-manager-service';

export default function (server, options = {}) {
  // Set up user scenario.
  consulGnmScenario(server, options);

  let currentUser = server.schema.findBy('iam.user-principal', {
    current_user: true,
  });
  let org = server.schema.findBy('resource-manager.organization', {
    ownerId: currentUser.id,
  });
  let project = server.schema.findBy('resource-manager.project', {
    parent: {
      type: 'ORGANIZATION',
      id: org.id,
    },
  });
  let locationIds = findLocationIdsForProject(server.schema, {
    organizationId: org.id,
    projectId: project.id,
  });
  let clusters = server.schema.where(
    'consul-global-network-manager.cluster',
    (cluster) => locationIds.includes(cluster.locationId)
  );

  let [source_cluster, target_cluster, target_cluster_2] = clusters.models;
  let sourceClusterLocation = server.schema.findBy('location.location', {
    organizationId: source_cluster.location.organizationId,
    projectId: source_cluster.location.projectId,
    regionId: source_cluster.location.regionId,
  });

  // Create a peering for each peer status
  Object.values(
    HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus
  ).forEach((peerConnectionStatus) => {
    server.create('consul-global-network-manager.cluster.peer', {
      source_cluster,
      source_partition: `${source_cluster.id}-ap`,
      target_cluster,
      target_partition: `${target_cluster.id}-ap`,
      peer_name: `${source_cluster.id}-${
        target_cluster.id
      }-${peerConnectionStatus.toLowerCase()}`,
      peering_connection_status: peerConnectionStatus,
      location: sourceClusterLocation,
    });
  });

  server.create('consul-global-network-manager.cluster.peer', {
    source_cluster,
    // Intentionally use same source partition as first loop above
    source_partition: `${source_cluster.id}-ap-0`,
    target_cluster: target_cluster_2,
    target_partition: `${target_cluster_2.id}-ap`,
    peer_name: `${source_cluster.id}-${target_cluster_2.id}`,
    peering_connection_status:
      HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus.ACTIVE,
    location: sourceClusterLocation,
  });

  let firstSelfManagedCluster = server.schema.first(
    'consul-global-network-manager.cluster'
  );
  let loc = server.schema.findBy('location.location', {
    id: firstSelfManagedCluster.locationId,
  });
  server.create('consul-global-network-manager.cluster', {
    id: 'sm-peering-eligible',
    name: 'sm-peering-eligible',
    consul_version: 'v1.14.0',
    location: loc,
    admin_partitions: ['default', 'ap1', 'ap2'],
    admin_partitions_as_dialer: [
      { name: 'eligiblepartition1' },
      {
        name: 'ineligiblepartition',
        ineligibilityReasonCodes: [
          HashicorpCloudGlobalNetworkManager20220215PartitionPeeringIneligibilityReason.PEERINGALREADYEXISTS,
        ],
      },
    ],
    dialer_connectivity_options: [
      HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOption.MESHGATEWAY,
    ],
  });
  server.create('consul-global-network-manager.cluster', {
    id: 'sm-peering-ineligible',
    name: 'sm-peering-ineligible',
    consul_version: 'v1.13.0',
    location: loc,
    peering_acceptor_ineligibility_codes: [
      HashicorpCloudGlobalNetworkManager20220215PeeringIneligibilityReason.MINVERSIONNOTMET,
    ],
    peering_dialer_ineligibility_codes: [
      HashicorpCloudGlobalNetworkManager20220215PeeringIneligibilityReason.MINVERSIONNOTMET,
    ],
    admin_partitions_as_dialer: [
      { name: 'cluster2partition1' },
      { name: 'cluster2partition2' },
    ],
  });
  server.create('consul-global-network-manager.cluster', {
    id: 'sm-peering-ineligible-billing',
    name: 'sm-peering-ineligible-billing',
    consul_version: 'v1.13.0',
    location: loc,
    peering_acceptor_ineligibility_codes: [
      HashicorpCloudGlobalNetworkManager20220215PeeringIneligibilityReason.INELIGIBLEBILLINGPLAN,
    ],
    peering_dialer_ineligibility_codes: [
      HashicorpCloudGlobalNetworkManager20220215PeeringIneligibilityReason.INELIGIBLEBILLINGPLAN,
    ],
    admin_partitions_as_dialer: [
      { name: 'cluster2partition1' },
      { name: 'cluster2partition2' },
    ],
  });
  server.create('consul-global-network-manager.cluster', {
    id: 'sm-peering-not-enabled',
    name: 'sm-peering-not-enabled',
    consul_version: 'v1.13.0',
    location: loc,
    peering_acceptor_ineligibility_codes: [
      HashicorpCloudGlobalNetworkManager20220215PeeringIneligibilityReason.PEERINGNOTENABLED,
    ],
    peering_dialer_ineligibility_codes: [
      HashicorpCloudGlobalNetworkManager20220215PeeringIneligibilityReason.PEERINGNOTENABLED,
    ],
    admin_partitions_as_dialer: [
      { name: 'cluster2partition1' },
      { name: 'cluster2partition2' },
    ],
  });
  server.create('consul-global-network-manager.cluster', {
    id: 'sm-peering-eligible-no-dialers',
    name: 'sm-peering-eligible-no-dialers',
    consul_version: 'v1.14.0',
    location: loc,
    admin_partitions: ['default', 'ap1'],
    admin_partitions_as_dialer: [
      { name: 'cluster3partition1' },
      { name: 'cluster3partition2' },
    ],
  });
  server.create('consul-global-network-manager.cluster', {
    id: 'sm-peering-eligible-dialer-error',
    name: 'sm-peering-eligible-dialer-error',
    consul_version: 'v1.14.0',
    location: loc,
    admin_partitions_as_dialer: [
      { name: 'cluster1partition1' },
      { name: 'cluster1partition2' },
    ],
  });

  let hcpClusters = server.schema.where('consul.cluster', (cluster) =>
    locationIds.includes(cluster.locationId)
  ).models;
  let firstHcpCluster = hcpClusters[0];
  let hcpLoc = server.schema.findBy('location.location', {
    organizationId: firstHcpCluster.location.organizationId,
    projectId: firstHcpCluster.location.projectId,
    regionId: firstHcpCluster.location.regionId,
  });
  let networkId = firstHcpCluster.config.network_config.network.id;
  let networkLink = server.schema.findBy('location.link', { id: networkId });
  server.create('consul.cluster', {
    id: 'hcp-peering-eligible-public',
    consul_version: 'v1.14.0',
    created_at: '2020-10-14T02:16:17.187Z',
    resourceId: faker.string.uuid(),
    config: {
      capacity_config: {
        size: 'MEDIUM',
      },
      network_config: {
        network: networkLink,
        private: false,
        ip_allowlist: [],
      },
      tier: 'STANDARD',
      primary: null,
    },
    dns_names: {
      public: `test-cluster.public.consul.${networkId}.aws.hcp.dev`,
      private: `test-cluster.private.consul.${networkId}.aws.hcp.dev`,
    },
    location: hcpLoc,
    admin_partitions: ['default', 'ap1', 'ap2', 'ap3'],
    admin_partitions_as_dialer: [{ name: 'cluster4partition1' }],
    dialer_connectivity_options: [
      HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOption.HVNTOHVNPEERING,
    ],
  });
  server.create('consul.cluster', {
    id: 'hcp-peering-eligible-private',
    consul_version: 'v1.14.0',
    created_at: '2020-10-14T02:16:17.187Z',
    resourceId: faker.string.uuid(),
    config: {
      capacity_config: {
        size: 'MEDIUM',
      },
      network_config: {
        network: networkLink,
        private: true,
        ip_allowlist: [],
      },
      tier: 'STANDARD',
      primary: null,
    },
    dns_names: {
      private: `test-cluster.private.consul.${networkId}.aws.hcp.dev`,
    },
    location: hcpLoc,
    admin_partitions_as_dialer: [
      { name: 'hvnhvnpeeringpartition1' },
      {
        name: 'hvnhvnpeeringpartition2',
        ineligibilityReasonCodes: [
          HashicorpCloudGlobalNetworkManager20220215PartitionPeeringIneligibilityReason.PEERINGALREADYEXISTS,
        ],
      },
    ],
    dialer_connectivity_options: [
      HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOption.HVNTOHVNPEERING,
    ],
  });
  server.create('consul.cluster', {
    id: 'hcp-peering-ineligible',
    consul_version: 'v1.12.0',
    created_at: '2020-10-14T02:16:17.187Z',
    resourceId: faker.string.uuid(),
    config: {
      capacity_config: {
        size: 'MEDIUM',
      },
      network_config: {
        network: networkLink,
        private: false,
        ip_allowlist: [],
      },
      tier: 'STANDARD',
      primary: null,
    },
    dns_names: {
      public: `test-cluster.public.consul.${networkId}.aws.hcp.dev`,
      private: `test-cluster.private.consul.${networkId}.aws.hcp.dev`,
    },
    location: hcpLoc,
    peering_acceptor_ineligibility_codes: [
      HashicorpCloudGlobalNetworkManager20220215PeeringIneligibilityReason.MINVERSIONNOTMET,
    ],
    peering_dialer_ineligibility_codes: [
      HashicorpCloudGlobalNetworkManager20220215PeeringIneligibilityReason.MINVERSIONNOTMET,
    ],
    admin_partitions_as_dialer: [
      { name: 'cluster3partition1' },
      { name: 'cluster3partition2' },
      { name: 'cluster3partition3' },
    ],
  });

  // Add upgradable versions
  server.create('consul.upgrade-version', {
    version: 'v1.10.3',
    status: 'AVAILABLE',
  });

  server.create('consul.upgrade-version', {
    version: 'v1.10.2',
    status: 'AVAILABLE',
  });

  server.create('consul.upgrade-version', {
    version: 'v1.10.1',
    status: 'AVAILABLE',
  });

  // Federated clusters
  server.create('consul.cluster', {
    id: 'hcp-peering-eligible-federated-primary',
    consul_version: 'v1.14.0',
    created_at: '2020-10-14T02:16:17.187Z',
    resourceId: faker.string.uuid(),
    config: {
      capacity_config: {
        size: 'MEDIUM',
      },
      network_config: {
        network: networkLink,
        private: false,
      },
      tier: 'STANDARD',
      primary: {
        id: 'hcp-peering-eligible-federated-primary',
        location: hcpLoc,
      },
    },
    dns_names: {
      public: `test-cluster.public.consul.${networkId}.aws.hcp.dev`,
      private: `test-cluster.private.consul.${networkId}.aws.hcp.dev`,
    },
    location: hcpLoc,
    admin_partitions: ['default', 'ap1', 'ap2', 'ap3'],
    admin_partitions_as_dialer: [{ name: 'cluster4partition1' }],
    dialer_connectivity_options: [
      HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOption.HVNTOHVNPEERING,
    ],
  });
  server.create('consul.cluster', {
    id: 'hcp-peering-eligible-federated-secondary',
    consul_version: 'v1.14.0',
    created_at: '2020-10-14T02:16:17.187Z',
    resourceId: faker.string.uuid(),
    config: {
      capacity_config: {
        size: 'MEDIUM',
      },
      network_config: {
        network: networkLink,
        private: false,
      },
      tier: 'STANDARD',
      primary: {
        id: 'hcp-peering-eligible-federated-primary',
        location: hcpLoc,
      },
    },
    dns_names: {
      public: `test-cluster.public.consul.${networkId}.aws.hcp.dev`,
      private: `test-cluster.private.consul.${networkId}.aws.hcp.dev`,
    },
    location: hcpLoc,
    admin_partitions: ['default', 'ap1', 'ap2', 'ap3'],
    admin_partitions_as_dialer: [{ name: 'cluster4partition1' }],
    dialer_connectivity_options: [
      HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOption.HVNTOHVNPEERING,
    ],
  });
}
