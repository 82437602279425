import userScenario from './user';

export default function (server) {
  userScenario(server, {
    hasVerifiedEmail: true,
  });

  // const organization = server.schema.findBy('resource-manager.organization', {
  //   name: 'Default Org',
  // });
  //
  // const project = server.schema.findBy('resource-manager.project', {
  //   parent: { type: 'ORGANIZATION', id: organization.id },
  // });

  // const cluster =
  let { schema } = server;
  const location = schema.first('location.location');
  server.create('boundary.cluster', { location });
}
