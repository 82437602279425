export default [
  {
    id: 'roles/owner',
    version: 0,
    title: 'Owner',
    description:
      'Has all of the admin’s permissions, and also the ability to delete the organization and promote/demote other owners.',
    permissions: [
      'billing.accounts.assign-project',
      'billing.accounts.create',
      'billing.accounts.delete',
      'billing.accounts.get',
      'billing.accounts.list',
      'billing.accounts.tokenize-payment-details',
      'billing.accounts.unassign-project',
      'billing.accounts.update',
      'billing.fcp.get-consumption-pool',
      'billing.fcp.list-consumption-pools',
      'billing.statements.get-statement',
      'billing.statements.list',
      'boundary.clusters.create',
      'boundary.clusters.delete',
      'boundary.clusters.get',
      'boundary.clusters.get-update-deadline',
      'boundary.clusters.get-update-deadline-internal',
      'boundary.clusters.list',
      'boundary.clusters.maintenance-window-get',
      'boundary.clusters.update',
      'boundary.clusters.update-maintenance-window',
      'boundary.clusters.update-version',
      'boundary.sessions.list',
      'consul.clusters.create',
      'consul.clusters.create-root-token',
      'consul.clusters.delete',
      'consul.clusters.federate',
      'consul.clusters.get',
      'consul.clusters.get-client-config',
      'consul.clusters.get-telemetry',
      'consul.clusters.list',
      'consul.clusters.list-versions',
      'consul.clusters.update',
      'consul.snapshots.create',
      'consul.snapshots.delete',
      'consul.snapshots.get',
      'consul.snapshots.list',
      'consul.snapshots.update',
      'consul-telemetry-gateway.metrics.query',
      'consul-telemetry-gateway.service-topology.get',
      'consul-telemetry-gateway.user-is.admin',
      'consul-telemetry-gateway.user-is.contributor',
      'global-network-manager.consul-clusters.create',
      'global-network-manager.consul-clusters.delete',
      'global-network-manager.consul-clusters.get',
      'global-network-manager.consul-clusters.get-api-information',
      'global-network-manager.consul-clusters.get-ca-config',
      'global-network-manager.consul-clusters.get-management-token-details',
      'global-network-manager.consul-clusters.get-secrets',
      'global-network-manager.consul-clusters.list',
      'global-network-manager.consul-clusters.list-partitions',
      'global-network-manager.consul-clusters.list-service-instances',
      'global-network-manager.consul-clusters.proxy-access',
      'global-network-manager.consul-clusters.ui',
      'global-network-manager.consul-clusters.update',
      'global-network-manager.consul-clusters-servers.list',
      'global-network-manager.consul-observability.get-credentials',
      'global-network-manager.intentions.list',
      'global-network-manager.nodes.get',
      'global-network-manager.nodes.list',
      'global-network-manager.organizations.get-trial',
      'global-network-manager.peering-connections.create',
      'global-network-manager.peering-connections.delete',
      'global-network-manager.peering-connections.get',
      'global-network-manager.peering-connections.list',
      'global-network-manager.peering-connections.list-clusters-eligibility',
      'global-network-manager.peering-connections.list-peers-by-cluster-partition',
      'global-network-manager.service-catalog.summary',
      'global-network-manager.services.get',
      'global-network-manager.services.list',
      'global-network-manager.user-is.admin',
      'global-network-manager.user-is.contributor',
      'iam.auth-connection.create',
      'iam.auth-connection.delete',
      'iam.auth-connection.get',
      'iam.auth-connection.update',
      'iam.auth-metadata.get',
      'iam.domain-ownership-records.get',
      'iam.groups.count',
      'iam.groups.create',
      'iam.groups.delete',
      'iam.groups.get',
      'iam.groups.get-iam-policy',
      'iam.groups.list',
      'iam.groups.list-members',
      'iam.groups.member-count',
      'iam.groups.set-iam-policy',
      'iam.groups.update',
      'iam.groups.update-members',
      'iam.invitations.create',
      'iam.invitations.delete',
      'iam.invitations.get',
      'iam.invitations.list',
      'iam.invitations.update',
      'iam.principal.groups-count',
      'iam.principals.list',
      'iam.service-principals.create',
      'iam.service-principals.create-token',
      'iam.service-principals.delete',
      'iam.service-principals.delete-token',
      'iam.service-principals.get',
      'iam.service-principals.list',
      'iam.service-principals.list-token',
      'iam.service-principals.update',
      'iam.sso-configurations.create',
      'iam.sso-configurations.delete',
      'iam.sso-configurations.get',
      'iam.sso-configurations.list',
      'iam.sso-configurations.update',
      'iam.sso-type.get',
      'iam.users.delete',
      'iam.users.get',
      'iam.users.list',
      'iam.workload-identity-providers.create',
      'iam.workload-identity-providers.delete',
      'iam.workload-identity-providers.get',
      'iam.workload-identity-providers.list',
      'iam.workload-identity-providers.update',
      'idp.scim.create-token',
      'idp.scim.delete-token',
      'idp.scim.disable',
      'idp.scim.enable',
      'idp.scim.get',
      'idp.scim.list-tokens',
      'link.nodes-status.get',
      'log.archive.create',
      'log.archive.get',
      'log.archive.list',
      'log.entries.get',
      'log.entries.store',
      'log.streaming.create',
      'log.streaming.delete',
      'log.streaming.get',
      'log.streaming.list',
      'log.streaming.test',
      'log.streaming.update',
      'network.dependencies.list',
      'network.hvns.create',
      'network.hvns.delete',
      'network.hvns.get',
      'network.hvns.list',
      'network.hvns.update',
      'network.peerings.create',
      'network.peerings.delete',
      'network.peerings.get',
      'network.peerings.list',
      'network.routes.create',
      'network.routes.delete',
      'network.routes.get',
      'network.routes.list',
      'network.tgw-attachments.create',
      'network.tgw-attachments.delete',
      'network.tgw-attachments.get',
      'network.tgw-attachments.list',
      'operation.operations.get',
      'operation.operations.list',
      'packer.bucket.contributors-list',
      'packer.bucket.create',
      'packer.bucket.delete',
      'packer.bucket.get',
      'packer.bucket.list',
      'packer.bucket.update',
      'packer.buckets.get-iam-policy',
      'packer.buckets.set-iam-policy',
      'packer.channels.contributors-get',
      'packer.channels.contributors-list',
      'packer.channels.create',
      'packer.channels.delete',
      'packer.channels.get',
      'packer.channels.list',
      'packer.channels.update',
      'packer.images.contributors-list',
      'packer.images.create',
      'packer.images.delete',
      'packer.images.get',
      'packer.images.list',
      'packer.images.update',
      'packer.iterations.create',
      'packer.iterations.delete',
      'packer.iterations.get',
      'packer.iterations.list',
      'packer.iterations.update',
      'packer.registries.create',
      'packer.registries.delete',
      'packer.registries.get',
      'packer.registries.update',
      'packer.versions.create',
      'packer.versions.delete',
      'packer.versions.get',
      'packer.versions.list',
      'packer.versions.update',
      'resource-manager.organizations.get-iam-policy',
      'resource-manager.organizations.get-role',
      'resource-manager.organizations.list-roles',
      'resource-manager.organizations.set-iam-policy',
      'resource-manager.organizations.set-name',
      'resource-manager.projects.create',
      'resource-manager.projects.delete',
      'resource-manager.projects.get',
      'resource-manager.projects.get-iam-policy',
      'resource-manager.projects.list',
      'resource-manager.projects.set-iam-policy',
      'resource-manager.projects.set-system-managed-iam-policy',
      'resource-manager.projects.update',
      'resource-manager.resources.list',
      'resource-manager.resources.list-roles',
      'secrets.app-secret-versions.list',
      'secrets.app-secret-versions.open',
      'secrets.app-secrets.create',
      'secrets.app-secrets.delete',
      'secrets.app-secrets.get',
      'secrets.app-secrets.list',
      'secrets.app-secrets.open',
      'secrets.app-secrets.rotate',
      'secrets.app-secrets.update',
      'secrets.app-secrets-state.get',
      'secrets.apps.create',
      'secrets.apps.delete',
      'secrets.apps.get',
      'secrets.apps.get-iam-policy',
      'secrets.apps.list',
      'secrets.apps.set-iam-policy',
      'secrets.apps.update',
      'secrets.billing.update',
      'secrets.gateway-pools.create',
      'secrets.gateway-pools.delete',
      'secrets.gateway-pools.get',
      'secrets.gateway-pools.get-iam-policy',
      'secrets.gateway-pools.list',
      'secrets.gateway-pools.set-iam-policy',
      'secrets.gateway-pools.update',
      'secrets.integrations.create',
      'secrets.integrations.delete',
      'secrets.integrations.get',
      'secrets.integrations.list',
      'secrets.rotating-mongodb-atlas.create',
      'secrets.rotating-twilio.create',
      'secrets.rotation-integrations.create',
      'secrets.rotation-integrations.delete',
      'secrets.rotation-integrations.get',
      'secrets.rotation-integrations.list',
      'secrets.rotation-integrations.update',
      'secrets.sync-installations.create',
      'secrets.sync-installations.delete',
      'secrets.sync-installations.get',
      'secrets.sync-installations.list',
      'secrets.sync-installations.update',
      'secrets.sync-integrations.create',
      'secrets.sync-integrations.delete',
      'secrets.sync-integrations.force',
      'secrets.sync-integrations.get',
      'secrets.sync-integrations.list',
      'secrets.sync-integrations.update',
      'secrets.usage.get',
      'vagrant.architecture.create',
      'vagrant.architecture.delete',
      'vagrant.architecture.read',
      'vagrant.architecture.update',
      'vagrant.box.create',
      'vagrant.box.delete',
      'vagrant.box.list',
      'vagrant.box.read',
      'vagrant.box.update',
      'vagrant.boxfile.complete',
      'vagrant.boxfile.create',
      'vagrant.boxfile.delete',
      'vagrant.boxfile.download',
      'vagrant.boxfile.read',
      'vagrant.boxfile.update',
      'vagrant.boxfile.upload',
      'vagrant.provider.create',
      'vagrant.provider.delete',
      'vagrant.provider.list',
      'vagrant.provider.read',
      'vagrant.provider.update',
      'vagrant.registry.activate',
      'vagrant.registry.create',
      'vagrant.registry.deactivate',
      'vagrant.registry.delete',
      'vagrant.registry.list',
      'vagrant.registry.read',
      'vagrant.registry.update',
      'vagrant.version.create',
      'vagrant.version.delete',
      'vagrant.version.list',
      'vagrant.version.read',
      'vagrant.version.release',
      'vagrant.version.revoke',
      'vagrant.version.update',
      'vault.audit-logs.get',
      'vault.clusters.create',
      'vault.clusters.create-admin-token',
      'vault.clusters.delete',
      'vault.clusters.get',
      'vault.clusters.list',
      'vault.clusters.list-features',
      'vault.clusters.lock',
      'vault.clusters.proxy-access',
      'vault.clusters.revoke-admin-tokens',
      'vault.clusters.scale',
      'vault.clusters.seal',
      'vault.clusters.sentinel-policy-delete',
      'vault.clusters.unlock',
      'vault.clusters.unseal',
      'vault.clusters.update',
      'vault.majorversionupgrade.manual-upgrade',
      'vault.majorversionupgradeconfig.update',
      'vault.notifications.contributor',
      'vault.notifications.viewer',
      'vault.pathsfilter.create',
      'vault.pathsfilter.delete',
      'vault.performancereplication.disable',
      'vault.performancereplication.enable',
      'vault.performancereplication.secondaries-list',
      'vault.performancereplication.status',
      'vault.plugin.is-registered',
      'vault.plugins.deregister-plugin',
      'vault.plugins.register-plugin',
      'vault.providers.get',
      'vault.snapshots.create',
      'vault.snapshots.delete',
      'vault.snapshots.get',
      'vault.snapshots.list',
      'vault.snapshots.update',
      'vault.templates.list',
      'vault-insights.aggregates.get',
      'vault-insights.history.get',
      'vault-link.clusters.authenticate-to-cluster',
      'vault-link.clusters.create',
      'vault-link.clusters.delete',
      'vault-link.clusters.get',
      'vault-link.clusters.list',
      'vault-link.clusters.revoke-credentials',
      'vault-link.clusters.rotate-credentials',
      'vault-radar.admin-api-key-settings.create',
      'vault-radar.admin-api-key-settings.delete',
      'vault-radar.admin-api-key-settings.get',
      'vault-radar.admin-api-key-settings.list',
      'vault-radar.admin-settings.create',
      'vault-radar.admin-settings.delete',
      'vault-radar.admin-settings.get',
      'vault-radar.admin-settings.list',
      'vault-radar.admin-settings.update',
      'vault-radar.agent-service-principals.create',
      'vault-radar.agents.create',
      'vault-radar.agents.delete',
      'vault-radar.agents.get',
      'vault-radar.agents.list',
      'vault-radar.agents.update',
      'vault-radar.agents-jobs.list',
      'vault-radar.agents-jobs.update',
      'vault-radar.alerts.get',
      'vault-radar.alerts.list',
      'vault-radar.alerts.update',
      'vault-radar.build-scans.get',
      'vault-radar.build-scans.list',
      'vault-radar.checkpoints.create',
      'vault-radar.checkpoints.get',
      'vault-radar.checkpoints.update',
      'vault-radar.code-server-groups.create',
      'vault-radar.code-server-groups.get',
      'vault-radar.code-server-groups.list',
      'vault-radar.code-server-groups.update',
      'vault-radar.code-server-registrations.create',
      'vault-radar.code-server-registrations.get',
      'vault-radar.code-server-registrations.list',
      'vault-radar.code-server-registrations.update',
      'vault-radar.code-servers.get',
      'vault-radar.code-servers.list',
      'vault-radar.code-servers.update',
      'vault-radar.configs.create',
      'vault-radar.configs.get',
      'vault-radar.configs.list',
      'vault-radar.data-source-groups.create',
      'vault-radar.data-source-groups.get',
      'vault-radar.data-source-groups.list',
      'vault-radar.data-source-groups.update',
      'vault-radar.data-source-registrations.create',
      'vault-radar.data-source-registrations.get',
      'vault-radar.data-source-registrations.list',
      'vault-radar.data-source-registrations.update',
      'vault-radar.data-sources.get',
      'vault-radar.data-sources.list',
      'vault-radar.data-sources.update',
      'vault-radar.developers.get',
      'vault-radar.developers.list',
      'vault-radar.exports.get',
      'vault-radar.exports.list',
      'vault-radar.github-apps.create',
      'vault-radar.github-apps.delete',
      'vault-radar.github-apps.get',
      'vault-radar.github-apps.list',
      'vault-radar.github-apps.update',
      'vault-radar.integrations.create',
      'vault-radar.integrations.delete',
      'vault-radar.integrations.get',
      'vault-radar.integrations.list',
      'vault-radar.integrations.update',
      'vault-radar.public-admin-settings.get',
      'vault-radar.public-admin-settings.list',
      'vault-radar.public-code-server-registrations.get',
      'vault-radar.public-data-source-registrations.get',
      'vault-radar.public-events.list',
      'vault-radar.public-events.update',
      'vault-radar.public-repos.get',
      'vault-radar.public-repos.list',
      'vault-radar.public-repos-scans.get',
      'vault-radar.public-tenants.get',
      'vault-radar.remediations.get',
      'vault-radar.remediations.list',
      'vault-radar.remediations.update',
      'vault-radar.repos.get',
      'vault-radar.repos.list',
      'vault-radar.repos.update',
      'vault-radar.resources.create',
      'vault-radar.resources.get',
      'vault-radar.resources.list',
      'vault-radar.resources.update',
      'vault-radar.rules.create',
      'vault-radar.rules.delete',
      'vault-radar.rules.get',
      'vault-radar.rules.list',
      'vault-radar.rules.update',
      'vault-radar.scan-results.create',
      'vault-radar.scan-targets.get-iam-policy',
      'vault-radar.scan-targets.list',
      'vault-radar.scan-targets.set-iam-policy',
      'vault-radar.scans.create',
      'vault-radar.scans.list',
      'vault-radar.scans.update',
      'vault-radar.task-definitions.get',
      'vault-radar.task-definitions.list',
      'vault-radar.user-meters.create',
      'vault-radar.user-meters.update',
      'vault-radar.users.can-admin',
      'vault-radar.users.can-contribute',
      'vault-radar.users.can-view',
      'vault-scanning.deprecated-permission.get',
      'vault-secrets.app-secret-versions.list',
      'vault-secrets.app-secret-versions.open',
      'vault-secrets.app-secrets.create',
      'vault-secrets.app-secrets.delete',
      'vault-secrets.app-secrets.get',
      'vault-secrets.app-secrets.list',
      'vault-secrets.app-secrets.open',
      'vault-secrets.app-secrets.rotate',
      'vault-secrets.app-secrets.update',
      'vault-secrets.app-secrets-state.get',
      'vault-secrets.apps.create',
      'vault-secrets.apps.delete',
      'vault-secrets.apps.get',
      'vault-secrets.apps.list',
      'vault-secrets.apps.update',
      'vault-secrets.billing.update',
      'vault-secrets.rotating-twilio.create',
      'vault-secrets.rotation-integrations.create',
      'vault-secrets.rotation-integrations.delete',
      'vault-secrets.rotation-integrations.get',
      'vault-secrets.rotation-integrations.list',
      'vault-secrets.rotation-integrations.update',
      'vault-secrets.sync-installations.create',
      'vault-secrets.sync-installations.delete',
      'vault-secrets.sync-installations.get',
      'vault-secrets.sync-installations.update',
      'vault-secrets.sync-integrations.create',
      'vault-secrets.sync-integrations.delete',
      'vault-secrets.sync-integrations.force',
      'vault-secrets.sync-integrations.get',
      'vault-secrets.sync-integrations.list',
      'vault-secrets.sync-integrations.update',
      'vault-secrets.usage.get',
      'waypoint.action.create',
      'waypoint.action.delete',
      'waypoint.action.read',
      'waypoint.action.run',
      'waypoint.action.update',
      'waypoint.addondefinitions.create',
      'waypoint.addondefinitions.delete',
      'waypoint.addondefinitions.read',
      'waypoint.addondefinitions.update',
      'waypoint.addons.create',
      'waypoint.addons.delete',
      'waypoint.addons.read',
      'waypoint.addons.update',
      'waypoint.applications.create',
      'waypoint.applications.delete',
      'waypoint.applications.read',
      'waypoint.applications.update',
      'waypoint.auth-methods.read',
      'waypoint.configs.create',
      'waypoint.configs.delete',
      'waypoint.configs.read',
      'waypoint.configs.update',
      'waypoint.configsourcers.create',
      'waypoint.configsourcers.delete',
      'waypoint.configsourcers.read',
      'waypoint.configsourcers.update',
      'waypoint.namespaces.create',
      'waypoint.namespaces.delete',
      'waypoint.namespaces.get',
      'waypoint.namespaces.list',
      'waypoint.namespaces.update',
      'waypoint.projectfromtemplates.create',
      'waypoint.projectinputvariables.create',
      'waypoint.projectinputvariables.delete',
      'waypoint.projectinputvariables.read',
      'waypoint.projectinputvariables.update',
      'waypoint.projects.create',
      'waypoint.projects.delete',
      'waypoint.projects.read',
      'waypoint.projects.update',
      'waypoint.templates.create',
      'waypoint.templates.delete',
      'waypoint.templates.read',
      'waypoint.templates.update',
      'waypoint.tfcconfig.create',
      'waypoint.tfcconfig.delete',
      'waypoint.tfcconfig.read',
      'waypoint.tfcconfig.update',
      'waypoint.users.delete',
      'waypoint.users.read',
      'waypoint.users.update',
      'waypoint.variable.create',
      'waypoint.variable.delete',
      'waypoint.variable.read',
      'waypoint.variable.update',
      'webhook.deliveries.list',
      'webhook.smoke-test.delete-resource-subscriptions',
      'webhook.smoke-test.deliver-payload',
      'webhook.webhooks.create',
      'webhook.webhooks.delete',
      'webhook.webhooks.get',
      'webhook.webhooks.list',
      'webhook.webhooks.update',
    ],
    lowest_applicable_resource_types: [],
  },
  {
    id: 'roles/admin',
    version: 0,
    title: 'Admin',
    description:
      'Has full access to all resources including the right to edit IAM, invite users, edit roles.',
    permissions: [
      'billing.accounts.assign-project',
      'billing.accounts.create',
      'billing.accounts.delete',
      'billing.accounts.get',
      'billing.accounts.list',
      'billing.accounts.tokenize-payment-details',
      'billing.accounts.unassign-project',
      'billing.accounts.update',
      'billing.fcp.get-consumption-pool',
      'billing.fcp.list-consumption-pools',
      'billing.statements.get-statement',
      'billing.statements.list',
      'boundary.clusters.create',
      'boundary.clusters.delete',
      'boundary.clusters.get',
      'boundary.clusters.get-update-deadline',
      'boundary.clusters.get-update-deadline-internal',
      'boundary.clusters.list',
      'boundary.clusters.maintenance-window-get',
      'boundary.clusters.update',
      'boundary.clusters.update-maintenance-window',
      'boundary.clusters.update-version',
      'boundary.sessions.list',
      'consul.clusters.create',
      'consul.clusters.create-root-token',
      'consul.clusters.delete',
      'consul.clusters.federate',
      'consul.clusters.get',
      'consul.clusters.get-client-config',
      'consul.clusters.get-telemetry',
      'consul.clusters.list',
      'consul.clusters.list-versions',
      'consul.clusters.update',
      'consul.snapshots.create',
      'consul.snapshots.delete',
      'consul.snapshots.get',
      'consul.snapshots.list',
      'consul.snapshots.update',
      'consul-telemetry-gateway.metrics.query',
      'consul-telemetry-gateway.service-topology.get',
      'consul-telemetry-gateway.user-is.admin',
      'consul-telemetry-gateway.user-is.contributor',
      'global-network-manager.consul-clusters.create',
      'global-network-manager.consul-clusters.delete',
      'global-network-manager.consul-clusters.get',
      'global-network-manager.consul-clusters.get-api-information',
      'global-network-manager.consul-clusters.get-ca-config',
      'global-network-manager.consul-clusters.get-management-token-details',
      'global-network-manager.consul-clusters.get-secrets',
      'global-network-manager.consul-clusters.list',
      'global-network-manager.consul-clusters.list-partitions',
      'global-network-manager.consul-clusters.list-service-instances',
      'global-network-manager.consul-clusters.proxy-access',
      'global-network-manager.consul-clusters.ui',
      'global-network-manager.consul-clusters.update',
      'global-network-manager.consul-clusters-servers.list',
      'global-network-manager.consul-observability.get-credentials',
      'global-network-manager.intentions.list',
      'global-network-manager.nodes.get',
      'global-network-manager.nodes.list',
      'global-network-manager.organizations.get-trial',
      'global-network-manager.peering-connections.create',
      'global-network-manager.peering-connections.delete',
      'global-network-manager.peering-connections.get',
      'global-network-manager.peering-connections.list',
      'global-network-manager.peering-connections.list-clusters-eligibility',
      'global-network-manager.peering-connections.list-peers-by-cluster-partition',
      'global-network-manager.service-catalog.summary',
      'global-network-manager.services.get',
      'global-network-manager.services.list',
      'global-network-manager.user-is.admin',
      'global-network-manager.user-is.contributor',
      'iam.auth-connection.create',
      'iam.auth-connection.delete',
      'iam.auth-connection.get',
      'iam.auth-connection.update',
      'iam.auth-metadata.get',
      'iam.domain-ownership-records.get',
      'iam.groups.count',
      'iam.groups.create',
      'iam.groups.delete',
      'iam.groups.get',
      'iam.groups.get-iam-policy',
      'iam.groups.list',
      'iam.groups.list-members',
      'iam.groups.member-count',
      'iam.groups.set-iam-policy',
      'iam.groups.update',
      'iam.groups.update-members',
      'iam.invitations.create',
      'iam.invitations.delete',
      'iam.invitations.get',
      'iam.invitations.list',
      'iam.invitations.update',
      'iam.principal.groups-count',
      'iam.principals.list',
      'iam.service-principals.create',
      'iam.service-principals.create-token',
      'iam.service-principals.delete',
      'iam.service-principals.delete-token',
      'iam.service-principals.get',
      'iam.service-principals.list',
      'iam.service-principals.list-token',
      'iam.service-principals.update',
      'iam.sso-configurations.create',
      'iam.sso-configurations.delete',
      'iam.sso-configurations.get',
      'iam.sso-configurations.list',
      'iam.sso-configurations.update',
      'iam.sso-type.get',
      'iam.users.delete',
      'iam.users.get',
      'iam.users.list',
      'iam.workload-identity-providers.create',
      'iam.workload-identity-providers.delete',
      'iam.workload-identity-providers.get',
      'iam.workload-identity-providers.list',
      'iam.workload-identity-providers.update',
      'idp.scim.create-token',
      'idp.scim.delete-token',
      'idp.scim.disable',
      'idp.scim.enable',
      'idp.scim.get',
      'idp.scim.list-tokens',
      'link.nodes-status.get',
      'log.archive.create',
      'log.archive.get',
      'log.archive.list',
      'log.entries.get',
      'log.entries.store',
      'log.streaming.create',
      'log.streaming.delete',
      'log.streaming.get',
      'log.streaming.list',
      'log.streaming.test',
      'log.streaming.update',
      'network.dependencies.list',
      'network.hvns.create',
      'network.hvns.delete',
      'network.hvns.get',
      'network.hvns.list',
      'network.hvns.update',
      'network.peerings.create',
      'network.peerings.delete',
      'network.peerings.get',
      'network.peerings.list',
      'network.routes.create',
      'network.routes.delete',
      'network.routes.get',
      'network.routes.list',
      'network.tgw-attachments.create',
      'network.tgw-attachments.delete',
      'network.tgw-attachments.get',
      'network.tgw-attachments.list',
      'operation.operations.get',
      'operation.operations.list',
      'packer.bucket.contributors-list',
      'packer.bucket.create',
      'packer.bucket.delete',
      'packer.bucket.get',
      'packer.bucket.list',
      'packer.bucket.update',
      'packer.buckets.get-iam-policy',
      'packer.buckets.set-iam-policy',
      'packer.channels.contributors-get',
      'packer.channels.contributors-list',
      'packer.channels.create',
      'packer.channels.delete',
      'packer.channels.get',
      'packer.channels.list',
      'packer.channels.update',
      'packer.images.contributors-list',
      'packer.images.create',
      'packer.images.delete',
      'packer.images.get',
      'packer.images.list',
      'packer.images.update',
      'packer.iterations.create',
      'packer.iterations.delete',
      'packer.iterations.get',
      'packer.iterations.list',
      'packer.iterations.update',
      'packer.registries.create',
      'packer.registries.delete',
      'packer.registries.get',
      'packer.registries.update',
      'packer.versions.create',
      'packer.versions.delete',
      'packer.versions.get',
      'packer.versions.list',
      'packer.versions.update',
      'resource-manager.organizations.get-iam-policy',
      'resource-manager.organizations.get-role',
      'resource-manager.organizations.list-roles',
      'resource-manager.organizations.set-iam-policy',
      'resource-manager.organizations.set-name',
      'resource-manager.projects.create',
      'resource-manager.projects.delete',
      'resource-manager.projects.get',
      'resource-manager.projects.get-iam-policy',
      'resource-manager.projects.list',
      'resource-manager.projects.set-iam-policy',
      'resource-manager.projects.set-system-managed-iam-policy',
      'resource-manager.projects.update',
      'resource-manager.resources.list',
      'resource-manager.resources.list-roles',
      'secrets.app-secret-versions.list',
      'secrets.app-secret-versions.open',
      'secrets.app-secrets.create',
      'secrets.app-secrets.delete',
      'secrets.app-secrets.get',
      'secrets.app-secrets.list',
      'secrets.app-secrets.open',
      'secrets.app-secrets.rotate',
      'secrets.app-secrets.update',
      'secrets.app-secrets-state.get',
      'secrets.apps.create',
      'secrets.apps.delete',
      'secrets.apps.get',
      'secrets.apps.get-iam-policy',
      'secrets.apps.list',
      'secrets.apps.set-iam-policy',
      'secrets.apps.update',
      'secrets.billing.update',
      'secrets.gateway-pools.create',
      'secrets.gateway-pools.delete',
      'secrets.gateway-pools.get',
      'secrets.gateway-pools.get-iam-policy',
      'secrets.gateway-pools.list',
      'secrets.gateway-pools.set-iam-policy',
      'secrets.gateway-pools.update',
      'secrets.integrations.create',
      'secrets.integrations.delete',
      'secrets.integrations.get',
      'secrets.integrations.list',
      'secrets.rotating-mongodb-atlas.create',
      'secrets.rotating-twilio.create',
      'secrets.rotation-integrations.create',
      'secrets.rotation-integrations.delete',
      'secrets.rotation-integrations.get',
      'secrets.rotation-integrations.list',
      'secrets.rotation-integrations.update',
      'secrets.sync-installations.create',
      'secrets.sync-installations.delete',
      'secrets.sync-installations.get',
      'secrets.sync-installations.list',
      'secrets.sync-installations.update',
      'secrets.sync-integrations.create',
      'secrets.sync-integrations.delete',
      'secrets.sync-integrations.force',
      'secrets.sync-integrations.get',
      'secrets.sync-integrations.list',
      'secrets.sync-integrations.update',
      'secrets.usage.get',
      'vagrant.architecture.create',
      'vagrant.architecture.delete',
      'vagrant.architecture.read',
      'vagrant.architecture.update',
      'vagrant.box.create',
      'vagrant.box.delete',
      'vagrant.box.list',
      'vagrant.box.read',
      'vagrant.box.update',
      'vagrant.boxfile.complete',
      'vagrant.boxfile.create',
      'vagrant.boxfile.delete',
      'vagrant.boxfile.download',
      'vagrant.boxfile.read',
      'vagrant.boxfile.update',
      'vagrant.boxfile.upload',
      'vagrant.provider.create',
      'vagrant.provider.delete',
      'vagrant.provider.list',
      'vagrant.provider.read',
      'vagrant.provider.update',
      'vagrant.registry.activate',
      'vagrant.registry.create',
      'vagrant.registry.deactivate',
      'vagrant.registry.delete',
      'vagrant.registry.list',
      'vagrant.registry.read',
      'vagrant.registry.update',
      'vagrant.version.create',
      'vagrant.version.delete',
      'vagrant.version.list',
      'vagrant.version.read',
      'vagrant.version.release',
      'vagrant.version.revoke',
      'vagrant.version.update',
      'vault.audit-logs.get',
      'vault.clusters.create',
      'vault.clusters.create-admin-token',
      'vault.clusters.delete',
      'vault.clusters.get',
      'vault.clusters.list',
      'vault.clusters.list-features',
      'vault.clusters.lock',
      'vault.clusters.proxy-access',
      'vault.clusters.revoke-admin-tokens',
      'vault.clusters.scale',
      'vault.clusters.seal',
      'vault.clusters.sentinel-policy-delete',
      'vault.clusters.unlock',
      'vault.clusters.unseal',
      'vault.clusters.update',
      'vault.majorversionupgrade.manual-upgrade',
      'vault.majorversionupgradeconfig.update',
      'vault.notifications.contributor',
      'vault.notifications.viewer',
      'vault.pathsfilter.create',
      'vault.pathsfilter.delete',
      'vault.performancereplication.disable',
      'vault.performancereplication.enable',
      'vault.performancereplication.secondaries-list',
      'vault.performancereplication.status',
      'vault.plugin.is-registered',
      'vault.plugins.deregister-plugin',
      'vault.plugins.register-plugin',
      'vault.providers.get',
      'vault.snapshots.create',
      'vault.snapshots.delete',
      'vault.snapshots.get',
      'vault.snapshots.list',
      'vault.snapshots.update',
      'vault.templates.list',
      'vault-insights.aggregates.get',
      'vault-insights.history.get',
      'vault-link.clusters.authenticate-to-cluster',
      'vault-link.clusters.create',
      'vault-link.clusters.delete',
      'vault-link.clusters.get',
      'vault-link.clusters.list',
      'vault-link.clusters.revoke-credentials',
      'vault-link.clusters.rotate-credentials',
      'vault-radar.admin-api-key-settings.create',
      'vault-radar.admin-api-key-settings.delete',
      'vault-radar.admin-api-key-settings.get',
      'vault-radar.admin-api-key-settings.list',
      'vault-radar.admin-settings.create',
      'vault-radar.admin-settings.delete',
      'vault-radar.admin-settings.get',
      'vault-radar.admin-settings.list',
      'vault-radar.admin-settings.update',
      'vault-radar.agent-service-principals.create',
      'vault-radar.agents.create',
      'vault-radar.agents.delete',
      'vault-radar.agents.get',
      'vault-radar.agents.list',
      'vault-radar.agents.update',
      'vault-radar.agents-jobs.list',
      'vault-radar.agents-jobs.update',
      'vault-radar.alerts.get',
      'vault-radar.alerts.list',
      'vault-radar.alerts.update',
      'vault-radar.build-scans.get',
      'vault-radar.build-scans.list',
      'vault-radar.checkpoints.create',
      'vault-radar.checkpoints.get',
      'vault-radar.checkpoints.update',
      'vault-radar.code-server-groups.create',
      'vault-radar.code-server-groups.get',
      'vault-radar.code-server-groups.list',
      'vault-radar.code-server-groups.update',
      'vault-radar.code-server-registrations.create',
      'vault-radar.code-server-registrations.get',
      'vault-radar.code-server-registrations.list',
      'vault-radar.code-server-registrations.update',
      'vault-radar.code-servers.get',
      'vault-radar.code-servers.list',
      'vault-radar.code-servers.update',
      'vault-radar.configs.create',
      'vault-radar.configs.get',
      'vault-radar.configs.list',
      'vault-radar.data-source-groups.create',
      'vault-radar.data-source-groups.get',
      'vault-radar.data-source-groups.list',
      'vault-radar.data-source-groups.update',
      'vault-radar.data-source-registrations.create',
      'vault-radar.data-source-registrations.get',
      'vault-radar.data-source-registrations.list',
      'vault-radar.data-source-registrations.update',
      'vault-radar.data-sources.get',
      'vault-radar.data-sources.list',
      'vault-radar.data-sources.update',
      'vault-radar.developers.get',
      'vault-radar.developers.list',
      'vault-radar.exports.get',
      'vault-radar.exports.list',
      'vault-radar.github-apps.create',
      'vault-radar.github-apps.delete',
      'vault-radar.github-apps.get',
      'vault-radar.github-apps.list',
      'vault-radar.github-apps.update',
      'vault-radar.integrations.create',
      'vault-radar.integrations.delete',
      'vault-radar.integrations.get',
      'vault-radar.integrations.list',
      'vault-radar.integrations.update',
      'vault-radar.public-admin-settings.get',
      'vault-radar.public-admin-settings.list',
      'vault-radar.public-code-server-registrations.get',
      'vault-radar.public-data-source-registrations.get',
      'vault-radar.public-events.list',
      'vault-radar.public-events.update',
      'vault-radar.public-repos.get',
      'vault-radar.public-repos.list',
      'vault-radar.public-repos-scans.get',
      'vault-radar.public-tenants.get',
      'vault-radar.remediations.get',
      'vault-radar.remediations.list',
      'vault-radar.remediations.update',
      'vault-radar.repos.get',
      'vault-radar.repos.list',
      'vault-radar.repos.update',
      'vault-radar.resources.create',
      'vault-radar.resources.get',
      'vault-radar.resources.list',
      'vault-radar.resources.update',
      'vault-radar.rules.create',
      'vault-radar.rules.delete',
      'vault-radar.rules.get',
      'vault-radar.rules.list',
      'vault-radar.rules.update',
      'vault-radar.scan-results.create',
      'vault-radar.scan-targets.get-iam-policy',
      'vault-radar.scan-targets.list',
      'vault-radar.scan-targets.set-iam-policy',
      'vault-radar.scans.create',
      'vault-radar.scans.list',
      'vault-radar.scans.update',
      'vault-radar.task-definitions.get',
      'vault-radar.task-definitions.list',
      'vault-radar.user-meters.create',
      'vault-radar.user-meters.update',
      'vault-radar.users.can-admin',
      'vault-radar.users.can-contribute',
      'vault-radar.users.can-view',
      'vault-scanning.deprecated-permission.get',
      'vault-secrets.app-secret-versions.list',
      'vault-secrets.app-secret-versions.open',
      'vault-secrets.app-secrets.create',
      'vault-secrets.app-secrets.delete',
      'vault-secrets.app-secrets.get',
      'vault-secrets.app-secrets.list',
      'vault-secrets.app-secrets.open',
      'vault-secrets.app-secrets.rotate',
      'vault-secrets.app-secrets.update',
      'vault-secrets.app-secrets-state.get',
      'vault-secrets.apps.create',
      'vault-secrets.apps.delete',
      'vault-secrets.apps.get',
      'vault-secrets.apps.list',
      'vault-secrets.apps.update',
      'vault-secrets.billing.update',
      'vault-secrets.rotating-twilio.create',
      'vault-secrets.rotation-integrations.create',
      'vault-secrets.rotation-integrations.delete',
      'vault-secrets.rotation-integrations.get',
      'vault-secrets.rotation-integrations.list',
      'vault-secrets.rotation-integrations.update',
      'vault-secrets.sync-installations.create',
      'vault-secrets.sync-installations.delete',
      'vault-secrets.sync-installations.get',
      'vault-secrets.sync-installations.update',
      'vault-secrets.sync-integrations.create',
      'vault-secrets.sync-integrations.delete',
      'vault-secrets.sync-integrations.force',
      'vault-secrets.sync-integrations.get',
      'vault-secrets.sync-integrations.list',
      'vault-secrets.sync-integrations.update',
      'vault-secrets.usage.get',
      'waypoint.action.create',
      'waypoint.action.delete',
      'waypoint.action.read',
      'waypoint.action.run',
      'waypoint.action.update',
      'waypoint.addondefinitions.create',
      'waypoint.addondefinitions.delete',
      'waypoint.addondefinitions.read',
      'waypoint.addondefinitions.update',
      'waypoint.addons.create',
      'waypoint.addons.delete',
      'waypoint.addons.read',
      'waypoint.addons.update',
      'waypoint.applications.create',
      'waypoint.applications.delete',
      'waypoint.applications.read',
      'waypoint.applications.update',
      'waypoint.auth-methods.read',
      'waypoint.configs.create',
      'waypoint.configs.delete',
      'waypoint.configs.read',
      'waypoint.configs.update',
      'waypoint.configsourcers.create',
      'waypoint.configsourcers.delete',
      'waypoint.configsourcers.read',
      'waypoint.configsourcers.update',
      'waypoint.namespaces.create',
      'waypoint.namespaces.delete',
      'waypoint.namespaces.get',
      'waypoint.namespaces.list',
      'waypoint.namespaces.update',
      'waypoint.projectfromtemplates.create',
      'waypoint.projectinputvariables.create',
      'waypoint.projectinputvariables.delete',
      'waypoint.projectinputvariables.read',
      'waypoint.projectinputvariables.update',
      'waypoint.projects.create',
      'waypoint.projects.delete',
      'waypoint.projects.read',
      'waypoint.projects.update',
      'waypoint.templates.create',
      'waypoint.templates.delete',
      'waypoint.templates.read',
      'waypoint.templates.update',
      'waypoint.tfcconfig.create',
      'waypoint.tfcconfig.delete',
      'waypoint.tfcconfig.read',
      'waypoint.tfcconfig.update',
      'waypoint.users.delete',
      'waypoint.users.read',
      'waypoint.users.update',
      'waypoint.variable.create',
      'waypoint.variable.delete',
      'waypoint.variable.read',
      'waypoint.variable.update',
      'webhook.deliveries.list',
      'webhook.smoke-test.delete-resource-subscriptions',
      'webhook.smoke-test.deliver-payload',
      'webhook.webhooks.create',
      'webhook.webhooks.delete',
      'webhook.webhooks.get',
      'webhook.webhooks.list',
      'webhook.webhooks.update',
    ],
    lowest_applicable_resource_types: [],
  },
  {
    id: 'roles/contributor',
    version: 0,
    title: 'Contributor',
    description:
      'Can create and manage all types of resources. Can only view IAM.',
    permissions: [
      'billing.accounts.assign-project',
      'billing.accounts.get',
      'billing.accounts.list',
      'billing.fcp.get-consumption-pool',
      'billing.fcp.list-consumption-pools',
      'billing.statements.get-statement',
      'billing.statements.list',
      'boundary.clusters.create',
      'boundary.clusters.delete',
      'boundary.clusters.get',
      'boundary.clusters.get-update-deadline',
      'boundary.clusters.get-update-deadline-internal',
      'boundary.clusters.list',
      'boundary.clusters.maintenance-window-get',
      'boundary.clusters.update',
      'boundary.clusters.update-maintenance-window',
      'boundary.clusters.update-version',
      'boundary.sessions.list',
      'consul.clusters.create',
      'consul.clusters.create-root-token',
      'consul.clusters.delete',
      'consul.clusters.federate',
      'consul.clusters.get',
      'consul.clusters.get-client-config',
      'consul.clusters.get-telemetry',
      'consul.clusters.list',
      'consul.clusters.list-versions',
      'consul.clusters.update',
      'consul.snapshots.create',
      'consul.snapshots.delete',
      'consul.snapshots.get',
      'consul.snapshots.list',
      'consul.snapshots.update',
      'consul-telemetry-gateway.metrics.query',
      'consul-telemetry-gateway.service-topology.get',
      'consul-telemetry-gateway.user-is.contributor',
      'global-network-manager.consul-clusters.create',
      'global-network-manager.consul-clusters.delete',
      'global-network-manager.consul-clusters.get',
      'global-network-manager.consul-clusters.get-api-information',
      'global-network-manager.consul-clusters.get-ca-config',
      'global-network-manager.consul-clusters.get-management-token-details',
      'global-network-manager.consul-clusters.get-secrets',
      'global-network-manager.consul-clusters.list',
      'global-network-manager.consul-clusters.list-partitions',
      'global-network-manager.consul-clusters.list-service-instances',
      'global-network-manager.consul-clusters.proxy-access',
      'global-network-manager.consul-clusters.ui',
      'global-network-manager.consul-clusters.update',
      'global-network-manager.consul-clusters-servers.list',
      'global-network-manager.consul-observability.get-credentials',
      'global-network-manager.intentions.list',
      'global-network-manager.nodes.get',
      'global-network-manager.nodes.list',
      'global-network-manager.organizations.get-trial',
      'global-network-manager.peering-connections.create',
      'global-network-manager.peering-connections.delete',
      'global-network-manager.peering-connections.get',
      'global-network-manager.peering-connections.list',
      'global-network-manager.peering-connections.list-clusters-eligibility',
      'global-network-manager.peering-connections.list-peers-by-cluster-partition',
      'global-network-manager.service-catalog.summary',
      'global-network-manager.services.get',
      'global-network-manager.services.list',
      'global-network-manager.user-is.contributor',
      'iam.auth-metadata.get',
      'iam.groups.count',
      'iam.groups.get',
      'iam.groups.list',
      'iam.groups.list-members',
      'iam.groups.member-count',
      'iam.invitations.get',
      'iam.invitations.list',
      'iam.principal.groups-count',
      'iam.sso-type.get',
      'iam.users.get',
      'iam.users.list',
      'link.nodes-status.get',
      'log.archive.create',
      'log.archive.get',
      'log.archive.list',
      'log.entries.get',
      'log.entries.store',
      'log.streaming.create',
      'log.streaming.delete',
      'log.streaming.get',
      'log.streaming.list',
      'log.streaming.test',
      'log.streaming.update',
      'network.dependencies.list',
      'network.hvns.create',
      'network.hvns.delete',
      'network.hvns.get',
      'network.hvns.list',
      'network.hvns.update',
      'network.peerings.create',
      'network.peerings.delete',
      'network.peerings.get',
      'network.peerings.list',
      'network.routes.create',
      'network.routes.delete',
      'network.routes.get',
      'network.routes.list',
      'network.tgw-attachments.create',
      'network.tgw-attachments.delete',
      'network.tgw-attachments.get',
      'network.tgw-attachments.list',
      'operation.operations.get',
      'operation.operations.list',
      'packer.bucket.contributors-list',
      'packer.bucket.create',
      'packer.bucket.delete',
      'packer.bucket.get',
      'packer.bucket.list',
      'packer.bucket.update',
      'packer.channels.contributors-get',
      'packer.channels.contributors-list',
      'packer.channels.create',
      'packer.channels.delete',
      'packer.channels.get',
      'packer.channels.list',
      'packer.channels.update',
      'packer.images.contributors-list',
      'packer.images.create',
      'packer.images.delete',
      'packer.images.get',
      'packer.images.list',
      'packer.images.update',
      'packer.iterations.create',
      'packer.iterations.delete',
      'packer.iterations.get',
      'packer.iterations.list',
      'packer.iterations.update',
      'packer.registries.create',
      'packer.registries.delete',
      'packer.registries.get',
      'packer.registries.update',
      'packer.versions.create',
      'packer.versions.delete',
      'packer.versions.get',
      'packer.versions.list',
      'packer.versions.update',
      'resource-manager.organizations.get-iam-policy',
      'resource-manager.organizations.get-role',
      'resource-manager.organizations.list-roles',
      'resource-manager.projects.create',
      'resource-manager.projects.get',
      'resource-manager.projects.get-iam-policy',
      'resource-manager.projects.list',
      'resource-manager.resources.list',
      'resource-manager.resources.list-roles',
      'secrets.app-secret-versions.list',
      'secrets.app-secret-versions.open',
      'secrets.app-secrets.create',
      'secrets.app-secrets.delete',
      'secrets.app-secrets.get',
      'secrets.app-secrets.list',
      'secrets.app-secrets.open',
      'secrets.app-secrets.rotate',
      'secrets.app-secrets.update',
      'secrets.app-secrets-state.get',
      'secrets.apps.create',
      'secrets.apps.delete',
      'secrets.apps.get',
      'secrets.apps.list',
      'secrets.apps.update',
      'secrets.gateway-pools.create',
      'secrets.gateway-pools.delete',
      'secrets.gateway-pools.get',
      'secrets.gateway-pools.list',
      'secrets.gateway-pools.update',
      'secrets.integrations.get',
      'secrets.integrations.list',
      'secrets.rotating-mongodb-atlas.create',
      'secrets.rotating-twilio.create',
      'secrets.rotation-integrations.create',
      'secrets.rotation-integrations.delete',
      'secrets.rotation-integrations.get',
      'secrets.rotation-integrations.list',
      'secrets.rotation-integrations.update',
      'secrets.sync-installations.create',
      'secrets.sync-installations.delete',
      'secrets.sync-installations.get',
      'secrets.sync-installations.list',
      'secrets.sync-installations.update',
      'secrets.sync-integrations.create',
      'secrets.sync-integrations.delete',
      'secrets.sync-integrations.force',
      'secrets.sync-integrations.get',
      'secrets.sync-integrations.list',
      'secrets.sync-integrations.update',
      'secrets.usage.get',
      'vagrant.architecture.create',
      'vagrant.architecture.delete',
      'vagrant.architecture.read',
      'vagrant.architecture.update',
      'vagrant.box.create',
      'vagrant.box.delete',
      'vagrant.box.list',
      'vagrant.box.read',
      'vagrant.box.update',
      'vagrant.boxfile.complete',
      'vagrant.boxfile.create',
      'vagrant.boxfile.delete',
      'vagrant.boxfile.download',
      'vagrant.boxfile.read',
      'vagrant.boxfile.update',
      'vagrant.boxfile.upload',
      'vagrant.provider.create',
      'vagrant.provider.delete',
      'vagrant.provider.list',
      'vagrant.provider.read',
      'vagrant.provider.update',
      'vagrant.registry.list',
      'vagrant.registry.read',
      'vagrant.version.create',
      'vagrant.version.delete',
      'vagrant.version.list',
      'vagrant.version.read',
      'vagrant.version.release',
      'vagrant.version.revoke',
      'vagrant.version.update',
      'vault.clusters.create',
      'vault.clusters.create-admin-token',
      'vault.clusters.delete',
      'vault.clusters.get',
      'vault.clusters.list',
      'vault.clusters.list-features',
      'vault.clusters.lock',
      'vault.clusters.proxy-access',
      'vault.clusters.seal',
      'vault.clusters.unlock',
      'vault.clusters.unseal',
      'vault.clusters.update',
      'vault.majorversionupgrade.manual-upgrade',
      'vault.majorversionupgradeconfig.update',
      'vault.notifications.contributor',
      'vault.notifications.viewer',
      'vault.pathsfilter.create',
      'vault.pathsfilter.delete',
      'vault.performancereplication.disable',
      'vault.performancereplication.enable',
      'vault.performancereplication.secondaries-list',
      'vault.performancereplication.status',
      'vault.plugin.is-registered',
      'vault.plugins.deregister-plugin',
      'vault.plugins.register-plugin',
      'vault.providers.get',
      'vault.snapshots.create',
      'vault.snapshots.delete',
      'vault.snapshots.get',
      'vault.snapshots.list',
      'vault.snapshots.update',
      'vault.templates.list',
      'vault-insights.aggregates.get',
      'vault-insights.history.get',
      'vault-link.clusters.create',
      'vault-link.clusters.delete',
      'vault-link.clusters.get',
      'vault-link.clusters.list',
      'vault-radar.admin-api-key-settings.get',
      'vault-radar.admin-api-key-settings.list',
      'vault-radar.admin-settings.get',
      'vault-radar.admin-settings.list',
      'vault-radar.agents.get',
      'vault-radar.agents.list',
      'vault-radar.alerts.get',
      'vault-radar.alerts.list',
      'vault-radar.alerts.update',
      'vault-radar.build-scans.get',
      'vault-radar.build-scans.list',
      'vault-radar.checkpoints.get',
      'vault-radar.code-server-groups.get',
      'vault-radar.code-server-groups.list',
      'vault-radar.code-server-registrations.get',
      'vault-radar.code-server-registrations.list',
      'vault-radar.code-servers.get',
      'vault-radar.code-servers.list',
      'vault-radar.configs.get',
      'vault-radar.configs.list',
      'vault-radar.data-source-groups.get',
      'vault-radar.data-source-groups.list',
      'vault-radar.data-source-registrations.get',
      'vault-radar.data-source-registrations.list',
      'vault-radar.data-sources.get',
      'vault-radar.data-sources.list',
      'vault-radar.developers.get',
      'vault-radar.developers.list',
      'vault-radar.exports.get',
      'vault-radar.exports.list',
      'vault-radar.integrations.get',
      'vault-radar.integrations.list',
      'vault-radar.public-admin-settings.get',
      'vault-radar.public-admin-settings.list',
      'vault-radar.public-code-server-registrations.get',
      'vault-radar.public-data-source-registrations.get',
      'vault-radar.public-events.list',
      'vault-radar.public-events.update',
      'vault-radar.public-repos.get',
      'vault-radar.public-repos.list',
      'vault-radar.public-repos-scans.get',
      'vault-radar.public-tenants.get',
      'vault-radar.remediations.get',
      'vault-radar.remediations.list',
      'vault-radar.repos.get',
      'vault-radar.repos.list',
      'vault-radar.resources.get',
      'vault-radar.resources.list',
      'vault-radar.rules.get',
      'vault-radar.rules.list',
      'vault-radar.scan-targets.list',
      'vault-radar.scans.list',
      'vault-radar.task-definitions.get',
      'vault-radar.task-definitions.list',
      'vault-radar.users.can-contribute',
      'vault-radar.users.can-view',
      'vault-scanning.deprecated-permission.get',
      'vault-secrets.app-secret-versions.list',
      'vault-secrets.app-secret-versions.open',
      'vault-secrets.app-secrets.create',
      'vault-secrets.app-secrets.delete',
      'vault-secrets.app-secrets.get',
      'vault-secrets.app-secrets.list',
      'vault-secrets.app-secrets.open',
      'vault-secrets.app-secrets.rotate',
      'vault-secrets.app-secrets.update',
      'vault-secrets.app-secrets-state.get',
      'vault-secrets.apps.create',
      'vault-secrets.apps.delete',
      'vault-secrets.apps.get',
      'vault-secrets.apps.list',
      'vault-secrets.apps.update',
      'vault-secrets.rotating-twilio.create',
      'vault-secrets.rotation-integrations.get',
      'vault-secrets.rotation-integrations.list',
      'vault-secrets.sync-installations.get',
      'vault-secrets.sync-integrations.get',
      'vault-secrets.sync-integrations.list',
      'vault-secrets.usage.get',
      'waypoint.action.read',
      'waypoint.action.run',
      'waypoint.addondefinitions.read',
      'waypoint.addons.create',
      'waypoint.addons.delete',
      'waypoint.addons.read',
      'waypoint.addons.update',
      'waypoint.applications.create',
      'waypoint.applications.delete',
      'waypoint.applications.read',
      'waypoint.applications.update',
      'waypoint.auth-methods.read',
      'waypoint.configs.create',
      'waypoint.configs.read',
      'waypoint.configs.update',
      'waypoint.configsourcers.create',
      'waypoint.configsourcers.read',
      'waypoint.configsourcers.update',
      'waypoint.namespaces.create',
      'waypoint.namespaces.delete',
      'waypoint.namespaces.get',
      'waypoint.namespaces.list',
      'waypoint.namespaces.update',
      'waypoint.projectfromtemplates.create',
      'waypoint.projectinputvariables.delete',
      'waypoint.projectinputvariables.read',
      'waypoint.projectinputvariables.update',
      'waypoint.projects.create',
      'waypoint.projects.delete',
      'waypoint.projects.read',
      'waypoint.projects.update',
      'waypoint.templates.read',
      'waypoint.tfcconfig.create',
      'waypoint.tfcconfig.delete',
      'waypoint.tfcconfig.read',
      'waypoint.tfcconfig.update',
      'waypoint.users.read',
      'waypoint.users.update',
      'waypoint.variable.read',
      'waypoint.variable.update',
      'webhook.deliveries.list',
      'webhook.smoke-test.delete-resource-subscriptions',
      'webhook.smoke-test.deliver-payload',
      'webhook.webhooks.create',
      'webhook.webhooks.delete',
      'webhook.webhooks.get',
      'webhook.webhooks.list',
      'webhook.webhooks.update',
    ],
    lowest_applicable_resource_types: [],
  },
  {
    id: 'roles/viewer',
    version: 0,
    title: 'Viewer',
    description:
      'Can view existing resources. Cannot create new resources or edit existing ones. Cannot view the org IAM page.',
    permissions: [
      'billing.accounts.get',
      'billing.accounts.list',
      'billing.fcp.get-consumption-pool',
      'billing.fcp.list-consumption-pools',
      'billing.statements.get-statement',
      'billing.statements.list',
      'boundary.clusters.get',
      'boundary.clusters.get-update-deadline',
      'boundary.clusters.get-update-deadline-internal',
      'boundary.clusters.list',
      'boundary.clusters.maintenance-window-get',
      'boundary.sessions.list',
      'consul.clusters.get',
      'consul.clusters.get-telemetry',
      'consul.clusters.list',
      'consul.clusters.list-versions',
      'consul.snapshots.get',
      'consul.snapshots.list',
      'consul-telemetry-gateway.metrics.query',
      'consul-telemetry-gateway.service-topology.get',
      'consul-telemetry-gateway.user-is.viewer',
      'global-network-manager.consul-clusters.get',
      'global-network-manager.consul-clusters.get-api-information',
      'global-network-manager.consul-clusters.get-management-token-details',
      'global-network-manager.consul-clusters.get-secrets',
      'global-network-manager.consul-clusters.list',
      'global-network-manager.consul-clusters.list-partitions',
      'global-network-manager.consul-clusters.list-service-instances',
      'global-network-manager.consul-clusters.proxy-access',
      'global-network-manager.consul-clusters.ui',
      'global-network-manager.consul-clusters-servers.list',
      'global-network-manager.consul-observability.get-credentials',
      'global-network-manager.intentions.list',
      'global-network-manager.nodes.get',
      'global-network-manager.nodes.list',
      'global-network-manager.organizations.get-trial',
      'global-network-manager.peering-connections.get',
      'global-network-manager.peering-connections.list',
      'global-network-manager.peering-connections.list-clusters-eligibility',
      'global-network-manager.peering-connections.list-peers-by-cluster-partition',
      'global-network-manager.service-catalog.summary',
      'global-network-manager.services.get',
      'global-network-manager.services.list',
      'global-network-manager.user-is.viewer',
      'iam.auth-metadata.get',
      'iam.groups.count',
      'iam.groups.get',
      'iam.groups.list',
      'iam.groups.list-members',
      'iam.groups.member-count',
      'iam.invitations.get',
      'iam.invitations.list',
      'iam.principal.groups-count',
      'iam.sso-type.get',
      'iam.users.get',
      'iam.users.list',
      'link.nodes-status.get',
      'log.archive.get',
      'log.archive.list',
      'log.entries.get',
      'log.streaming.get',
      'log.streaming.list',
      'network.dependencies.list',
      'network.hvns.get',
      'network.hvns.list',
      'network.peerings.get',
      'network.peerings.list',
      'network.routes.get',
      'network.routes.list',
      'network.tgw-attachments.get',
      'network.tgw-attachments.list',
      'operation.operations.get',
      'operation.operations.list',
      'packer.bucket.get',
      'packer.bucket.list',
      'packer.channels.get',
      'packer.channels.list',
      'packer.images.get',
      'packer.images.list',
      'packer.iterations.get',
      'packer.iterations.list',
      'packer.registries.get',
      'packer.versions.get',
      'packer.versions.list',
      'resource-manager.organizations.get-role',
      'resource-manager.organizations.list-roles',
      'resource-manager.projects.get',
      'resource-manager.projects.get-iam-policy',
      'resource-manager.projects.list',
      'resource-manager.resources.list',
      'resource-manager.resources.list-roles',
      'secrets.app-secret-versions.list',
      'secrets.app-secret-versions.open',
      'secrets.app-secrets.get',
      'secrets.app-secrets.list',
      'secrets.app-secrets.open',
      'secrets.app-secrets-state.get',
      'secrets.apps.get',
      'secrets.apps.list',
      'secrets.gateway-pools.get',
      'secrets.gateway-pools.list',
      'secrets.usage.get',
      'vagrant.architecture.read',
      'vagrant.box.list',
      'vagrant.box.read',
      'vagrant.boxfile.download',
      'vagrant.boxfile.read',
      'vagrant.provider.list',
      'vagrant.provider.read',
      'vagrant.registry.list',
      'vagrant.registry.read',
      'vagrant.version.list',
      'vagrant.version.read',
      'vault.clusters.get',
      'vault.clusters.list',
      'vault.clusters.list-features',
      'vault.notifications.viewer',
      'vault.performancereplication.secondaries-list',
      'vault.performancereplication.status',
      'vault.plugin.is-registered',
      'vault.providers.get',
      'vault.snapshots.get',
      'vault.snapshots.list',
      'vault.templates.list',
      'vault-link.clusters.get',
      'vault-link.clusters.list',
      'vault-radar.admin-api-key-settings.get',
      'vault-radar.admin-api-key-settings.list',
      'vault-radar.admin-settings.get',
      'vault-radar.admin-settings.list',
      'vault-radar.agents.get',
      'vault-radar.agents.list',
      'vault-radar.alerts.get',
      'vault-radar.alerts.list',
      'vault-radar.build-scans.get',
      'vault-radar.build-scans.list',
      'vault-radar.checkpoints.get',
      'vault-radar.code-server-groups.get',
      'vault-radar.code-server-groups.list',
      'vault-radar.code-server-registrations.get',
      'vault-radar.code-server-registrations.list',
      'vault-radar.code-servers.get',
      'vault-radar.code-servers.list',
      'vault-radar.configs.get',
      'vault-radar.configs.list',
      'vault-radar.data-source-groups.get',
      'vault-radar.data-source-groups.list',
      'vault-radar.data-source-registrations.get',
      'vault-radar.data-source-registrations.list',
      'vault-radar.data-sources.get',
      'vault-radar.data-sources.list',
      'vault-radar.developers.get',
      'vault-radar.developers.list',
      'vault-radar.exports.get',
      'vault-radar.exports.list',
      'vault-radar.integrations.get',
      'vault-radar.integrations.list',
      'vault-radar.remediations.get',
      'vault-radar.remediations.list',
      'vault-radar.repos.get',
      'vault-radar.repos.list',
      'vault-radar.resources.get',
      'vault-radar.resources.list',
      'vault-radar.rules.get',
      'vault-radar.rules.list',
      'vault-radar.scan-targets.list',
      'vault-radar.scans.list',
      'vault-radar.task-definitions.get',
      'vault-radar.task-definitions.list',
      'vault-radar.users.can-view',
      'vault-scanning.deprecated-permission.get',
      'vault-secrets.app-secret-versions.list',
      'vault-secrets.app-secret-versions.open',
      'vault-secrets.app-secrets.get',
      'vault-secrets.app-secrets.list',
      'vault-secrets.app-secrets.open',
      'vault-secrets.app-secrets-state.get',
      'vault-secrets.apps.get',
      'vault-secrets.apps.list',
      'vault-secrets.usage.get',
      'waypoint.action.read',
      'waypoint.addondefinitions.read',
      'waypoint.addons.read',
      'waypoint.applications.read',
      'waypoint.auth-methods.read',
      'waypoint.configs.read',
      'waypoint.configsourcers.read',
      'waypoint.namespaces.get',
      'waypoint.namespaces.list',
      'waypoint.projectinputvariables.read',
      'waypoint.projects.read',
      'waypoint.templates.read',
      'waypoint.tfcconfig.read',
      'waypoint.users.read',
      'waypoint.variable.read',
      'webhook.deliveries.list',
      'webhook.webhooks.get',
      'webhook.webhooks.list',
    ],
    lowest_applicable_resource_types: [],
  },
  {
    id: 'roles/vault-radar.scan-target-viewer',
    version: 0,
    title: 'Scan-Target Viewer',
    description: 'Deprecated',
    permissions: [],
    lowest_applicable_resource_types: [],
  },
  {
    id: 'roles/iam.group-manager',
    version: 0,
    title: 'Group Manager',
    description: 'Group Manager can manage groups and group members.',
    permissions: [
      'iam.groups.get-iam-policy',
      'iam.groups.set-iam-policy',
      'iam.groups.update',
      'iam.groups.update-members',
    ],
    lowest_applicable_resource_types: [],
  },
  {
    id: 'roles/secrets.app-manager',
    version: 0,
    title: 'Vault Secrets App Manager',
    description:
      'This role is a collection of permissions that allows principals to create/read/update/delete secrets and add sync mappings inside a Vault Secrets app',
    permissions: [
      'resource-manager.projects.get',
      'resource-manager.projects.list',
      'secrets.app-secret-versions.list',
      'secrets.app-secret-versions.open',
      'secrets.app-secrets.create',
      'secrets.app-secrets.delete',
      'secrets.app-secrets.get',
      'secrets.app-secrets.list',
      'secrets.app-secrets.open',
      'secrets.app-secrets.rotate',
      'secrets.app-secrets.update',
      'secrets.app-secrets-state.get',
      'secrets.apps.create',
      'secrets.apps.delete',
      'secrets.apps.get',
      'secrets.apps.get-iam-policy',
      'secrets.apps.list',
      'secrets.apps.set-iam-policy',
      'secrets.apps.update',
      'secrets.integrations.get',
      'secrets.integrations.list',
      'secrets.rotating-mongodb-atlas.create',
      'secrets.rotating-twilio.create',
      'secrets.rotation-integrations.get',
      'secrets.rotation-integrations.list',
      'secrets.sync-installations.get',
      'secrets.sync-installations.list',
      'secrets.sync-integrations.force',
      'secrets.sync-integrations.get',
      'secrets.sync-integrations.list',
      'secrets.usage.get',
    ],
    lowest_applicable_resource_types: [],
  },
  {
    id: 'roles/secrets.app-secret-reader',
    version: 0,
    title: 'Vault Secrets App Secret Reader',
    description:
      'This role is a collection of permissions that allows principals to read secret values inside an Vault Secrets app',
    permissions: [
      'resource-manager.projects.get',
      'resource-manager.projects.list',
      'secrets.app-secret-versions.list',
      'secrets.app-secret-versions.open',
      'secrets.app-secrets.get',
      'secrets.app-secrets.list',
      'secrets.app-secrets.open',
      'secrets.app-secrets-state.get',
      'secrets.apps.get',
      'secrets.apps.list',
      'secrets.usage.get',
    ],
    lowest_applicable_resource_types: [],
  },
  {
    id: 'roles/vault-radar.resource-viewer',
    version: 0,
    title: 'Vault Radar Resource Viewer',
    description:
      "Vault Radar Resource Viewer can view alerts under select 'scan-targets' resources.",
    permissions: [
      'vault-radar.alerts.get',
      'vault-radar.alerts.list',
      'vault-radar.exports.get',
      'vault-radar.exports.list',
      'vault-radar.resources.get',
      'vault-radar.resources.list',
      'vault-radar.scan-targets.list',
    ],
    lowest_applicable_resource_types: [],
  },
  {
    id: 'roles/vault-radar.developer',
    version: 0,
    title: 'Vault Radar Developer',
    description:
      'Vault Radar Developer has minimal access to the event page at the project level.',
    permissions: [
      'resource-manager.projects.get',
      'resource-manager.projects.list',
      'vault-radar.configs.get',
      'vault-radar.configs.list',
      'vault-radar.remediations.get',
      'vault-radar.remediations.list',
      'vault-radar.users.can-view',
    ],
    lowest_applicable_resource_types: [],
  },
  {
    id: 'roles/vault-radar.resource-contributor',
    version: 0,
    title: 'Vault Radar Resource Contributor',
    description:
      "Vault Radar Resource Contributor can view and update alerts under select 'scan-targets' resources.",
    permissions: [
      'vault-radar.alerts.get',
      'vault-radar.alerts.list',
      'vault-radar.alerts.update',
      'vault-radar.exports.get',
      'vault-radar.exports.list',
      'vault-radar.resources.get',
      'vault-radar.resources.list',
      'vault-radar.scan-targets.list',
    ],
    lowest_applicable_resource_types: [],
  },
  {
    id: 'roles/resource-manager.browser',
    version: 0,
    title: 'Browser',
    description:
      'Allows browsing the resource hierarchy of the organization including listing and getting all projects. This does not allow accessing resources inside a project.',
    permissions: [
      'resource-manager.projects.get',
      'resource-manager.projects.list',
    ],
    lowest_applicable_resource_types: [],
  },
  {
    id: 'roles/resource-manager.project-iam-policies-admin',
    version: 0,
    title: 'Project IAM Policies Administrator',
    description: 'Allows performing CRUD operations on project policies.',
    permissions: [
      'resource-manager.projects.get-iam-policy',
      'resource-manager.projects.set-iam-policy',
    ],
    lowest_applicable_resource_types: ['hashicorp.resource-manager.project'],
  },
  {
    id: 'roles/billing.billing-admin',
    version: 0,
    title: 'Billing Administrator',
    description: 'Allows managing billing resources.',
    permissions: [
      'billing.accounts.assign-project',
      'billing.accounts.get',
      'billing.accounts.list',
      'billing.accounts.tokenize-payment-details',
      'billing.accounts.unassign-project',
      'billing.accounts.update',
      'billing.fcp.get-consumption-pool',
      'billing.fcp.list-consumption-pools',
      'billing.statements.get-statement',
      'billing.statements.list',
    ],
    lowest_applicable_resource_types: [],
  },
  {
    id: 'roles/vault-radar.agent',
    version: 0,
    title: 'Vault Radar Agent',
    description: 'Vault Radar Agent.',
    permissions: [
      'vault-radar.admin-settings.get',
      'vault-radar.admin-settings.list',
      'vault-radar.agents-jobs.list',
      'vault-radar.agents-jobs.update',
      'vault-radar.configs.get',
      'vault-radar.configs.list',
      'vault-radar.scan-results.create',
      'vault-radar.scans.update',
      'vault-radar.user-meters.create',
      'vault-radar.user-meters.update',
    ],
    lowest_applicable_resource_types: [],
  },
  {
    id: 'roles/vault-radar.cli-user',
    version: 0,
    title: 'Vault Radar CLI User',
    description:
      'Vault Radar CLI User has limited permission to run the Vault Radar CLI',
    permissions: [
      'vault-radar.admin-settings.get',
      'vault-radar.admin-settings.list',
      'vault-radar.configs.get',
      'vault-radar.configs.list',
      'vault-radar.resources.create',
      'vault-radar.resources.update',
      'vault-radar.scan-results.create',
      'vault-radar.scans.create',
      'vault-radar.scans.update',
      'vault-radar.user-meters.create',
      'vault-radar.user-meters.update',
    ],
    lowest_applicable_resource_types: [],
  },
  {
    id: 'roles/secrets.gateway-pools-reader',
    version: 0,
    title: 'Vault Secrets Gateway Pools Reader',
    description:
      'This role is a collection of permissions that allows principals to read gateway pools',
    permissions: [
      'resource-manager.projects.get',
      'resource-manager.projects.list',
      'secrets.gateway-pools.get',
      'secrets.gateway-pools.list',
    ],
    lowest_applicable_resource_types: [],
  },
  {
    id: 'roles/secrets.gateway-pools-manager',
    version: 0,
    title: 'Vault Secrets Gateway Pools Manager',
    description:
      'This role is a collection of permissions that allows principals to create/read/update/delete gateway pools',
    permissions: [
      'resource-manager.projects.get',
      'resource-manager.projects.list',
      'secrets.gateway-pools.create',
      'secrets.gateway-pools.delete',
      'secrets.gateway-pools.get',
      'secrets.gateway-pools.get-iam-policy',
      'secrets.gateway-pools.list',
      'secrets.gateway-pools.set-iam-policy',
      'secrets.gateway-pools.update',
    ],
    lowest_applicable_resource_types: [],
  },
];
