import baseScenario from './user';

const baseOptions = {
  createNetwork: false,
  createBilling: false,
  createConsul: false,
  createVault: false,
};

export default function (server, { options = {} } = {}) {
  baseScenario(server, { ...baseOptions, ...options });
}
