import { faker } from '@faker-js/faker';
import userScenario from './user';
import { TYPE_VAGRANT } from 'common/utils/cloud-resource-types';

export default function (server) {
  userScenario(server, {
    hasVerifiedEmail: true,
    createBilling: true,
  });

  // Make a tree of registries, boxes, versions, and providers that's big enough
  // to get some sense of how the UI works but not so hefty that it makes mirage
  // fall over w/ hundreds of leaf nodes.
  server.createList('vagrant.registry', 3);
  server.createList('vagrant.box', 9);

  const versions = server.schema['vagrant.versions'].all().models;
  versions.forEach((version) => {
    let numProviders = faker.number.int({ min: 1, max: 3 });
    server.createList('vagrant.provider', numProviders, {
      version,
    });
  });

  // Location links are used to create resource manager resources
  server.create('location.link', {
    id: 'vagrant-registry',
    type: TYPE_VAGRANT,
    location: server.schema.first('location.location'),
  });
}
