export default function getPlatformContext(server) {
  let user = server.schema.findBy('iam.user-principal', {
    current_user: true,
  });

  // fetch user owned organization
  let organization = server.schema.findBy('resource-manager.organization', {
    ownerId: user.id,
  });

  // if user has no owned organization (e.g. SSO users), return the first organization they have membership of
  if (organization === null) {
    let memberships = server.schema.where('iam.organization-membership', {
      principalId: user.id,
    });
    organization = memberships.models.map(
      ({ organization }) => organization
    )[0];
  }

  let project = server.schema.findBy('resource-manager.project', {
    parent: {
      type: 'ORGANIZATION',
      id: organization?.id,
    },
  });

  return { user, organization, project };
}
