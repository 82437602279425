import userScenario from './user';
const baseOptions = {
  createNetwork: true,
  createVault: true,
  createConsul: true,
  createBilling: true,
  hasVerifiedEmail: true,
  consulPrimaryFederatedCluster: false,
  consulSecondaryFederatedCluster: false,
};

export default function (server, { options = {} } = {}) {
  userScenario(server, { ...baseOptions, ...options });

  let defaultOrg = server.schema.findBy('resource-manager.organization', {
    name: 'default-org',
  });

  defaultOrg.update({
    name: 'this-is-a-very-long-org-name-with-no-spaces-yadda-yadda',
  });

  let consulCluster = server.schema.findBy('consul.cluster', {
    id: 'test-cluster',
  });
  consulCluster.update({
    id: 'test-cluster-super-long-name-no-spaces-long-long',
  });

  let hvn = server.schema.findBy('network.network', { id: 'test-network' });
  hvn.update({ id: 'test-network-name-that-is-long-no-spaces-just-long' });
}
