import { faker } from '@faker-js/faker';
import consulScenario from './consul';

export const NetworkIds = {
  azure: 'azure',
  awsSameRegionOverlappingCidr: 'same-region-overlapping-cidr',
  awsDifferentRegionOverlappingCidr: 'aws-different-region-overlapping-cidr',
  awsDifferentRegion: 'aws-different-region',
};

export default function (server, options = {}) {
  // Set up user scenario.
  consulScenario(server, options);

  // Consul scenario creates a single Standard/Small Consul cluster
  let defaultCluster = server.schema.first('consul.cluster');
  let networkId = defaultCluster.config.network_config.network.id;
  let networkLink = server.schema.findBy('location.link', { id: networkId });
  let network = server.schema.findBy('network.network', { id: networkId });
  let loc = server.schema.findBy('location.location', {
    organizationId: defaultCluster.location.organizationId,
    projectId: defaultCluster.location.projectId,
    regionId: defaultCluster.location.regionId,
  });

  // Dev/XSmall cluster
  server.create('consul.cluster', {
    id: 'dev-cluster',
    consul_version: 'v1.8.6',
    created_at: '2020-10-14T02:16:17.187Z',
    resourceId: faker.string.uuid(),
    config: {
      capacity_config: {
        size: 'X_SMALL',
      },
      network_config: {
        network: networkLink,
        private: false,
        ip_allowlist: [],
      },
      tier: 'DEVELOPMENT',
      primary: null,
    },
    dns_names: {
      public: `test-cluster.public.consul.${networkId}.aws.hcp.dev`,
      private: `test-cluster.private.consul.${networkId}.aws.hcp.dev`,
    },
    location: loc,
  });
  // Plus/Small cluster
  server.create('consul.cluster', {
    id: 'plus-cluster',
    consul_version: 'v1.8.6',
    created_at: '2020-10-14T02:16:17.187Z',
    resourceId: faker.string.uuid(),
    config: {
      capacity_config: {
        size: 'SMALL',
      },
      network_config: {
        network: networkLink,
        private: false,
        ip_allowlist: [],
      },
      tier: 'PLUS',
      primary: null,
    },
    dns_names: {
      public: `test-cluster.public.consul.${networkId}.aws.hcp.dev`,
      private: `test-cluster.private.consul.${networkId}.aws.hcp.dev`,
    },
    location: loc,
  });

  // Create HVNs in different regions/providers
  let project = server.schema.first('resource-manager.project');
  // Azure
  let azureRegion = server.create('location.region', {
    region: 'westus2',
    provider: 'azure',
  });
  let azureLocation = server.create('location.location', {
    organizationId: project.parent.id,
    projectId: project.id,
    regionId: azureRegion.id,
  });
  server.create('network.network', {
    id: NetworkIds.azure,
    location: azureLocation,
    // This CIDR is intentionally *not* an exact match of other CIDRs
    cidr_block: '172.20.15.0/21',
    created_at: '2020-10-14T03:16:17.187Z',
    updated_at: '2020-10-14T03:16:17.187Z',
  });
  // AWS - different region
  let awsDifferentRegion = server.create('location.region', {
    region: 'eu-west-1',
    provider: 'aws',
  });
  let awsDifferentRegionLocation = server.create('location.location', {
    organizationId: project.parent.id,
    projectId: project.id,
    regionId: awsDifferentRegion.id,
  });
  // AWS - different region, no overlapping CIDR
  server.create('network.network', {
    id: NetworkIds.awsDifferentRegion,
    location: awsDifferentRegionLocation,
    cidr_block: '255.255.255.240/24',
    created_at: '2020-10-14T03:16:17.187Z',
    updated_at: '2020-10-14T03:16:17.187Z',
  });
  // AWS - different region, overlapping CIDR
  server.create('network.network', {
    id: NetworkIds.awsDifferentRegionOverlappingCidr,
    location: awsDifferentRegionLocation,
    // This CIDR is intentionally *not* an exact match of other CIDRs
    cidr_block: '172.20.15.0/22',
    created_at: '2020-10-14T03:16:17.187Z',
    updated_at: '2020-10-14T03:16:17.187Z',
  });
  // AWS - same region, overlapping CIDR
  server.create('network.network', {
    id: NetworkIds.awsSameRegionOverlappingCidr,
    location: loc,
    // This CIDR is intentionally an exact match of default scenario hvn CIDR
    cidr_block: network.cidr_block,
    created_at: '2020-10-14T03:16:17.187Z',
    updated_at: '2020-10-14T03:16:17.187Z',
  });
}
