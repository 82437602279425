import baseScenario from './user';
import { viewerPermissions } from '../helpers/permissions';
import { STATUSPAGE_IO_STATUS } from 'core/utils/constants';
import { BILLING_ACCOUNT_STATUS } from 'billing-common/constants/status';

export default function (server, options = {}) {
  baseScenario(server, {
    systemStatus: {
      hcp: STATUSPAGE_IO_STATUS.OPERATIONAL,
      consul: STATUSPAGE_IO_STATUS.OPERATIONAL,
      packer: STATUSPAGE_IO_STATUS.OPERATIONAL,
      vault: STATUSPAGE_IO_STATUS.OPERATIONAL,
    },
    billingRemainingBalance: undefined,
    createConsul: true,
    consulPackages: {
      development: true,
      standard: true,
      plus: true,
    },
    consulPrimaryFederatedCluster: false,
    consulSecondaryFederatedCluster: false,
    entitlementPricingModel: false,
    createNetwork: true,
    createPeeringConnection: {
      state: 'PENDING_ACCEPTANCE',
      hvnRoutes: true,
    },
    createTgwAttachment: {
      state: 'PENDING_ACCEPTANCE',
      hvnRoutes: false,
    },
    // this defines what clusters exist when you boot the app
    createVault: {
      development: true,
      starter: true,
    },
    createBilling: true,
    createGroups: true,
    hasNoOwnedOrg: false,
    hasVerifiedEmail: false,
    billingAccountStatus: BILLING_ACCOUNT_STATUS.TRIAL,
    onDemandBillingMethodDetails: false,
    // this defines what vault tier packages are available
    vaultPackages: {
      development: true,
      standard: true,
      starter: true,
    },
    allowedPermissions: viewerPermissions,
    ...options,
  });
}
