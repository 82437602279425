import { faker } from '@faker-js/faker';

let awsAccountId = faker.number.int({
  min: 10000000000000000,
  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision, no-loss-of-precision
  max: 99999999999999999,
});
let awsConditionalAccess = `"aws.arn matches "arn:aws:sts::${awsAccountId}:assumed-role/remote_personal_dev_workspaceName01*"`;
export const PROVIDER_DETAILS = [
  {
    type: 'oidc',
    provider: 'azure',
    name: 'my-app',
    description: 'Allow access from Azure Managed App',
    config: {
      oidc_config: {
        issuer_uri:
          'https://sts.windows.net/6222e6c3-738c-46cf-a67d-d244844288fc',
        allowed_audiences: ['api://066c643f-86c0-490a-854c-35e77ddc7851'],
      },
    },
    conditional_access:
      'jwt_claims.oid is `066c643f-86c0-490a-854c-35e77ddc7851`',
  },
  {
    type: 'oidc',
    name: 'gcp',
    description: 'Allow access from GCP Service Account',
    config: {
      oidc_config: {
        issuer_uri: 'https://accounts.google.com',
      },
    },
    conditional_access: 'jwt_token.sub is `107517467455664443765`',
  },
  {
    type: 'oidc',
    name: 'gitlab',
    description: 'Allow access from GitLab repo',
    config: {
      oidc_config: {
        issuer_uri: 'https://gitlab.com',
        allowed_audiences: ['https://gitlab.com'],
      },
    },
    conditional_access:
      'jwt_token.sub matches `^project_path:my-org/my-backend`',
  },
  {
    type: 'oidc',
    name: 'github',
    description: 'Allow access from GitHub repo',
    config: {
      oidc_config: {
        issuer_uri: 'https://token.actions.githubusercontent.com',
      },
    },
    conditional_access:
      'jwt_token.repository is `my-org/my-backend` and jwt_token.workflow is `deploy-container`',
  },
  {
    type: 'aws',
    name: 'aws',
    config: {
      aws_config: {
        account_id: awsAccountId,
      },
    },
    conditional_access: awsConditionalAccess,
  },
];
