import userScenario from './user';
import { fullPermissions } from '../helpers/permissions';
import {
  BILLING_ACCOUNT_STATUS,
  BILLING_ON_DEMAND_STATUS,
} from 'billing-common/constants/status';

/**
 * The default vault scenario which should cover the most common testing needs. This includes:
 * - 1 dev, standard, starter, and plus cluster on an aws network
 * - 1 dev cluster on an azure network
 * - 1 replication secondary cluster
 * - 4 aws networks
 * - 1 azure network
 *  */

export default function (server, options = {}) {
  userScenario(server, {
    // vault-specific items
    createVault: {
      development: true,
      standard: true,
      starter: true,
      plus: true,
    },
    vaultPackages: {
      development: true,
      standard: true,
      starter: true,
      plus: true,
    },

    // billing
    createBilling: true,
    hasVerifiedEmail: true,
    billingAccountStatus: BILLING_ACCOUNT_STATUS.ACTIVE,
    onDemandBillingMethodDetails: true,
    billingAccountOnDemandStatus: BILLING_ON_DEMAND_STATUS.ACTIVE,

    // misc
    createConsul: false,
    allowedPermissions: fullPermissions,
    ...options,
  });

  // NETWORKS
  // set up 2 additional AWS networks and an azure network

  // first grab the pre-existing aws network
  const { schema } = server;
  const loc = schema.first('location.location');
  const network = schema.findBy('network.network', { id: 'test-network' });

  // create an aws network with a cidr collision
  server.create('network.network', {
    id: 'network-with-cidr-collision',
    cidr_block: network.cidr_block,
    location: loc,
  });

  const project = server.schema.first('resource-manager.project');

  const usEast = server.create('location.region', {
    region: 'us-east-1',
    provider: 'aws',
  });

  const usEastLoc = server.create('location.location', {
    organizationId: project.parent.id,
    projectId: project.id,
    regionId: usEast.id,
  });

  // create an aws network without a cidr collision
  const backupNetwork = server.create('network.network', {
    id: 'no-cidr-collision',
    cidr_block: '172.25.20.0/20',
    location: usEastLoc,
  });

  const plusCluster = server.schema.findBy('vault.cluster', ({ config }) => {
    return config.tier.includes('PLUS');
  });

  if (plusCluster) {
    plusCluster.update({
      config: {
        ...plusCluster.config,
        disaster_recovery_network_config: {
          network_id: backupNetwork.id,
          location: {
            organization: backupNetwork.location.organizationId,
            projectId: backupNetwork.location.projectId,
            region: {
              provider: backupNetwork.location.region.provider,
              region: backupNetwork.location.region.region,
            },
          },
        },
      },
    });
  }

  // set up region & location for azure network
  const azureRegion = server.create('location.region', {
    region: 'westus2',
    provider: 'azure',
  });

  const location = server.create('location.location', {
    organizationId: project.parent.id,
    projectId: project.id,
    regionId: azureRegion.id,
  });

  // create azure network
  const azureNetworkId = 'azure-network';
  server.create('network.network', {
    id: azureNetworkId,
    location,
    cidr_block: '172.20.15.0/20',
  });

  // CLUSTERS
  // create an azure cluster as long as we aren't intentionally trying
  // to set up a test with 0 clusters
  if (options.createVault !== false) {
    server.create('vault.cluster', 'development', {
      id: 'azure-dev-cluster',
      location: location,
      $network_id: azureNetworkId,
    });
  }
}
