import billingEntitlement from './billing-entitlement';
import { BILLING_ON_DEMAND_STATUS } from 'billing-common/constants/status';

let options = {
  onDemandBillingMethodDetails: true,
  billingAccountOnDemandStatus: BILLING_ON_DEMAND_STATUS.ACTIVE,
};

export default function (server) {
  return billingEntitlement(server, options);
}
