import baseScenario from './user';
import { TYPE_NETWORK } from 'common/utils/cloud-resource-types';
import { HashicorpCloudNetwork20200907NetworkState } from '@clients/cloud-network';
const baseOptions = {
  createNetwork: false,
  createVault: false,
  createBilling: true,
  hasVerifiedEmail: true,
  createConsul: true,
  consulPrimaryFederatedCluster: false,
  consulSecondaryFederatedCluster: false,
  createPeeringConnection: null,
  createTgwAttachment: null,
};

import { RegionProviders } from 'core/utils/regions';
export const azureHvnId = `${RegionProviders.azure}-hvn`;
export default function (server, { options = {} } = {}) {
  /**
   * This scenario creates an Azure network with no Peering connections
   * 1.  Azure HVN ("azure-hvn")
   */

  baseScenario(server, { ...baseOptions, ...options });

  let project = server.schema.first('resource-manager.project');
  let providerLocation;
  let providerRegion;

  providerRegion = server.create('location.region', {
    region: 'westus2',
    provider: RegionProviders.azure,
  });
  providerLocation = server.create('location.location', {
    organizationId: project.parent.id,
    projectId: project.id,
    regionId: providerRegion.id,
  });

  server.create('network.network', {
    id: azureHvnId,
    state: HashicorpCloudNetwork20200907NetworkState.STABLE,
    cidr_block: '10.10.0.0/20',
    location: providerLocation,
  });
  server.create('location.link', {
    id: azureHvnId,
    type: TYPE_NETWORK,
    description: `HashiCorp Virtual Network ("${azureHvnId}")`,
    location: providerLocation,
  });
}
