// @ts-nocheck
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { dropTask, task } from 'ember-concurrency';
import { DateTime } from 'luxon';

import type ApiService from 'api/services/api';

interface MirageDevToolsConsulSignature {
  // Args: {};
}

export default class MirageDevToolsConsulComponent extends Component<MirageDevToolsConsulSignature> {
  @service declare readonly api: ApiService;

  @tracked clusterIdForServers = '';
  @tracked clusterIdForService = '';
  @tracked serviceName = '';
  @tracked clusterIdForVersionUpdate = '';
  @tracked consulVersion = '';
  @tracked updateConsulVersionSuccess = null;
  @tracked updateConsulVersionError = null;
  @tracked startServersError = null;
  @tracked serverStartSuccess = null;
  @tracked serviceStartSuccess = null;
  @tracked startServiceError = null;

  get defaultStartServersError() {
    return `Error starting servers for cluster ${this.clusterIdForServers}`;
  }

  get defaultStartServiceError() {
    return `Error starting service ${this.serviceName} on cluster ${this.clusterIdForService}`;
  }

  get defaultUpdateConsulVersionError() {
    return `Error updating cluster ${this.clusterIdForVersionUpdate} to Consul version ${this.consulVersion}`;
  }

  @action
  onClusterIdForServersChange(e) {
    this.clusterIdForServers = e.target.value;
  }

  @action
  onClusterIdForServiceChange(e) {
    this.clusterIdForService = e.target.value;
  }

  @action
  onServiceNameChange(e) {
    this.serviceName = e.target.value;
  }

  @action
  onConsulVersionChange(e) {
    this.consulVersion = e.target.value;
  }

  @action
  onClusterIdForVersionUpdateChange(e) {
    this.clusterIdForVersionUpdate = e.target.value;
  }

  updateConsulVersion = task({ drop: true }, async (evt) => {
    evt.preventDefault();
    this.updateConsulVersionSuccess = null;
    this.updateConsulVersionError = null;
    const mirage = window.mirage;
    const cluster = mirage.schema['consulGlobalNetworkManager.clusters'].find(
      this.clusterIdForVersionUpdate
    );
    if (!cluster) {
      this.updateConsulVersionError = `Did not find cluster with ID ${this.clusterIdForVersionUpdate}`;
      return;
    }
    if (!this.consulVersion) {
      this.updateConsulVersionError = `Consul version is required`;
      return;
    }

    await cluster.update({
      consul_version: this.consulVersion,
    });

    const hcpCluster = mirage.schema['consul.clusters'].find(
      this.clusterIdForVersionUpdate
    );
    if (hcpCluster) {
      await cluster.update({
        consul_version: this.consulVersion,
      });
    }

    this.updateConsulVersionSuccess = `Updated cluster ${this.clusterIdForVersionUpdate} to Consul version ${this.consulVersion}`;
    this.clusterIdForVersionUpdate = '';
    this.consulVersion = '';
  });

  @dropTask
  *startConsulService() {
    this.serviceStartSuccess = null;
    this.startServiceError = null;
    const mirage = window.mirage;
    const cluster = mirage.schema['consulGlobalNetworkManager.clusters'].find(
      this.clusterIdForService
    );
    if (!cluster) {
      this.startServersError = `Did not find cluster with ID ${this.clusterIdForService}`;
      return;
    }

    const service = {
      name: this.serviceName,
      cluster: cluster,
      partition: 'partition',
      namespace: 'namespace',
      passing: 5,
      warning: 3,
      critical: 2,
    };
    const total = service.passing + service.warning + service.critical;

    yield mirage.schema['consulGlobalNetworkManager.cluster.services'].create({
      ...service,
      total,
    });

    this.serviceStartSuccess = `Service ${this.serviceName} started on cluster ${this.clusterIdForService}`;
    this.clusterIdForService = '';
    this.serviceName = '';
  }

  @dropTask
  *startConsulServers() {
    this.serverStartSuccess = null;
    this.startServersError = null;
    const mirage = window.mirage;
    const cluster = mirage.schema['consulGlobalNetworkManager.clusters'].find(
      this.clusterIdForServers
    );
    if (!cluster) {
      this.startServersError = `Did not find cluster with ID ${this.clusterIdForServers}`;
      return;
    }

    yield mirage.schema['consulGlobalNetworkManager.cluster.servers'].create({
      id: `${this.clusterIdForServers}-server`,
      cluster: cluster,
      lan_address: 'x.x.x.x',
      gossip_port: 8301,
      rpc_port: 8502,
      version: 'v1.13.0',
      failure_tolerance: 2,
      tls_enabled: true,
      tls_expiry: DateTime.now().plus({ months: 6 }).toISO(),
    });

    this.serverStartSuccess = `Server for cluster ${this.clusterIdForServers} started`;
    this.clusterIdForServers = '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MirageDevTools::Consul': typeof MirageDevToolsConsulComponent;
    'mirage-dev-tools/consul': typeof MirageDevToolsConsulComponent;
  }
}
