import { getOwner } from '@ember/owner';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface DevToolsSignature {
  // eslint-disable-next-line @typescript-eslint/ban-types
  Args: {};
}

export default class DevToolsComponent extends Component<DevToolsSignature> {
  @tracked showModal = false;

  get destinationElement() {
    return document.body;
  }

  get config() {
    return getOwner(this)?.lookup('service:config');
  }

  @action
  toggleModal(openVal: boolean) {
    this.showModal = openVal ?? !this.showModal;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    DevTools: typeof DevToolsComponent;
    'dev-tools': typeof DevToolsComponent;
  }
}
