import baseScenario from './user';
import { BILLING_ACCOUNT_STATUS } from 'billing-common/constants/status';
import getPlatformContext from '../helpers/get-platform-context';

let options = {
  createBilling: true,
  billingAccountStatus: BILLING_ACCOUNT_STATUS.TRIAL,
};

export default function (server) {
  baseScenario(server, options);

  let { organization, project } = getPlatformContext(server);

  let billingAccount = server.schema.findBy('billing-account.billing-account', {
    organizationId: organization.id,
    projectIds: [project.id],
  });

  server.create('billing-account.contract', 'upcoming', {
    organization,
    billing_account: billingAccount,
  });

  server.create('billing-account.transition', 'upcoming', 'flexTransition', {
    billing_account: billingAccount,
  });
}
