import userScenario from './user';
import { PROVIDER_DETAILS } from '../helpers/workload-identity-provider';
export default function (
  server,
  options = {
    createWorkloadIdentityProviders: true,
  }
) {
  userScenario(server, {
    createBilling: true,
    createServicePrincipals: { project: true },
  });

  let project = server.schema.first('resource-manager.project');

  // WorkloadIdentityProviders are only at the Project level
  let projectServicePrincipalOne = server.schema.findBy(
    'service-principal.service-principal',
    { projectId: project.id }
  );

  let { projectId, name } = projectServicePrincipalOne;
  if (options.createWorkloadIdentityProviders) {
    PROVIDER_DETAILS.map((provider) => {
      /**
       * Create a wip for the following providers:
       * AWS, Azure, GCP, GitHub, and GitLab
       */
      server.create('service-principal.service-principal-wip', {
        $servicePrincipalName: name,
        $projectId: projectId,
        $provider: provider,
      });
    });
    /**
     * Create a generic OIDC provider -
     * openid-color icon and OIDC label renders in list view
     */
    server.create('service-principal.service-principal-wip', {
      $servicePrincipalName: name,
      $projectId: projectId,
      $provider: {
        type: 'oidc',
        provider: 'kubernetes',
        name: 'kubernetes-app',
        description: 'Allow access from Kubernetes managed app',
        config: {
          oidc_config: {
            issuer_uri: 'https://kubernetes.issuerUri',
          },
        },
      },
    });
  }
}
