import secretsScenario from './secrets';
import { faker } from '@faker-js/faker';

export default function (server, options = {}) {
  secretsScenario(server, {
    hvsBillingTier: 'STANDARD',
    ...options,
  });

  const firstOrg = server.schema.first('resourceManager.organization');
  const firstProj = server.schema.first('resourceManager.project');

  // Add more integrations
  const gcpSmOne = server.create('secrets.sync-integration', 'gcp-sm', {
    $orgId: firstOrg.id,
    $projId: firstProj.id,
    name: 'gcp-sm',
    location: { organization_id: firstOrg.id, project_id: firstProj.id },
  });

  const tfcOrg = faker.company.buzzNoun().toLowerCase().replaceAll(' ', '-');
  const tfcTeam = faker.company.buzzVerb().toLowerCase().replaceAll(' ', '-');

  const tfcInstallation = server.create('secrets.sync-installation', {
    $orgId: firstOrg.id,
    $projId: firstProj.id,
    location: { organization_id: firstOrg.id, project_id: firstProj.id },
    type: 'hcp-terraform',
    name: `${tfcOrg}-${tfcTeam}`,
    hcp_terraform: {
      org: tfcOrg,
      team: tfcTeam,
    },
  });

  const tfcOne = server.create('secrets.sync-integration', 'hcp-terraform', {
    $orgId: firstOrg.id,
    $projId: firstProj.id,
    name: 'terraform-one',
    location: { organization_id: firstOrg.id, project_id: firstProj.id },
    hcp_terraform_connection_details: {
      installation_name: tfcInstallation.name,
    },
  });

  const app = server.schema.findBy('secrets.app', {
    name: 'app-0',
  });

  app.update({
    sync_integrations: [...app.sync_integrations, gcpSmOne.name, tfcOne.name],
  });
}
