import {
  ACTION_CREATE,
  ACTION_DELETE,
  ACTION_FORCE,
  ACTION_GET,
  ACTION_LIST,
  ACTION_UPDATE,
  ACTION_OPEN,
  ACTION_READ,
} from './base';

export const PREFIX_VAULT_SECRETS = 'secrets';

export const SECRETS_APP = 'apps';
export const SECRETS_APP_SECRETS = 'app-secrets';
export const SECRETS_APP_SECRETS_STATE = 'app-secrets-state';
export const SECRETS_APP_SECRETS_ROTATING_TWILIO = 'rotating-twilio';
export const SECRETS_APP_SECRETS_ROTATING_MONGODB = 'rotating-mongodb-atlas';
export const SECRETS_APP_SECRET_VERSIONS = 'app-secret-versions';
export const SECRETS_BILLING = 'billing';
export const SECRETS_INTEGRATIONS = 'integrations';
export const SECRETS_ROTATION_INTEGRATIONS = 'rotation-integrations';
export const SECRETS_SYNC_INSTALLATIONS = 'sync-installations';
export const SECRETS_SYNC_INTEGRATIONS = 'sync-integrations';
export const SECRETS_USAGE = 'usage';

export const ACTION_ROTATE = 'rotate';

export const SECRETS_ALL_PERMISSIONS = [
  // apps
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}.${ACTION_CREATE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}.${ACTION_DELETE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}.${ACTION_GET}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}.${ACTION_LIST}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}.${ACTION_UPDATE}`,

  // default secrets
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_CREATE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_DELETE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_GET}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_LIST}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_OPEN}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_UPDATE}`,

  // rotating secrets
  // generic
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS_STATE}.${ACTION_GET}`,
  // twilio
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS_ROTATING_TWILIO}.${ACTION_CREATE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS_ROTATING_TWILIO}.${ACTION_ROTATE}`,
  // mongodb-atlas
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS_ROTATING_MONGODB}.${ACTION_CREATE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS_ROTATING_MONGODB}.${ACTION_ROTATE}`,

  // secret versions
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRET_VERSIONS}.${ACTION_LIST}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRET_VERSIONS}.${ACTION_OPEN}`,

  // rotation integrations
  `${PREFIX_VAULT_SECRETS}.${SECRETS_ROTATION_INTEGRATIONS}.${ACTION_LIST}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_ROTATION_INTEGRATIONS}.${ACTION_GET}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_ROTATION_INTEGRATIONS}.${ACTION_CREATE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_ROTATION_INTEGRATIONS}.${ACTION_DELETE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_ROTATION_INTEGRATIONS}.${ACTION_UPDATE}`,

  // sync installations
  `${PREFIX_VAULT_SECRETS}.${SECRETS_SYNC_INSTALLATIONS}.${ACTION_GET}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_SYNC_INSTALLATIONS}.${ACTION_CREATE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_SYNC_INSTALLATIONS}.${ACTION_DELETE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_SYNC_INSTALLATIONS}.${ACTION_UPDATE}`,

  // sync integrations
  `${PREFIX_VAULT_SECRETS}.${SECRETS_SYNC_INTEGRATIONS}.${ACTION_CREATE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_SYNC_INTEGRATIONS}.${ACTION_DELETE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_SYNC_INTEGRATIONS}.${ACTION_GET}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_SYNC_INTEGRATIONS}.${ACTION_LIST}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_SYNC_INTEGRATIONS}.${ACTION_UPDATE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_SYNC_INTEGRATIONS}.${ACTION_FORCE}`,

  // "generic" integrations
  `${PREFIX_VAULT_SECRETS}.${SECRETS_INTEGRATIONS}.${ACTION_CREATE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_INTEGRATIONS}.${ACTION_DELETE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_INTEGRATIONS}.${ACTION_UPDATE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_INTEGRATIONS}.${ACTION_GET}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_INTEGRATIONS}.${ACTION_LIST}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_USAGE}.${ACTION_GET}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_BILLING}.${ACTION_UPDATE}`,
];

export const SECRETS_OWNER_ROLE_PERMISSIONS = [...SECRETS_ALL_PERMISSIONS];

export const SECRETS_ADMIN_ROLE_PERMISSIONS = [...SECRETS_ALL_PERMISSIONS];

export const SECRETS_CONTRIBUTOR_ROLE_PERMISSIONS = [
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}.${ACTION_CREATE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}.${ACTION_DELETE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}.${ACTION_GET}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}.${ACTION_LIST}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}.${ACTION_UPDATE}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_CREATE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_DELETE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_GET}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_LIST}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_OPEN}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_UPDATE}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS_STATE}.${ACTION_GET}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS_ROTATING_TWILIO}.${ACTION_CREATE}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRET_VERSIONS}.${ACTION_LIST}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRET_VERSIONS}.${ACTION_OPEN}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_ROTATION_INTEGRATIONS}.${ACTION_LIST}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_ROTATION_INTEGRATIONS}.${ACTION_GET}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_SYNC_INSTALLATIONS}.${ACTION_GET}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_SYNC_INTEGRATIONS}.${ACTION_GET}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_SYNC_INTEGRATIONS}.${ACTION_LIST}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_USAGE}.${ACTION_GET}`,
];

export const SECRETS_VIEWER_ROLE_PERMISSIONS = [
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}.${ACTION_GET}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}.${ACTION_LIST}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_GET}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_LIST}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_OPEN}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS_STATE}.${ACTION_GET}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRET_VERSIONS}.${ACTION_LIST}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRET_VERSIONS}.${ACTION_OPEN}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_USAGE}.${ACTION_GET}`,
];

// HVS specific roles

export const SECRETS_APP_MANAGER_ROLE_PERMISSIONS = [
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}.${ACTION_CREATE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}.${ACTION_DELETE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}.${ACTION_GET}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}.${ACTION_LIST}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}.${ACTION_UPDATE}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_CREATE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_UPDATE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_DELETE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_ROTATE}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_OPEN}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_GET}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_LIST}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS_ROTATING_TWILIO}.${ACTION_CREATE}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRET_VERSIONS}.${ACTION_LIST}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRET_VERSIONS}.${ACTION_OPEN}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS_STATE}.${ACTION_GET}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_SYNC_INTEGRATIONS}.${ACTION_LIST}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_INTEGRATIONS}.${ACTION_GET}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_INTEGRATIONS}.${ACTION_LIST}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_SYNC_INSTALLATIONS}.${ACTION_GET}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_ROTATION_INTEGRATIONS}.${ACTION_LIST}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_ROTATION_INTEGRATIONS}.${ACTION_READ}`,
];

export const SECRETS_SECRET_READER_ROLE_PERMISSIONS = [
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}.${ACTION_GET}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}.${ACTION_LIST}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_GET}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_LIST}`,
  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS}.${ACTION_OPEN}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRET_VERSIONS}.${ACTION_LIST}`,

  `${PREFIX_VAULT_SECRETS}.${SECRETS_APP_SECRETS_STATE}.${ACTION_GET}`,
];
