import type PrincipalsService from '../services/principals';

type MapType<T> = Map<string, T>;

export default function iamGetItemFromMap<T>(
  map: PrincipalsService | MapType<T> | undefined,
  { key }: { key?: string },
): T | undefined {
  if (!map || !key) {
    return undefined;
  }

  // Assuming PrincipalsService has a get method similar to Map
  if ('get' in map && typeof map.get === 'function') {
    return map.get(key) as T | undefined;
  }

  return undefined;
}
