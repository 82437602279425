import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isTesting } from '@embroider/macros';

import type EnginesRouterService from 'ember-engines-router-service/services/router';
import type CurrentUserService from '../services/current-user.ts';
import type UserContextService from '../services/user-context.ts';

interface HcpAppFrameSignature {
  Args: {
    includeHomeLink: unknown;
    showStatuspageLink: unknown;
  };
  Blocks: {
    default: [];
  };
}

/**
 *
 * `HcpAppFrame` uses HDS HTML layout structure to wrap content.
 *
 *
 * ```
 * <HcpAppFrame>{{outlet}}</HcpAppFrame>
 * ```
 *
 * @class HcpAppFrame
 *
 */

export default class HcpAppFrameComponent extends Component<HcpAppFrameSignature> {
  @service declare readonly currentUser: CurrentUserService;
  @service declare readonly router: EnginesRouterService;
  @service declare readonly userContext: UserContextService;
  // @ts-expect-error
  @service declare readonly systemStatus;

  isTesting = isTesting();
  year = new Date().getFullYear();

  get includeHomeLink() {
    return this.args.includeHomeLink ?? true;
  }

  get currentOrg() {
    return this.userContext?.organization;
  }

  get currentProject() {
    return this.userContext?.project;
  }

  get organizations() {
    return this?.currentUser?.organizations ?? [];
  }

  get projects() {
    return this?.userContext?.projects ?? [];
  }

  get projectsNextPageToken() {
    return this?.userContext?._projectsNextPageToken;
  }

  get hideUserNav() {
    if (this.router.currentRouteName === 'cloud.orgs.create.index') {
      return this.organizations.length <= 0;
    }

    if (
      this.router.currentRouteName === 'cloud.orgs.detail.hcp-with-terraform'
    ) {
      return true;
    }

    return false;
  }

  get statuspageUrl() {
    return this.systemStatus.statuspageUrl;
  }

  get hcpStatus() {
    return this.systemStatus.hcp;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    HcpAppFrame: typeof HcpAppFrameComponent;
    'hcp-app-frame': typeof HcpAppFrameComponent;
  }
}
