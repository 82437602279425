import userScenario from './user';
import getPlatformContext from '../helpers/get-platform-context';

let options = {
  createBilling: true,
};

export default function (server) {
  userScenario(server, options);

  let { organization, project } = getPlatformContext(server);

  let billingAccount = server.schema.findBy('billing-account.billing-account', {
    organizationId: organization.id,
    projectIds: [project.id],
  });

  server.create('billing-account.contract', 'expired', 'zeroBalance', {
    organization,
    billing_account: billingAccount,
  });

  server.create('billing-account.contract', 'upcoming', {
    organization,
    billing_account: billingAccount,
  });

  server.create('billing-account.flexible-consumption-billing-method-detail', {
    billing_account: billingAccount,
  });
}
