import { TYPE_VAULT_SECRETS_APP } from 'common/utils/cloud-resource-types';

import type { RoleDefinitionService } from '../../../types/role-definitions-flyout';

const roleDefinitionFlyoutTranslationPath =
  'manage-access.components.flyout.role-definitions-flyout.resource';

const SECRETS_SERVICES: Array<RoleDefinitionService> = [
  {
    id: 'secrets',
    title: `${roleDefinitionFlyoutTranslationPath}.secrets.vault-secrets-application`,
    categories: [
      {
        title: `${roleDefinitionFlyoutTranslationPath}.secrets.categories.vault-secrets-ability`,
        actions: [
          {
            title: `${roleDefinitionFlyoutTranslationPath}.secrets.categories.actions.read-secrets`,
            permissions: ['secrets.app-secrets.list', 'secrets.apps.list'],
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.secrets.categories.actions.create-edit-delete-secrets`,
            permissions: [
              'secrets.app-secrets.create',
              'secrets.app-secrets.update',
              'secrets.app-secrets.delete',
            ],
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.secrets.categories.actions.create-edit-delete-applications`,
            permissions: [
              'secrets.apps.create',
              'secrets.apps.update',
              'secrets.apps.delete',
            ],
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.secrets.categories.actions.create-edit-delete-service-principals`,
            permissions: ['iam.service-principals.update'],
          },
        ],
        columnRoleIds: [
          'roles/secrets.app-manager',
          'roles/secrets.app-secret-reader',
        ],
      },
    ],
  },
];

export const RESOURCE_SERVICES: Record<string, Array<RoleDefinitionService>> = {
  [TYPE_VAULT_SECRETS_APP]: SECRETS_SERVICES,
};
