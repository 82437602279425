import userScenario from './user';
import { PRICING_MODEL } from '../consts/billing';

export default function (server, options) {
  let opts = {
    createBilling: true,
    pricingModel: PRICING_MODEL.ENTITLEMENT,
    ...options,
  };

  return userScenario(server, opts);
}
