/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudWaypointTFModuleVariableFromJSON(json) {
    return HashicorpCloudWaypointTFModuleVariableFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointTFModuleVariableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'variableType': !exists(json, 'variable_type') ? undefined : json['variable_type'],
        'options': !exists(json, 'options') ? undefined : json['options'],
        'userEditable': !exists(json, 'user_editable') ? undefined : json['user_editable'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}
export function HashicorpCloudWaypointTFModuleVariableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'variable_type': value.variableType,
        'options': value.options,
        'user_editable': value.userEditable,
        'description': value.description,
    };
}
