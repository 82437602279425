/**
 * TODO: createVaultPackages should be replaced with a mirage Factory trait once `billing.package` factories are in place.
 */

// Mock the cloud-billing API to create a Vault standard or development package
export function createVaultPackages(server, { packageType = 'standard' }) {
  switch (packageType) {
    case 'development':
      return server.create('billing.package', {
        key: 'hashicorp.vault.development',
        resource_type: 'hashicorp.vault.cluster',
        items: [
          {
            key: 'hashicorp.billing.dimension.size',
            optional: false,
            options: [
              {
                unit: 'HOUR',
                unit_price: '1',
                key: 'extra-small',
                dependencies: [],
              },
            ],
          },
          {
            key: 'hashicorp.vault.dimension.num_clients',
            optional: false,
            options: [
              {
                unit: 'HOUR',
                unit_price: '0.01',
                key: 'num_clients_extra_small',
                dependencies: [],
              },
            ],
          },
        ],
      });
    case 'standard':
      return server.create('billing.package', {
        key: 'hashicorp.vault.standard',
        name: '',
        resource_type: 'hashicorp.vault.cluster',
        items: [
          {
            key: 'hashicorp.billing.dimension.size',
            optional: false,
            options: [
              {
                unit: 'HOUR',
                unit_price: '2',
                key: 'small',
                dependencies: [],
              },
              {
                unit: 'HOUR',
                unit_price: '4',
                key: 'medium',
                dependencies: [],
              },
              {
                unit: 'HOUR',
                unit_price: '8',
                key: 'large',
                dependencies: [],
              },
            ],
          },
          {
            key: 'hashicorp.vault.dimension.num_clients',
            optional: false,
            options: [
              {
                unit: 'HOUR',
                unit_price: '0.158',
                key: 'num_clients_small',
                dependencies: [
                  {
                    item_key: 'hashicorp.billing.dimension.size',
                    option_key: 'small',
                  },
                ],
              },
              {
                unit: 'HOUR',
                unit_price: '0.127',
                key: 'num_clients_medium',
                dependencies: [
                  {
                    item_key: 'hashicorp.billing.dimension.size',
                    option_key: 'medium',
                  },
                ],
              },
              {
                unit: 'HOUR',
                unit_price: '0.075',
                key: 'num_clients_large',
                dependencies: [
                  {
                    item_key: 'hashicorp.billing.dimension.size',
                    option_key: 'large',
                  },
                ],
              },
            ],
          },
        ],
      });
    case 'starter':
      return server.create('billing.package', {
        key: 'hashicorp.vault.starter',
        resource_type: 'hashicorp.vault.cluster',
        items: [
          {
            key: 'hashicorp.billing.dimension.size',
            optional: false,
            options: [
              {
                unit: 'HOUR',
                unit_price: '0.50',
                key: 'small',
                dependencies: [],
              },
            ],
          },
          {
            key: 'hashicorp.vault.dimension.num_clients',
            optional: false,
            options: [
              {
                unit: 'HOUR',
                unit_price: '0.50',
                key: 'num_clients_small',
                dependencies: [
                  {
                    item_key: 'hashicorp.billing.dimension.size',
                    option_key: 'small',
                  },
                ],
              },
            ],
          },
        ],
      });

    case 'plus':
      return server.create('billing.package', {
        key: 'hashicorp.vault.plus',
        resource_type: 'hashicorp.vault.cluster',
        items: [
          {
            key: 'hashicorp.billing.dimension.size',
            optional: false,
            options: [
              {
                unit: 'HOUR',
                unit_price: '6',
                key: 'small',
                dependencies: [],
              },
              {
                unit: 'HOUR',
                unit_price: '7',
                key: 'medium',
                dependencies: [],
              },

              {
                unit: 'HOUR',
                unit_price: '8',
                key: 'large',
                dependencies: [],
              },
            ],
          },
          {
            key: 'hashicorp.vault.dimension.num_clients',
            optional: false,
            options: [
              {
                unit: 'HOUR',
                unit_price: '1',
                key: 'num_clients_small',
                dependencies: [
                  {
                    item_key: 'hashicorp.billing.dimension.size',
                    option_key: 'small',
                  },
                ],
              },
              {
                unit: 'HOUR',
                unit_price: '1',
                key: 'num_clients_medium',
                dependencies: [
                  {
                    item_key: 'hashicorp.billing.dimension.size',
                    option_key: 'medium',
                  },
                ],
              },

              {
                unit: 'HOUR',
                unit_price: '1',
                key: 'num_clients_large',
                dependencies: [
                  {
                    item_key: 'hashicorp.billing.dimension.size',
                    option_key: 'large',
                  },
                ],
              },
            ],
          },
        ],
      });

    default:
      throw new Error(`Unable to create Vault Package of type: ${packageType}`);
  }
}
