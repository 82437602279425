import { TYPE_NETWORK } from 'common/utils/cloud-resource-types';
import baseScenario from './user';

const baseOptions = {
  createNetwork: true,
  createBilling: true,
  createConsul: false,
  createVault: false,
};

export default function (
  server,
  { hasMultiRegion = false, options = {} } = {}
) {
  baseScenario(server, { ...baseOptions, ...options });

  let location = server.schema.first('location.location');
  if (hasMultiRegion) {
    let project = server.schema.first('resource-manager.project');
    let region = server.create('location.region', {
      region: 'us-east-1',
      provider: 'aws',
    });

    location = server.create('location.location', {
      organizationId: project.parent.id,
      projectId: project.id,
      regionId: region.id,
    });
  }

  let network = server.create('network.network', {
    id: 'network-2',
    cidr_block: '172.20.16.0/20',
    created_at: '2020-11-11T03:16:17:187Z',
    updated_at: '2020-11-11T03:16:17:187Z',
    location,
  });

  server.create('location.link', {
    id: network.id,
    type: TYPE_NETWORK,
    description: `HashiCorp Virtual Network ("${network.id}")`,
    location,
  });
}
