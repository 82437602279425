import { faker } from '@faker-js/faker';
import consulScenario from './consul';

export const CONSUL_AZURE_ID = 'consul-azure';

export default function (server, options = {}) {
  // Set up user scenario.
  consulScenario(server, options);

  let project = server.schema.first('resource-manager.project');
  let region = server.create('location.region', {
    region: 'westus2',
    provider: 'azure',
  });
  let location = server.create('location.location', {
    organizationId: project.parent.id,
    projectId: project.id,
    regionId: region.id,
  });

  let networkId = 'azure';
  server.create('network.network', {
    id: networkId,
    location,
    cidr_block: '172.20.15.0/20',
    created_at: '2020-10-14T03:16:17.187Z',
    updated_at: '2020-10-14T03:16:17.187Z',
  });
  let networkLink = server.schema.findBy('location.link', { id: networkId });

  server.create('consul.cluster', {
    id: CONSUL_AZURE_ID,
    consul_version: 'v1.8.6',
    created_at: '2020-10-14T02:16:17.187Z',
    resourceId: faker.string.uuid(),
    config: {
      capacity_config: {
        size: 'X_SMALL',
      },
      network_config: {
        network: networkLink,
        private: false,
        ip_allowlist: [],
      },
      tier: 'DEVELOPMENT',
      primary: null,
    },
    dns_names: {
      public: `test-cluster.public.consul.${networkId}.azure.hcp.dev`,
      private: `test-cluster.private.consul.${networkId}.azure.hcp.dev`,
    },
    location,
  });
}
