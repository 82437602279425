import userScenario from './user';
import { packerRegistry, packerBasics } from '../helpers/packer';
import { viewerPermissions } from '../helpers/permissions';

export default function (server) {
  userScenario(server, {
    createVault: false,
    createBilling: true,
    createConsul: false,
    hasVerifiedEmail: true,
    allowedPermissions: viewerPermissions,
  });

  packerRegistry(server);
  packerBasics(server);
}
