/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import { Secrets20231128PrincipalFromJSON, Secrets20231128PrincipalToJSON, Secrets20231128SecretRotatingVersionFromJSON, Secrets20231128SecretRotatingVersionToJSON, Secrets20231128SecretStaticVersionFromJSON, Secrets20231128SecretStaticVersionToJSON, Secrets20231128SyncStatusFromJSON, Secrets20231128SyncStatusToJSON, } from './';
export function Secrets20231128SecretFromJSON(json) {
    return Secrets20231128SecretFromJSONTyped(json, false);
}
export function Secrets20231128SecretFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'latestVersion': !exists(json, 'latest_version') ? undefined : json['latest_version'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'createdBy': !exists(json, 'created_by') ? undefined : Secrets20231128PrincipalFromJSON(json['created_by']),
        'syncStatus': !exists(json, 'sync_status') ? undefined : (mapValues(json['sync_status'], Secrets20231128SyncStatusFromJSON)),
        'staticVersion': !exists(json, 'static_version') ? undefined : Secrets20231128SecretStaticVersionFromJSON(json['static_version']),
        'rotatingVersion': !exists(json, 'rotating_version') ? undefined : Secrets20231128SecretRotatingVersionFromJSON(json['rotating_version']),
        'versionCount': !exists(json, 'version_count') ? undefined : json['version_count'],
    };
}
export function Secrets20231128SecretToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'type': value.type,
        'provider': value.provider,
        'latest_version': value.latestVersion,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'created_by': Secrets20231128PrincipalToJSON(value.createdBy),
        'sync_status': value.syncStatus === undefined ? undefined : (mapValues(value.syncStatus, Secrets20231128SyncStatusToJSON)),
        'static_version': Secrets20231128SecretStaticVersionToJSON(value.staticVersion),
        'rotating_version': Secrets20231128SecretRotatingVersionToJSON(value.rotatingVersion),
        'version_count': value.versionCount,
    };
}
