import userScenario from './user';
import { STATUSPAGE_IO_STATUS } from 'core/utils/constants';
import { HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevel } from '@clients/cloud-global-network-manager-service';
import { faker } from '@faker-js/faker';
import {
  BILLING_ACCOUNT_STATUS,
  BILLING_ON_DEMAND_STATUS,
} from 'billing-common/constants/status';

export default function (server, options = {}) {
  // Set up user scenario.
  userScenario(server, {
    systemStatus: {
      hcp: STATUSPAGE_IO_STATUS.OPERATIONAL,
      consul: STATUSPAGE_IO_STATUS.OPERATIONAL,
      packer: STATUSPAGE_IO_STATUS.OPERATIONAL,
      vault: STATUSPAGE_IO_STATUS.OPERATIONAL,
    },
    createConsul: true,
    numConsulConnectedClients: 1,
    consulPackages: {
      development: true,
      standard: true,
      plus: true,
    },
    consulPrimaryFederatedCluster: false,
    consulSecondaryFederatedCluster: false,
    createNetwork: true,
    createPeeringConnection: {
      state: 'PENDING_ACCEPTANCE',
      hvnRoutes: true,
    },
    createTgwAttachment: {
      state: 'PENDING_ACCEPTANCE',
      hvnRoutes: false,
    },
    // billing
    billingAccountOnDemandStatus: BILLING_ON_DEMAND_STATUS.UNSET,
    billingAccountStatus: BILLING_ACCOUNT_STATUS.TRIAL,
    billingRemainingBalance: undefined,
    billingZeroUsage: false,
    createBilling: true,
    hasExpiredCreditCard: false,
    onDemandBillingMethodDetails: true,
    // flex
    // end billing
    hasNoOwnedOrg: false,
    hasVerifiedEmail: true,
    // don't create vault stuff because mirage will freak out
    vaultPackages: false,
    createVault: false,
    ...options,
  });
  let firstReadWriteSelfManagedCluster = server.schema.first(
    'consul-global-network-manager.cluster'
  );
  let loc = server.schema.findBy('location.location', {
    id: firstReadWriteSelfManagedCluster.locationId,
  });

  let secondReadOnlySelfManagedConsulCluster = server.create(
    'consul-global-network-manager.cluster',
    {
      id: 'test-sm-read-only-mode',
      name: 'test-sm-read-only-mode',
      consul_access_level:
        HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevel.GLOBALREADONLY,
      consul_version: 'v1.15.3',
      location: loc,
    }
  );
  server.createList('consul-global-network-manager.cluster.server', 1, {
    cluster: secondReadOnlySelfManagedConsulCluster,
  });
}
