/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudVagrant20220930ArchitectureToJSON, HashicorpCloudVagrant20220930BoxToJSON, HashicorpCloudVagrant20220930CreateArchitectureResponseFromJSON, HashicorpCloudVagrant20220930CreateBoxResponseFromJSON, HashicorpCloudVagrant20220930CreateProviderResponseFromJSON, HashicorpCloudVagrant20220930CreateRegistryResponseFromJSON, HashicorpCloudVagrant20220930CreateVersionResponseFromJSON, HashicorpCloudVagrant20220930DeleteBoxResponseFromJSON, HashicorpCloudVagrant20220930DeleteRegistryResponseFromJSON, HashicorpCloudVagrant20220930DirectUploadBoxResponseFromJSON, HashicorpCloudVagrant20220930DownloadBoxResponseFromJSON, HashicorpCloudVagrant20220930ListArchitecturesResponseFromJSON, HashicorpCloudVagrant20220930ListBoxesResponseFromJSON, HashicorpCloudVagrant20220930ListProvidersResponseFromJSON, HashicorpCloudVagrant20220930ListRegistriesResponseFromJSON, HashicorpCloudVagrant20220930ListVersionsResponseFromJSON, HashicorpCloudVagrant20220930ProviderToJSON, HashicorpCloudVagrant20220930ReadArchitectureResponseFromJSON, HashicorpCloudVagrant20220930ReadBoxResponseFromJSON, HashicorpCloudVagrant20220930ReadProviderResponseFromJSON, HashicorpCloudVagrant20220930ReadRegistryResponseFromJSON, HashicorpCloudVagrant20220930ReadVersionResponseFromJSON, HashicorpCloudVagrant20220930RegistryToJSON, HashicorpCloudVagrant20220930SearchIndexResponseFromJSON, HashicorpCloudVagrant20220930UpdateArchitectureResponseFromJSON, HashicorpCloudVagrant20220930UpdateBoxResponseFromJSON, HashicorpCloudVagrant20220930UpdateProviderResponseFromJSON, HashicorpCloudVagrant20220930UpdateRegistryResponseFromJSON, HashicorpCloudVagrant20220930UpdateVersionResponseFromJSON, HashicorpCloudVagrant20220930UploadBoxResponseFromJSON, HashicorpCloudVagrant20220930VersionToJSON, } from '../models';
/**
 *
 */
export class RegistryServiceApi extends runtime.BaseAPI {
    /**
     * Activation will re-enable a Registry, allowing requests and accruing charges as normal.
     * ActivateRegistry activates a Vagrant Box Registry at the Location specified. The Registry must already exist and must be currently disabled.
     */
    async activateRegistryRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling activateRegistry.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/activate`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Activation will re-enable a Registry, allowing requests and accruing charges as normal.
     * ActivateRegistry activates a Vagrant Box Registry at the Location specified. The Registry must already exist and must be currently disabled.
     */
    async activateRegistry(registry) {
        const response = await this.activateRegistryRaw({ registry: registry });
        return await response.value();
    }
    /**
     * CompleteDirectUploadBox is a callback endpoint used to signal than a direct to backend storage upload of Boxfile is finished.
     */
    async completeDirectUploadBoxRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling completeDirectUploadBox.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling completeDirectUploadBox.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling completeDirectUploadBox.');
        }
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider', 'Required parameter requestParameters.provider was null or undefined when calling completeDirectUploadBox.');
        }
        if (requestParameters.architecture === null || requestParameters.architecture === undefined) {
            throw new runtime.RequiredError('architecture', 'Required parameter requestParameters.architecture was null or undefined when calling completeDirectUploadBox.');
        }
        if (requestParameters.object === null || requestParameters.object === undefined) {
            throw new runtime.RequiredError('object', 'Required parameter requestParameters.object was null or undefined when calling completeDirectUploadBox.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/version/{version}/provider/{provider}/architecture/{architecture}/direct/complete/{object}`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))).replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))).replace(`{${"architecture"}}`, encodeURIComponent(String(requestParameters.architecture))).replace(`{${"object"}}`, encodeURIComponent(String(requestParameters.object))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * CompleteDirectUploadBox is a callback endpoint used to signal than a direct to backend storage upload of Boxfile is finished.
     */
    async completeDirectUploadBox(registry, box, version, provider, architecture, object) {
        const response = await this.completeDirectUploadBoxRaw({ registry: registry, box: box, version: version, provider: provider, architecture: architecture, object: object });
        return await response.value();
    }
    /**
     * CreateArchitecture creates a Architecture in the specified Version.
     */
    async createArchitectureRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling createArchitecture.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling createArchitecture.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling createArchitecture.');
        }
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider', 'Required parameter requestParameters.provider was null or undefined when calling createArchitecture.');
        }
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling createArchitecture.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/version/{version}/provider/{provider}/architectures`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))).replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudVagrant20220930ArchitectureToJSON(requestParameters.data),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930CreateArchitectureResponseFromJSON(jsonValue));
    }
    /**
     * CreateArchitecture creates a Architecture in the specified Version.
     */
    async createArchitecture(registry, box, version, provider, data) {
        const response = await this.createArchitectureRaw({ registry: registry, box: box, version: version, provider: provider, data: data });
        return await response.value();
    }
    /**
     * CreateBox creates a new Vagrant Box.
     */
    async createBoxRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling createBox.');
        }
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling createBox.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/boxes`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudVagrant20220930BoxToJSON(requestParameters.data),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930CreateBoxResponseFromJSON(jsonValue));
    }
    /**
     * CreateBox creates a new Vagrant Box.
     */
    async createBox(registry, data) {
        const response = await this.createBoxRaw({ registry: registry, data: data });
        return await response.value();
    }
    /**
     * CreateProvider creates a Provider in the specified Version.
     */
    async createProviderRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling createProvider.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling createProvider.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling createProvider.');
        }
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling createProvider.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/version/{version}/providers`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudVagrant20220930ProviderToJSON(requestParameters.data),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930CreateProviderResponseFromJSON(jsonValue));
    }
    /**
     * CreateProvider creates a Provider in the specified Version.
     */
    async createProvider(registry, box, version, data) {
        const response = await this.createProviderRaw({ registry: registry, box: box, version: version, data: data });
        return await response.value();
    }
    /**
     * CreateRegistry creates a Vagrant Box Registry at the Location specified.
     */
    async createRegistryRaw(requestParameters) {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling createRegistry.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registries`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudVagrant20220930RegistryToJSON(requestParameters.data),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930CreateRegistryResponseFromJSON(jsonValue));
    }
    /**
     * CreateRegistry creates a Vagrant Box Registry at the Location specified.
     */
    async createRegistry(data) {
        const response = await this.createRegistryRaw({ data: data });
        return await response.value();
    }
    /**
     * CreateVersion creates a new Box Version.
     */
    async createVersionRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling createVersion.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling createVersion.');
        }
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling createVersion.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/versions`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudVagrant20220930VersionToJSON(requestParameters.data),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930CreateVersionResponseFromJSON(jsonValue));
    }
    /**
     * CreateVersion creates a new Box Version.
     */
    async createVersion(registry, box, data) {
        const response = await this.createVersionRaw({ registry: registry, box: box, data: data });
        return await response.value();
    }
    /**
     * Deactivation will disable a Registry but not delete its data. All requests to the Registry will return not-found errors. This can be used to temporarily block access to a Registry to prevent access-related charges to the Registry.
     * DeactivateRegistry deactivates a Vagrant Box Registry at the Location specified. The Registry must already exist and must be currently enabled.
     */
    async deactivateRegistryRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling deactivateRegistry.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/deactivate`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Deactivation will disable a Registry but not delete its data. All requests to the Registry will return not-found errors. This can be used to temporarily block access to a Registry to prevent access-related charges to the Registry.
     * DeactivateRegistry deactivates a Vagrant Box Registry at the Location specified. The Registry must already exist and must be currently enabled.
     */
    async deactivateRegistry(registry) {
        const response = await this.deactivateRegistryRaw({ registry: registry });
        return await response.value();
    }
    /**
     * DeleteArchitecture deletes a Architecture. Note that this deletes any box data associated with an Architecture, so use with care.
     */
    async deleteArchitectureRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling deleteArchitecture.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling deleteArchitecture.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling deleteArchitecture.');
        }
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider', 'Required parameter requestParameters.provider was null or undefined when calling deleteArchitecture.');
        }
        if (requestParameters.architecture === null || requestParameters.architecture === undefined) {
            throw new runtime.RequiredError('architecture', 'Required parameter requestParameters.architecture was null or undefined when calling deleteArchitecture.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/version/{version}/provider/{provider}/architecture/{architecture}`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))).replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))).replace(`{${"architecture"}}`, encodeURIComponent(String(requestParameters.architecture))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * DeleteArchitecture deletes a Architecture. Note that this deletes any box data associated with an Architecture, so use with care.
     */
    async deleteArchitecture(registry, box, version, provider, architecture) {
        const response = await this.deleteArchitectureRaw({ registry: registry, box: box, version: version, provider: provider, architecture: architecture });
        return await response.value();
    }
    /**
     * Deleting a Box removes all its Versions and Providers as well. This operation cannot be undone.
     * DeleteBox deletes a Vagrant Box.
     */
    async deleteBoxRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling deleteBox.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling deleteBox.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930DeleteBoxResponseFromJSON(jsonValue));
    }
    /**
     * Deleting a Box removes all its Versions and Providers as well. This operation cannot be undone.
     * DeleteBox deletes a Vagrant Box.
     */
    async deleteBox(registry, box) {
        const response = await this.deleteBoxRaw({ registry: registry, box: box });
        return await response.value();
    }
    /**
     * DeleteProvider deletes a Provider. Note that this deletes any data associated with a Hosted Provider, so use with care.
     */
    async deleteProviderRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling deleteProvider.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling deleteProvider.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling deleteProvider.');
        }
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider', 'Required parameter requestParameters.provider was null or undefined when calling deleteProvider.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/version/{version}/provider/{provider}`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))).replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * DeleteProvider deletes a Provider. Note that this deletes any data associated with a Hosted Provider, so use with care.
     */
    async deleteProvider(registry, box, version, provider) {
        const response = await this.deleteProviderRaw({ registry: registry, box: box, version: version, provider: provider });
        return await response.value();
    }
    /**
     * Deletion removes the Registry and all of its Box, Version, and Provider data, including any Hosted Providers and their respective data. This operation is permanent and cannot be undone.
     * DeleteRegistry deletes a Vagrant Box Registry at the Location specified.
     */
    async deleteRegistryRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling deleteRegistry.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930DeleteRegistryResponseFromJSON(jsonValue));
    }
    /**
     * Deletion removes the Registry and all of its Box, Version, and Provider data, including any Hosted Providers and their respective data. This operation is permanent and cannot be undone.
     * DeleteRegistry deletes a Vagrant Box Registry at the Location specified.
     */
    async deleteRegistry(registry) {
        const response = await this.deleteRegistryRaw({ registry: registry });
        return await response.value();
    }
    /**
     * Deleting a Box Version removes all its Providers as well. This operation cannot be undone.
     * DeleteVersion deletes a Box Version.
     */
    async deleteVersionRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling deleteVersion.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling deleteVersion.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling deleteVersion.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/version/{version}`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Deleting a Box Version removes all its Providers as well. This operation cannot be undone.
     * DeleteVersion deletes a Box Version.
     */
    async deleteVersion(registry, box, version) {
        const response = await this.deleteVersionRaw({ registry: registry, box: box, version: version });
        return await response.value();
    }
    /**
     * DirectUploadBox initiates the upload of a single hosted Boxfile directly to the backend storage. The service will return details that can be used to upload the data over HTTP.
     */
    async directUploadBoxRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling directUploadBox.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling directUploadBox.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling directUploadBox.');
        }
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider', 'Required parameter requestParameters.provider was null or undefined when calling directUploadBox.');
        }
        if (requestParameters.architecture === null || requestParameters.architecture === undefined) {
            throw new runtime.RequiredError('architecture', 'Required parameter requestParameters.architecture was null or undefined when calling directUploadBox.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/version/{version}/provider/{provider}/architecture/{architecture}/direct/upload`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))).replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))).replace(`{${"architecture"}}`, encodeURIComponent(String(requestParameters.architecture))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930DirectUploadBoxResponseFromJSON(jsonValue));
    }
    /**
     * DirectUploadBox initiates the upload of a single hosted Boxfile directly to the backend storage. The service will return details that can be used to upload the data over HTTP.
     */
    async directUploadBox(registry, box, version, provider, architecture) {
        const response = await this.directUploadBoxRaw({ registry: registry, box: box, version: version, provider: provider, architecture: architecture });
        return await response.value();
    }
    /**
     * For Archivist-hosted Boxfile, this is an atomic call that prepares the Provider for download from the object storage. For externally-hosted Boxfiles, this is simply a pass-through to the external download data supplied in the record.
     * Download initiates a Boxfile download.
     */
    async downloadBoxRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling downloadBox.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling downloadBox.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling downloadBox.');
        }
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider', 'Required parameter requestParameters.provider was null or undefined when calling downloadBox.');
        }
        if (requestParameters.architecture === null || requestParameters.architecture === undefined) {
            throw new runtime.RequiredError('architecture', 'Required parameter requestParameters.architecture was null or undefined when calling downloadBox.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/version/{version}/provider/{provider}/architecture/{architecture}/download`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))).replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))).replace(`{${"architecture"}}`, encodeURIComponent(String(requestParameters.architecture))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930DownloadBoxResponseFromJSON(jsonValue));
    }
    /**
     * For Archivist-hosted Boxfile, this is an atomic call that prepares the Provider for download from the object storage. For externally-hosted Boxfiles, this is simply a pass-through to the external download data supplied in the record.
     * Download initiates a Boxfile download.
     */
    async downloadBox(registry, box, version, provider, architecture) {
        const response = await this.downloadBoxRaw({ registry: registry, box: box, version: version, provider: provider, architecture: architecture });
        return await response.value();
    }
    /**
     * ListArchitectures lists all Architectures within a Provider.
     */
    async listArchitecturesRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling listArchitectures.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling listArchitectures.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling listArchitectures.');
        }
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider', 'Required parameter requestParameters.provider was null or undefined when calling listArchitectures.');
        }
        const queryParameters = {};
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/version/{version}/provider/{provider}/architectures`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))).replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930ListArchitecturesResponseFromJSON(jsonValue));
    }
    /**
     * ListArchitectures lists all Architectures within a Provider.
     */
    async listArchitectures(registry, box, version, provider, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.listArchitecturesRaw({ registry: registry, box: box, version: version, provider: provider, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * ListBoxes lists all of the Boxes within a particular Registry.
     */
    async listBoxesRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling listBoxes.');
        }
        const queryParameters = {};
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        if (requestParameters.expanded !== undefined) {
            queryParameters['expanded'] = requestParameters.expanded;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/boxes`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930ListBoxesResponseFromJSON(jsonValue));
    }
    /**
     * ListBoxes lists all of the Boxes within a particular Registry.
     */
    async listBoxes(registry, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken, expanded) {
        const response = await this.listBoxesRaw({ registry: registry, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken, expanded: expanded });
        return await response.value();
    }
    /**
     * ListProviders lists all Providers within a Version.
     */
    async listProvidersRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling listProviders.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling listProviders.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling listProviders.');
        }
        const queryParameters = {};
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        if (requestParameters.expanded !== undefined) {
            queryParameters['expanded'] = requestParameters.expanded;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/version/{version}/providers`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930ListProvidersResponseFromJSON(jsonValue));
    }
    /**
     * ListProviders lists all Providers within a Version.
     */
    async listProviders(registry, box, version, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken, expanded) {
        const response = await this.listProvidersRaw({ registry: registry, box: box, version: version, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken, expanded: expanded });
        return await response.value();
    }
    /**
     * ListRegistries lists available Vagrant Box Registries in a particular Project.
     */
    async listRegistriesRaw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.locationOrganizationId !== undefined) {
            queryParameters['location.organization_id'] = requestParameters.locationOrganizationId;
        }
        if (requestParameters.locationProjectId !== undefined) {
            queryParameters['location.project_id'] = requestParameters.locationProjectId;
        }
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930ListRegistriesResponseFromJSON(jsonValue));
    }
    /**
     * ListRegistries lists available Vagrant Box Registries in a particular Project.
     */
    async listRegistries(locationOrganizationId, locationProjectId, locationRegionProvider, locationRegionRegion, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.listRegistriesRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * ListVersion lists all of the Versions within a particular Box.
     */
    async listVersionsRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling listVersions.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling listVersions.');
        }
        const queryParameters = {};
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        if (requestParameters.expanded !== undefined) {
            queryParameters['expanded'] = requestParameters.expanded;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/versions`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930ListVersionsResponseFromJSON(jsonValue));
    }
    /**
     * ListVersion lists all of the Versions within a particular Box.
     */
    async listVersions(registry, box, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken, expanded) {
        const response = await this.listVersionsRaw({ registry: registry, box: box, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken, expanded: expanded });
        return await response.value();
    }
    /**
     * ReadArchitecture fetches a Architecture for the specified Provider.
     */
    async readArchitectureRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling readArchitecture.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling readArchitecture.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling readArchitecture.');
        }
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider', 'Required parameter requestParameters.provider was null or undefined when calling readArchitecture.');
        }
        if (requestParameters.architecture === null || requestParameters.architecture === undefined) {
            throw new runtime.RequiredError('architecture', 'Required parameter requestParameters.architecture was null or undefined when calling readArchitecture.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/version/{version}/provider/{provider}/architecture/{architecture}`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))).replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))).replace(`{${"architecture"}}`, encodeURIComponent(String(requestParameters.architecture))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930ReadArchitectureResponseFromJSON(jsonValue));
    }
    /**
     * ReadArchitecture fetches a Architecture for the specified Provider.
     */
    async readArchitecture(registry, box, version, provider, architecture) {
        const response = await this.readArchitectureRaw({ registry: registry, box: box, version: version, provider: provider, architecture: architecture });
        return await response.value();
    }
    /**
     * ReadBox reads a Vagrant Box.
     */
    async readBoxRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling readBox.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling readBox.');
        }
        const queryParameters = {};
        if (requestParameters.expanded !== undefined) {
            queryParameters['expanded'] = requestParameters.expanded;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930ReadBoxResponseFromJSON(jsonValue));
    }
    /**
     * ReadBox reads a Vagrant Box.
     */
    async readBox(registry, box, expanded) {
        const response = await this.readBoxRaw({ registry: registry, box: box, expanded: expanded });
        return await response.value();
    }
    /**
     * ReadProvider fetches a Provider for the specified Version.
     */
    async readProviderRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling readProvider.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling readProvider.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling readProvider.');
        }
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider', 'Required parameter requestParameters.provider was null or undefined when calling readProvider.');
        }
        const queryParameters = {};
        if (requestParameters.expanded !== undefined) {
            queryParameters['expanded'] = requestParameters.expanded;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/version/{version}/provider/{provider}`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))).replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930ReadProviderResponseFromJSON(jsonValue));
    }
    /**
     * ReadProvider fetches a Provider for the specified Version.
     */
    async readProvider(registry, box, version, provider, expanded) {
        const response = await this.readProviderRaw({ registry: registry, box: box, version: version, provider: provider, expanded: expanded });
        return await response.value();
    }
    /**
     * ReadRegistry reads a Vagrant Box Registry by its name.
     */
    async readRegistryRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling readRegistry.');
        }
        const queryParameters = {};
        if (requestParameters.expanded !== undefined) {
            queryParameters['expanded'] = requestParameters.expanded;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930ReadRegistryResponseFromJSON(jsonValue));
    }
    /**
     * ReadRegistry reads a Vagrant Box Registry by its name.
     */
    async readRegistry(registry, expanded) {
        const response = await this.readRegistryRaw({ registry: registry, expanded: expanded });
        return await response.value();
    }
    /**
     * ReadVersion reads a Box Version.
     */
    async readVersionRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling readVersion.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling readVersion.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling readVersion.');
        }
        const queryParameters = {};
        if (requestParameters.expanded !== undefined) {
            queryParameters['expanded'] = requestParameters.expanded;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/version/{version}`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930ReadVersionResponseFromJSON(jsonValue));
    }
    /**
     * ReadVersion reads a Box Version.
     */
    async readVersion(registry, box, version, expanded) {
        const response = await this.readVersionRaw({ registry: registry, box: box, version: version, expanded: expanded });
        return await response.value();
    }
    /**
     * Release releases the specified Version. The Version must not already be released.
     */
    async releaseVersionRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling releaseVersion.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling releaseVersion.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling releaseVersion.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/version/{version}/release`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Release releases the specified Version. The Version must not already be released.
     */
    async releaseVersion(registry, box, version) {
        const response = await this.releaseVersionRaw({ registry: registry, box: box, version: version });
        return await response.value();
    }
    /**
     * Revoke revokes the specified Version. The Version must be actively released.
     */
    async revokeVersionRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling revokeVersion.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling revokeVersion.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling revokeVersion.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/version/{version}/revoke`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Revoke revokes the specified Version. The Version must be actively released.
     */
    async revokeVersion(registry, box, version) {
        const response = await this.revokeVersionRaw({ registry: registry, box: box, version: version });
        return await response.value();
    }
    /**
     * Search returns a list of available boxes based on search parameters
     */
    async searchRaw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }
        if (requestParameters.providers) {
            queryParameters['providers'] = requestParameters.providers;
        }
        if (requestParameters.sortOrderBy) {
            queryParameters['sort.order_by'] = requestParameters.sortOrderBy;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        if (requestParameters.architectures) {
            queryParameters['architectures'] = requestParameters.architectures;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930SearchIndexResponseFromJSON(jsonValue));
    }
    /**
     * Search returns a list of available boxes based on search parameters
     */
    async search(q, providers, sortOrderBy, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken, architectures) {
        const response = await this.searchRaw({ q: q, providers: providers, sortOrderBy: sortOrderBy, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken, architectures: architectures });
        return await response.value();
    }
    /**
     * UpdateArchitecture updates details about a specified Architecture.
     */
    async updateArchitectureRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling updateArchitecture.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling updateArchitecture.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling updateArchitecture.');
        }
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider', 'Required parameter requestParameters.provider was null or undefined when calling updateArchitecture.');
        }
        if (requestParameters.architecture === null || requestParameters.architecture === undefined) {
            throw new runtime.RequiredError('architecture', 'Required parameter requestParameters.architecture was null or undefined when calling updateArchitecture.');
        }
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling updateArchitecture.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/version/{version}/provider/{provider}/architecture/{architecture}`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))).replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))).replace(`{${"architecture"}}`, encodeURIComponent(String(requestParameters.architecture))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudVagrant20220930ArchitectureToJSON(requestParameters.data),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930UpdateArchitectureResponseFromJSON(jsonValue));
    }
    /**
     * UpdateArchitecture updates details about a specified Architecture.
     */
    async updateArchitecture(registry, box, version, provider, architecture, data) {
        const response = await this.updateArchitectureRaw({ registry: registry, box: box, version: version, provider: provider, architecture: architecture, data: data });
        return await response.value();
    }
    /**
     * Note that this only updates details about the Box itself. To work with a Box\'s Versions or Providers, use those respective services.
     * UpdateBox updates the details of a Vagrant Box.
     */
    async updateBoxRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling updateBox.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling updateBox.');
        }
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling updateBox.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudVagrant20220930BoxToJSON(requestParameters.data),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930UpdateBoxResponseFromJSON(jsonValue));
    }
    /**
     * Note that this only updates details about the Box itself. To work with a Box\'s Versions or Providers, use those respective services.
     * UpdateBox updates the details of a Vagrant Box.
     */
    async updateBox(registry, box, data) {
        const response = await this.updateBoxRaw({ registry: registry, box: box, data: data });
        return await response.value();
    }
    /**
     * Note that you don\'t use this to upload data to Hosted Providers, to do that, use the Upload method.
     * UpdateProvider updates details about a specified Provider.
     */
    async updateProviderRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling updateProvider.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling updateProvider.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling updateProvider.');
        }
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider', 'Required parameter requestParameters.provider was null or undefined when calling updateProvider.');
        }
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling updateProvider.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/version/{version}/provider/{provider}`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))).replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudVagrant20220930ProviderToJSON(requestParameters.data),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930UpdateProviderResponseFromJSON(jsonValue));
    }
    /**
     * Note that you don\'t use this to upload data to Hosted Providers, to do that, use the Upload method.
     * UpdateProvider updates details about a specified Provider.
     */
    async updateProvider(registry, box, version, provider, data) {
        const response = await this.updateProviderRaw({ registry: registry, box: box, version: version, provider: provider, data: data });
        return await response.value();
    }
    /**
     * UpdateRegistry updates the details of a Vagrant Box Registry.
     */
    async updateRegistryRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling updateRegistry.');
        }
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling updateRegistry.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudVagrant20220930RegistryToJSON(requestParameters.data),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930UpdateRegistryResponseFromJSON(jsonValue));
    }
    /**
     * UpdateRegistry updates the details of a Vagrant Box Registry.
     */
    async updateRegistry(registry, data) {
        const response = await this.updateRegistryRaw({ registry: registry, data: data });
        return await response.value();
    }
    /**
     * UpdateVersion updates a Box Version.
     */
    async updateVersionRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling updateVersion.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling updateVersion.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling updateVersion.');
        }
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling updateVersion.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/version/{version}`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudVagrant20220930VersionToJSON(requestParameters.data),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930UpdateVersionResponseFromJSON(jsonValue));
    }
    /**
     * UpdateVersion updates a Box Version.
     */
    async updateVersion(registry, box, version, data) {
        const response = await this.updateVersionRaw({ registry: registry, box: box, version: version, data: data });
        return await response.value();
    }
    /**
     * Overwrite is permitted; old/existing data for a particular Architecture will be replaced with data from a new successful upload for that same Architecture.
     * UploadBox initiates the upload of a single hosted Boxfile. The service will return details that can be used to upload the data over HTTP.
     */
    async uploadBoxRaw(requestParameters) {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry', 'Required parameter requestParameters.registry was null or undefined when calling uploadBox.');
        }
        if (requestParameters.box === null || requestParameters.box === undefined) {
            throw new runtime.RequiredError('box', 'Required parameter requestParameters.box was null or undefined when calling uploadBox.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling uploadBox.');
        }
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider', 'Required parameter requestParameters.provider was null or undefined when calling uploadBox.');
        }
        if (requestParameters.architecture === null || requestParameters.architecture === undefined) {
            throw new runtime.RequiredError('architecture', 'Required parameter requestParameters.architecture was null or undefined when calling uploadBox.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/vagrant/2022-09-30/registry/{registry}/box/{box}/version/{version}/provider/{provider}/architecture/{architecture}/upload`.replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))).replace(`{${"box"}}`, encodeURIComponent(String(requestParameters.box))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))).replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))).replace(`{${"architecture"}}`, encodeURIComponent(String(requestParameters.architecture))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudVagrant20220930UploadBoxResponseFromJSON(jsonValue));
    }
    /**
     * Overwrite is permitted; old/existing data for a particular Architecture will be replaced with data from a new successful upload for that same Architecture.
     * UploadBox initiates the upload of a single hosted Boxfile. The service will return details that can be used to upload the data over HTTP.
     */
    async uploadBox(registry, box, version, provider, architecture) {
        const response = await this.uploadBoxRaw({ registry: registry, box: box, version: version, provider: provider, architecture: architecture });
        return await response.value();
    }
}
