import baseScenario from './user';
import { TYPE_NETWORK } from 'common/utils/cloud-resource-types';
import {
  HashicorpCloudNetwork20200907PeeringState,
  HashicorpCloudNetwork20200907NetworkState,
} from '@clients/cloud-network';
const baseOptions = {
  createNetwork: false,
  createVault: false,
  createBilling: true,
  hasVerifiedEmail: true,
  createConsul: true,
  consulPrimaryFederatedCluster: false,
  consulSecondaryFederatedCluster: false,
  createPeeringConnection: null,
  createTgwAttachment: null,
};

import { RegionProviders } from 'core/utils/regions';

export const AZURE_NETWORK_PEERING_STATES = [
  HashicorpCloudNetwork20200907PeeringState.PENDINGACCEPTANCE,
  HashicorpCloudNetwork20200907PeeringState.ACTIVE,
  HashicorpCloudNetwork20200907PeeringState.CREATING,
  HashicorpCloudNetwork20200907PeeringState.DELETING,
  HashicorpCloudNetwork20200907PeeringState.EXPIRED,
  HashicorpCloudNetwork20200907PeeringState.FAILED,
  HashicorpCloudNetwork20200907PeeringState.UNSET,
];

export const azureHvnId = `${RegionProviders.azure}-hvn`;

export default function (server, { options = {} } = {}) {
  /**
   * This scenario creates the following:
   * 1.  Azure HVN ("azure-hvn")
   * 2.  a list of  Azure Peerings
   */

  baseScenario(server, { ...baseOptions, ...options });

  let createNetworkAndLocation = (provider, networkId, state) => {
    let project = server.schema.first('resource-manager.project');
    let providerLocation;
    let providerRegion;
    if (provider === RegionProviders.azure) {
      providerRegion = server.create('location.region', {
        region: 'westus2',
        provider: RegionProviders.azure,
      });
      providerLocation = server.create('location.location', {
        organizationId: project.parent.id,
        projectId: project.id,
        regionId: providerRegion.id,
      });
    }

    let providerNetwork = server.create('network.network', {
      id: networkId,
      state,
      cidr_block: '10.10.0.0/20',
      location: providerLocation,
    });
    server.create('location.link', {
      id: networkId,
      type: TYPE_NETWORK,
      description: `HashiCorp Virtual Network ("${networkId}")`,
      location: providerLocation,
    });
    return providerNetwork;
  };

  // 1.  Create Azure HVN
  let azureHvn = createNetworkAndLocation(
    RegionProviders.azure,
    azureHvnId,
    HashicorpCloudNetwork20200907NetworkState.STABLE
  );

  //2. Create Azure Peering Connections
  AZURE_NETWORK_PEERING_STATES.map((state) => {
    server.create('network.peering', {
      hvn: azureHvn,
      state,
    });
  });
}
