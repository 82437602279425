/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointApplicationTemplateFromJSON, HashicorpCloudWaypointApplicationTemplateToJSON, TemplateRefWithoutIDFromJSON, TemplateRefWithoutIDToJSON, } from './';
export function HashicorpCloudWaypointWaypointServiceUpdateApplicationTemplateBodyFromJSON(json) {
    return HashicorpCloudWaypointWaypointServiceUpdateApplicationTemplateBodyFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointWaypointServiceUpdateApplicationTemplateBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'existingApplicationTemplate': !exists(json, 'existing_application_template') ? undefined : TemplateRefWithoutIDFromJSON(json['existing_application_template']),
        'applicationTemplate': !exists(json, 'application_template') ? undefined : HashicorpCloudWaypointApplicationTemplateFromJSON(json['application_template']),
        'updateMask': !exists(json, 'update_mask') ? undefined : json['update_mask'],
    };
}
export function HashicorpCloudWaypointWaypointServiceUpdateApplicationTemplateBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'namespace': value.namespace,
        'existing_application_template': TemplateRefWithoutIDToJSON(value.existingApplicationTemplate),
        'application_template': HashicorpCloudWaypointApplicationTemplateToJSON(value.applicationTemplate),
        'update_mask': value.updateMask,
    };
}
