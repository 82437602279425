/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105ContractStatusFromJSON, Billing20201105ContractStatusToJSON, Billing20201105ContractUpdateFromJSON, Billing20201105ContractUpdateToJSON, ContractFlexDetailsFromJSON, ContractFlexDetailsToJSON, } from './';
export function Billing20201105ContractFromJSON(json) {
    return Billing20201105ContractFromJSONTyped(json, false);
}
export function Billing20201105ContractFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'billingAccountId': !exists(json, 'billing_account_id') ? undefined : json['billing_account_id'],
        'activeFrom': !exists(json, 'active_from') ? undefined : (new Date(json['active_from'])),
        'activeUntil': !exists(json, 'active_until') ? undefined : (new Date(json['active_until'])),
        'status': !exists(json, 'status') ? undefined : Billing20201105ContractStatusFromJSON(json['status']),
        'updates': !exists(json, 'updates') ? undefined : (json['updates'].map(Billing20201105ContractUpdateFromJSON)),
        'flexDetails': !exists(json, 'flex_details') ? undefined : ContractFlexDetailsFromJSON(json['flex_details']),
        'entitlementDetails': !exists(json, 'entitlement_details') ? undefined : json['entitlement_details'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
    };
}
export function Billing20201105ContractToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'organization_id': value.organizationId,
        'billing_account_id': value.billingAccountId,
        'active_from': value.activeFrom === undefined ? undefined : (value.activeFrom.toISOString()),
        'active_until': value.activeUntil === undefined ? undefined : (value.activeUntil.toISOString()),
        'status': Billing20201105ContractStatusToJSON(value.status),
        'updates': value.updates === undefined ? undefined : (value.updates.map(Billing20201105ContractUpdateToJSON)),
        'flex_details': ContractFlexDetailsToJSON(value.flexDetails),
        'entitlement_details': value.entitlementDetails,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}
