/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105ContractDiscountFromJSON, Billing20201105ContractDiscountToJSON, ContractProductSupportLevelFromJSON, ContractProductSupportLevelToJSON, } from './';
export function ContractFlexDetailsFromJSON(json) {
    return ContractFlexDetailsFromJSONTyped(json, false);
}
export function ContractFlexDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'initialAmount': !exists(json, 'initial_amount') ? undefined : json['initial_amount'],
        'recognizedBalance': !exists(json, 'recognized_balance') ? undefined : json['recognized_balance'],
        'runningTotal': !exists(json, 'running_total') ? undefined : json['running_total'],
        'rollInPercentage': !exists(json, 'roll_in_percentage') ? undefined : json['roll_in_percentage'],
        'supportLevel': !exists(json, 'support_level') ? undefined : json['support_level'],
        'supportLevels': !exists(json, 'support_levels') ? undefined : (json['support_levels'].map(ContractProductSupportLevelFromJSON)),
        'discounts': !exists(json, 'discounts') ? undefined : (json['discounts'].map(Billing20201105ContractDiscountFromJSON)),
    };
}
export function ContractFlexDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'initial_amount': value.initialAmount,
        'recognized_balance': value.recognizedBalance,
        'running_total': value.runningTotal,
        'roll_in_percentage': value.rollInPercentage,
        'support_level': value.supportLevel,
        'support_levels': value.supportLevels === undefined ? undefined : (value.supportLevels.map(ContractProductSupportLevelToJSON)),
        'discounts': value.discounts === undefined ? undefined : (value.discounts.map(Billing20201105ContractDiscountToJSON)),
    };
}
