import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import iamKebabCaseToSentenceCase from '../helpers/iam-kebab-case-to-sentence-case.ts';
import { BASIC_ROLE_KEY_UI_ONLY } from '../utils/parse-role-id.ts';

import type IntlService from 'ember-intl/services/intl';
import type { IamParsedRole } from '../types/iam-parsed-role';

type ServiceId = IamParsedRole['service'];

interface IamGetLabelForServiceSignature {
  Args: {
    Positional: [ServiceId];
  };
  Return: string;
}

export default class IamGetLabelForService extends Helper<IamGetLabelForServiceSignature> {
  @service declare readonly intl: IntlService;

  compute([service]: [ServiceId]): string {
    if (!service) {
      return '';
    }

    switch (service) {
      case BASIC_ROLE_KEY_UI_ONLY:
        return this.intl.t(
          'manage-access.helpers.iam-get-label-for-service.basic',
        ) as string;
      default:
        return iamKebabCaseToSentenceCase(service) as string;
    }
  }
}
