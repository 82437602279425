import baseScenario from './user';
import { BILLING_ON_DEMAND_STATUS } from 'billing-common/constants/status';

let options = {
  createBilling: true,
  onDemandBillingMethodDetails: true,
  billingAccountOnDemandStatus: BILLING_ON_DEMAND_STATUS.ACTIVE,
  hasExpiredCreditCard: true,
};

export default function (server) {
  return baseScenario(server, options);
}
