import baseScenario from './user';

export default function (server) {
  baseScenario(server, {
    createConsul: false,
    createBilling: true,
    createPeeringConnection: {
      create: true,
      state: 'ACTIVE',
      hvnRoutes: false,
    },
    createTgwAttachment: {
      create: true,
      state: 'ACTIVE',
      hvnRoutes: false,
    },
  });
}
