import BaseAbility from './base-ability';
import { PREFIX_TERRAFORM_WORKSPACES } from '../utils/permission-types/index';

export default class TerraformWorkspaceAbility extends BaseAbility {
  prefix = PREFIX_TERRAFORM_WORKSPACES;

  /** @return {boolean} */
  get canList() {
    // This is intentionally set to true until the test-iam-permissions endpoint
    // returns the list permission. Simply removing this getter will enable the
    // actual check. For now, we override the default canList and always return
    // true.
    return true;
  }
}
