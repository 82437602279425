// Utils
import { TYPE_ORGANIZATION } from 'common/utils/cloud-resource-types';
import PolicyManager from '../utils/policy-manager.ts';

// Types
import type { HashicorpCloudResourcemanagerPolicyBindingMember } from '@clients/cloud-resource-manager';
import type { IamRoleLocation } from '../types/iam-role-location.ts';
import type { PolicyLocation } from '../types/policy-location.ts';

// Utils
import NO_ROLE_ROLE from '../utils/no-role-role.ts';

export default function iamFindRoleLocations(
  policyLocations: Array<PolicyLocation> = [],
  {
    memberIds = [],
  }: {
    memberIds: Array<
      HashicorpCloudResourcemanagerPolicyBindingMember['memberId']
    >;
  },
): Array<IamRoleLocation> {
  return policyLocations.reduce(
    (accumulator: Array<IamRoleLocation>, { link, policy }) => {
      const Policy = new PolicyManager({ policy });
      const locations = [
        ...memberIds.reduce((accumulator: Array<IamRoleLocation>, memberId) => {
          const { memberType, roleIds = [] } =
            Policy.getRolesByMemberId(memberId) ?? {};

          const member = {
            memberId,
            memberType,
          };

          const newRoleLocations = [];

          if (roleIds.length) {
            newRoleLocations.push(
              ...roleIds.map((roleId) => {
                return {
                  member,
                  link,
                  roleId,
                };
              }),
            );
          } else {
            // We need to be able to supplement "No role" rows here if the
            // link type is for the hashicorp.resourcemanager.organization.
            if (link.type === TYPE_ORGANIZATION) {
              newRoleLocations.push({
                member,
                link,
                roleId: NO_ROLE_ROLE.id,
              });
            }
          }

          return [...accumulator, ...newRoleLocations];
        }, []),
      ];

      return [...accumulator, ...locations];
    },
    [],
  );
}
