import userScenario from './user';

export default function (server) {
  userScenario(server, {
    hasVerifiedEmail: true,
    createBilling: true,
  });

  // Create 50 registries
  // 5 should have 1 box, many versions with 1 provider 1 architecture
  // 5 should have 1 box, 1 versions with many providers and architectures
  // 5 should have many boxes, 1 version, 1 provider, 1 architecture
  // 35 should have 1 box, 1 version 1 provider 1 archictecture
  // This is all for mirage's terrible performance

  const manyVersionRegistries = server.createList('vagrant.registry', 5);
  const manyProviderRegistries = server.createList('vagrant.registry', 5);
  const manyBoxRegistries = server.createList('vagrant.registry', 5);
  const shallowRegistries = server.createList('vagrant.registry', 35);

  manyVersionRegistries.forEach((registry) => {
    server.create('vagrant.box', 'public', {
      registry,
      $versions: 5,
      $versionProviders: 1,
    });
  });

  manyProviderRegistries.forEach((registry) => {
    const box = server.create('vagrant.box', 'public', {
      registry,
      $versions: 1,
    });

    const version = box.versions.models[0];
    server.createList('vagrant.provider', 5, {
      version,
      $architectures: 5,
    });
  });

  manyBoxRegistries.forEach((registry) => {
    server.createList('vagrant.box', 5, 'public', {
      registry,
      $versions: 1,
      $versionProviders: 1,
    });
  });

  shallowRegistries.forEach((registry) => {
    server.create('vagrant.box', 'public', {
      registry,
      $versions: 1,
      $versionProviders: 1,
    });
  });
}
