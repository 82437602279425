import type { HashicorpCloudResourcemanagerRole } from '@clients/cloud-resource-manager';

import type { IamEnrichedRole } from '../types/iam-enriched-role.ts';
import parseRoleId from '../utils/parse-role-id.ts';

export default function iamEnrichRolesWithServiceAndPersona(
  roles: Array<HashicorpCloudResourcemanagerRole>,
): Array<IamEnrichedRole> {
  if (!roles) {
    return [];
  }

  const ignoredRoles = new Set<HashicorpCloudResourcemanagerRole['id']>([
    'roles/owner',
  ]);

  return roles
    .filter((role) => {
      if (!role || !role.id) {
        return false;
      }
      return !ignoredRoles.has(role.id);
    })
    .map((role) => {
      const { service, persona } = parseRoleId(role.id);
      return {
        ...role,
        service,
        persona,
      };
    });
}
