/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128TierFromJSON, Secrets20231128TierToJSON, Secrets20231128UsageFromJSON, Secrets20231128UsageToJSON, Secrets20231128UsageOptLimitRemainingFromJSON, Secrets20231128UsageOptLimitRemainingToJSON, } from './';
export function Secrets20231128GetUsageResponseFromJSON(json) {
    return Secrets20231128GetUsageResponseFromJSONTyped(json, false);
}
export function Secrets20231128GetUsageResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'billingTier': !exists(json, 'billing_tier') ? undefined : Secrets20231128TierFromJSON(json['billing_tier']),
        'apps': !exists(json, 'apps') ? undefined : Secrets20231128UsageFromJSON(json['apps']),
        'secrets': !exists(json, 'secrets') ? undefined : Secrets20231128UsageFromJSON(json['secrets']),
        'integrations': !exists(json, 'integrations') ? undefined : Secrets20231128UsageFromJSON(json['integrations']),
        'accessApiCounts': !exists(json, 'access_api_counts') ? undefined : Secrets20231128UsageOptLimitRemainingFromJSON(json['access_api_counts']),
        'staticSecrets': !exists(json, 'static_secrets') ? undefined : Secrets20231128UsageFromJSON(json['static_secrets']),
        'rotatingSecrets': !exists(json, 'rotating_secrets') ? undefined : Secrets20231128UsageFromJSON(json['rotating_secrets']),
        'dynamicSecrets': !exists(json, 'dynamic_secrets') ? undefined : Secrets20231128UsageFromJSON(json['dynamic_secrets']),
        'gatewayPools': !exists(json, 'gateway_pools') ? undefined : Secrets20231128UsageFromJSON(json['gateway_pools']),
    };
}
export function Secrets20231128GetUsageResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'billing_tier': Secrets20231128TierToJSON(value.billingTier),
        'apps': Secrets20231128UsageToJSON(value.apps),
        'secrets': Secrets20231128UsageToJSON(value.secrets),
        'integrations': Secrets20231128UsageToJSON(value.integrations),
        'access_api_counts': Secrets20231128UsageOptLimitRemainingToJSON(value.accessApiCounts),
        'static_secrets': Secrets20231128UsageToJSON(value.staticSecrets),
        'rotating_secrets': Secrets20231128UsageToJSON(value.rotatingSecrets),
        'dynamic_secrets': Secrets20231128UsageToJSON(value.dynamicSecrets),
        'gateway_pools': Secrets20231128UsageToJSON(value.gatewayPools),
    };
}
