import baseScenario from './user';

export default function (server) {
  baseScenario(server, {
    createBilling: true,
    hasVerifiedEmail: true,
    createNetwork: true,
    createConsul: true,
    consulPrimaryFederatedCluster: true,
    consulSecondaryFederatedCluster: true,
    consulPrimaryDevFederatedCluster: true,
    consulSecondaryDevFederatedCluster: true,
  });
}
