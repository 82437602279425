import baseScenario from './user';
import { TYPE_NETWORK } from 'common/utils/cloud-resource-types';
import { HashicorpCloudNetwork20200907PeeringState } from '@clients/cloud-network';
const baseOptions = {
  createNetwork: false,
  createVault: false,
  createBilling: true,
  hasVerifiedEmail: true,
  createConsul: true,
  consulPrimaryFederatedCluster: false,
  consulSecondaryFederatedCluster: false,
  createPeeringConnection: null,
  createTgwAttachment: null,
};

import { RegionProviders } from 'core/utils/regions';

export const NETWORK_PEERING_STATES = Object.values(
  HashicorpCloudNetwork20200907PeeringState
);
export const awsHvnId = `${RegionProviders.aws}-hvn`;

export default function (server, { options = {} } = {}) {
  /**
   * This scenario creates the following:
   * 1.  AWS HVN ("aws-hvn")
   * 2.  a list of AWS Peerings
   */

  baseScenario(server, { ...baseOptions, ...options });

  let createNetworkAndLocation = (provider, networkId, state) => {
    let project = server.schema.first('resource-manager.project');
    let providerLocation;
    let providerRegion;
    if (provider === RegionProviders.azure) {
      providerRegion = server.create('location.region', {
        region: 'westus2',
        provider: RegionProviders.azure,
      });
      providerLocation = server.create('location.location', {
        organizationId: project.parent.id,
        projectId: project.id,
        regionId: providerRegion.id,
      });
    }
    if (provider === RegionProviders.aws) {
      providerLocation = server.schema.first('location.location');
    }

    let providerNetwork = server.create('network.network', {
      id: networkId,
      state,
      cidr_block: '10.10.0.0/20',
      location: providerLocation,
    });
    server.create('location.link', {
      id: networkId.id,
      type: TYPE_NETWORK,
      description: `HashiCorp Virtual Network ("${networkId.id}")`,
      location: providerLocation,
    });
    return providerNetwork;
  };

  // 1. Create AWS HVN
  let awsHvn = createNetworkAndLocation(
    RegionProviders.aws,
    awsHvnId,
    'STABLE'
  );
  // 2.  Create list of two AWS Peerings
  NETWORK_PEERING_STATES.map((state) => {
    server.create('network.peering', {
      hvn: awsHvn,
      state,
    });
  });
}
