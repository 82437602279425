import { Ability } from 'ember-can';
import { inject as service } from '@ember/service';
import {
  ACTION_CREATE,
  ACTION_DELETE,
  ACTION_GET,
  ACTION_LIST,
  ACTION_LIST_MEMBERS,
  ACTION_UPDATE,
  ACTION_UPDATE_MEMBERS,
  ACTION_READ,
} from '../utils/permission-types/index';

/**
 *
 * This class is used as a base class for every "ability" in the ui. By extending
 * this class, your new ability will get the basic IAM permissions checks for free.
 * Any additional checks or overrides can be made in the extended class.
 *
 *
 * Basic Usage:
 * ```
 * import BaseAbility from 'authz/abilities/base-ability';
 * import { PREFIX_IAM_INVITATIONS } from 'authz/utils/permission-types/index';
 *
 * export default class extends BaseAbility {
 *   prefix = PREFIX_IAM_INVITATIONS;
 * }
 * ```
 *
 * Implementation:
 * `{{if (can 'create invitation')}}`
 *
 * @class BaseAbility
 * @see {@link https://github.com/minutebase/ember-can}
 *
 */
export default class BaseAbility extends Ability {
  @service permissions;

  prefix = '';

  /**
   * A proxy to the permissions service to generate a permission string. This
   * mixes the ability's `prefix` value with a supplied action string to return
   * a permissions string based on the `generate` logic. As of now, the logic
   * simply combines them into dot notation. For example, if a prefix is
   * 'iam.invitations' and an action is 'create' then the resulting string should
   * be 'iam.invitations.create'. This allows us to set new prefixes on classes
   * but leave the action getters untouched.
   *
   * @param {string} permissionAction - A hashicorp.cloud.resourcemanager RBAC suffix.
   * @return {boolean}
   */
  generate(permissionAction) {
    return this.permissions.generate(this.prefix, permissionAction);
  }

  get canCreate() {
    return this.permissions.has(this.generate(ACTION_CREATE));
  }

  get canDelete() {
    return this.permissions.has(this.generate(ACTION_DELETE));
  }

  get canGet() {
    return this.permissions.has(this.generate(ACTION_GET));
  }

  get canRead() {
    return this.permissions.has(this.generate(ACTION_READ));
  }

  get canList() {
    return this.permissions.has(this.generate(ACTION_LIST));
  }

  get canUpdate() {
    return this.permissions.has(this.generate(ACTION_UPDATE));
  }

  get canUpdateMembership() {
    return this.permissions.has(this.generate(ACTION_UPDATE_MEMBERS));
  }

  get canListMembers() {
    return this.permissions.has(this.generate(ACTION_LIST_MEMBERS));
  }
}
