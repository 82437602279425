import userScenario from './user';
import { PROVIDER_DETAILS } from '../helpers/workload-identity-provider';
export default function (
  server,
  options = {
    createWorkloadIdentityProviders: false,
  }
) {
  userScenario(server, {
    createBilling: true,
    createServicePrincipals: { project: true },
  });

  let project = server.schema.first('resource-manager.project');

  // WorkloadIdentityProviders are only at the Project level
  let projectServicePrincipalOne = server.schema.findBy(
    'service-principal.service-principal',
    { projectId: project.id }
  );

  let { projectId, name } = projectServicePrincipalOne;
  if (options.createWorkloadIdentityProviders) {
    PROVIDER_DETAILS.map((provider) => {
      //Create a wip for each type of provider
      server.create('service-principal.service-principal-wip', {
        $servicePrincipalName: name,
        $projectId: projectId,
        $provider: provider,
      });
    });
  }
}
