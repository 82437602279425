import billingEntitlement from './billing-entitlement';
import getPlatformContext from '../helpers/get-platform-context';

export default function (server) {
  billingEntitlement(server);

  let { organization, project } = getPlatformContext(server);

  let billingAccount = server.schema.findBy('billing-account.billing-account', {
    organizationId: organization.id,
    projectIds: [project.id],
  });

  server.create('billing-account.contract', 'upcoming', {
    organization,
    billing_account: billingAccount,
  });

  server.create('billing-account.transition', 'upcoming', 'flexTransition', {
    billing_account: billingAccount,
  });
}
