import baseScenario from './user';

export default function (server) {
  baseScenario(server);
  let organization = server.schema.first('resource-manager.organization');
  server.create('auth-config.auth-connection', {
    organization,
    id: '123',
    issuer: 'https://oktasignin.local',
    email_domain: 'mysite.local',
    client_id: 'abc-123',
    created_at: new Date(),
    updated_at: new Date(),
    default_role: 'roles/admin',
  });
  let user = server.schema.findBy('iam.user-principal', {
    current_user: true,
  });
  user.update({
    identity_types: ['HASHICORP_SSO'],
  });
}
