import userScenario from './user';

export default function (server) {
  userScenario(server, {
    createBilling: true,
    hasVerifiedEmail: true,
  });

  const organization = server.schema.findBy('resource-manager.organization', {
    name: 'default-org',
  });

  const project = server.schema.findBy('resource-manager.project', {
    parent: { type: 'ORGANIZATION', id: organization.id },
  });
}
