import { faker } from '@faker-js/faker';

import {
  HashicorpCloudResourcemanagerPolicyBindingMemberType,
  ProjectServiceGetProjectsCountScopeTypeEnum,
} from '@clients/cloud-resource-manager';
import { HashicorpCloudIamPrincipalType } from '@clients/cloud-iam';

import createRoles from '../helpers/role-assignments/create-roles';
import organizationRoles from '../helpers/role-assignments/organization-roles-list';
import projectRoles from '../helpers/role-assignments/resource-project-roles-list';

export const HOGWARTS = {
  ORGANIZATION_ID: '4988ada2-cb2c-4ca5-9e44-e129da0e1cb8',
  ORGANIZATION_NAME: 'Hogwarts School of Witchcraft and Wizardry',
};
export const USERS = {
  DUMBLEDORE: {
    id: '5555f5f5-8c88-4275-85fb-b8ad856717cb',
    email: 'albus@hogwarts.edu',
    full_name: 'Albus Dumbledore',
  },
  HARRY_POTTER: {
    id: '6666f5f5-8c88-4275-85fb-b8ad856717cb',
    email: 'harry@hogwarts.edu',
    full_name: 'Harry Potter',
  },
  DRACO_MALFOY: {
    id: '7777f5f5-8c88-4275-85fb-b8ad856717cb',
    email: 'draco@hogwarts.edu',
    full_name: 'Draco Malfoy',
  },
  TOM_RIDDLE: {
    id: '8888f5f5-8c88-4275-85fb-b8ad856717cb',
    email: 'voldemort@horcrux.net',
    full_name: 'Tom Riddle',
  },
};
export const GROUPS = {
  SLYTHERIN: {
    display_name: 'Slytherin 🐍',
    resource_name: `iam/organization/4988ada2-cb2c-4ca5-9e44-e129da0e1cb8/group/Slytherin`,
    resource_id: 'iam.group:iloveSnakes420',
    description: 'We sure love snakes and potions',
    member_principal_ids: [USERS.DRACO_MALFOY.id, USERS.TOM_RIDDLE.id],
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  },
};
export const PROJECTS = {
  GRYFFINDOR_QUIDDICH: {
    id: '97cff5f5-8c88-4275-85fb-b8ad856717cf',
    name: 'Gryffindor Quidditch Team',
  },
  CHAMBER_OF_SECRETS: {
    id: '97cff5f5-8e88-4975-85fa-b8ad456717cb',
    name: '',
  },
};

export default function (server) {
  // Create user principals
  server.create('iam.user-principal', {
    ...USERS.DUMBLEDORE,
    current_user: true,
  });
  server.create('iam.user-principal', {
    ...USERS.HARRY_POTTER,
    current_user: false,
  });
  server.create('iam.user-principal', {
    ...USERS.DRACO_MALFOY,
    current_user: false,
  });
  server.create('iam.user-principal', {
    ...USERS.TOM_RIDDLE,
    current_user: false,
  });

  const dumbledoreUser = server.schema.findBy('iam.user-principal', {
    id: USERS.DUMBLEDORE.id,
  });
  const harryPotterUser = server.schema.findBy('iam.user-principal', {
    id: USERS.HARRY_POTTER.id,
  });
  const dracoMalfoyUser = server.schema.findBy('iam.user-principal', {
    id: USERS.DRACO_MALFOY.id,
  });
  const voldemortUser = server.schema.findBy('iam.user-principal', {
    id: USERS.TOM_RIDDLE.id,
  });
  const slytherinGroup = server.create('iam.group', {
    ...GROUPS.SLYTHERIN,
  });

  // Create organization, projects, and memberships
  server.create('resource-manager.organization', {
    id: HOGWARTS.ORGANIZATION_ID,
    name: HOGWARTS.ORGANIZATION_NAME,
    owner: dumbledoreUser,
  });

  const hogwartsOrganization = server.schema.findBy(
    'resource-manager.organization',
    {
      id: HOGWARTS.ORGANIZATION_ID,
    }
  );

  server.create('resource-manager.project', {
    id: PROJECTS.GRYFFINDOR_QUIDDICH.id,
    parent: {
      type: ProjectServiceGetProjectsCountScopeTypeEnum.ORGANIZATION,
      id: hogwartsOrganization.id,
    },
    name: PROJECTS.GRYFFINDOR_QUIDDICH.name,
    description: 'An initial project',
  });

  server.create('resource-manager.project', {
    id: PROJECTS.CHAMBER_OF_SECRETS.id,
    parent: {
      type: ProjectServiceGetProjectsCountScopeTypeEnum.ORGANIZATION,
      id: hogwartsOrganization.id,
    },
    name: PROJECTS.CHAMBER_OF_SECRETS.name,
    description: 'An initial project',
  });

  const gryffindorQuiddichTeamProject = server.schema.findBy(
    'resource-manager.project',
    {
      id: PROJECTS.GRYFFINDOR_QUIDDICH.id,
    }
  );

  const chamberOfSecretsProject = server.schema.findBy(
    'resource-manager.project',
    {
      id: PROJECTS.CHAMBER_OF_SECRETS.id,
    }
  );

  server.create('iam.organization-membership', {
    created_at: '2024-09-01T22:06:17.324Z',
    organization: hogwartsOrganization,
    principal: dumbledoreUser,
  });
  server.create('iam.organization-membership', {
    created_at: '2024-09-01T22:06:17.324Z',
    organization: hogwartsOrganization,
    principal: harryPotterUser,
  });
  server.create('iam.organization-membership', {
    created_at: '2024-09-01T22:06:17.324Z',
    organization: hogwartsOrganization,
    principal: dracoMalfoyUser,
  });
  server.create('iam.organization-membership', {
    created_at: '2024-09-01T22:06:17.324Z',
    organization: hogwartsOrganization,
    principal: voldemortUser,
  });

  // Policies
  server.create('resource-manager.organization.policy', {
    organization: hogwartsOrganization,
    policy: {
      bindings: [
        {
          role_id: 'roles/owner',
          members: [
            {
              member_id: dumbledoreUser.id,
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
            },
          ],
        },
        { role_id: 'roles/admin', members: [] },
        {
          role_id: 'roles/contributor',
          members: [
            {
              member_id: harryPotterUser.id,
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
            },
          ],
        },
        {
          role_id: 'roles/viewer',
          members: [
            {
              member_id: dracoMalfoyUser.id,
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
            },
          ],
        },
      ],
      etag: faker.string.alphanumeric(51),
    },
  });

  server.create('resource-manager.project.policy', {
    project: gryffindorQuiddichTeamProject,
    policy: {
      bindings: [
        {
          role_id: 'roles/admin',
          members: [
            {
              member_id: harryPotterUser.id,
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
            },
          ],
        },
      ],
      etag: faker.string.alphanumeric(51),
    },
  });

  server.create('resource-manager.project.policy', {
    project: chamberOfSecretsProject,
    policy: {
      bindings: [
        {
          role_id: 'roles/admin',
          members: [
            {
              member_id: voldemortUser.id,
              member_type: HashicorpCloudIamPrincipalType.USER,
            },
          ],
        },
        {
          role_id: 'roles/viewer',
          members: [
            {
              member_id: harryPotterUser.id,
              member_type: HashicorpCloudIamPrincipalType.USER,
            },
            {
              member_id: slytherinGroup.resource_id,
              member_type: HashicorpCloudIamPrincipalType.GROUP,
            },
          ],
        },
      ],
      etag: faker.string.alphanumeric(51),
    },
  });
  const resource = server.create('resource-manager.resourceV2', {
    name: 'test-resource',
    id: 'test-id',
  });
  server.create('resource-manager.resource.policy', {
    project: gryffindorQuiddichTeamProject,
    $resource_name: `test-resource/project/${gryffindorQuiddichTeamProject.id}/app/test-id`,
    resource,
    policy: {
      bindings: [
        {
          role_id: 'roles/admin',
          members: [
            {
              member_id: dumbledoreUser.id,
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
            },
          ],
        },
        {
          role_id: 'roles/contributor',
          members: [
            {
              member_id: harryPotterUser.id,
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
            },
          ],
        },
        {
          role_id: 'roles/viewer',
          members: [
            {
              member_id: dracoMalfoyUser.id,
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
            },
          ],
        },
      ],
    },
    etag: faker.string.alphanumeric(51),
  });

  // Add everyone to the group
  const slytherinGroupPolicy = {
    bindings: [
      {
        role_id: 'roles/iam.group-manager',
        // assign a group manager on group
        members: [
          {
            memberType: 'USER',
            memberId: dracoMalfoyUser.id,
          },
          {
            memberType: 'USER',
            memberId: voldemortUser.id,
          },
        ],
      },
    ],
    etag: faker.string.alphanumeric(51),
  };
  // Group IAM policy - only adds iam.user-principals to the group resource policy after some action to assign roles in the UI
  server.create('resource-manager.resource.policy', {
    $resource_name: GROUPS.SLYTHERIN.resource_name,
    policy: slytherinGroupPolicy,
  });
  server.create('resource-manager.resource.allowed-permission', {
    $resource_name: GROUPS.SLYTHERIN.resource_name,
    $principal_id: dracoMalfoyUser.id,
    allowed_permissions: [
      'iam.groups.update',
      'iam.groups.update-members',
      'iam.groups.list-members',
    ],
  });
  server.create('resource-manager.resource.allowed-permission', {
    $resource_name: GROUPS.SLYTHERIN.resource_name,
    $principal_id: voldemortUser.id,
    allowed_permissions: [
      'iam.groups.update',
      'iam.groups.update-members',
      'iam.groups.list-members',
    ],
  });
  server.create('resource-manager.resource.allowed-permission', {
    $resource_name: GROUPS.SLYTHERIN.resource_name,
    $principal_id: dumbledoreUser.id,
    allowed_permissions: [
      'iam.groups.update',
      'iam.groups.update-members',
      'iam.groups.list-members',
    ],
  });

  // Register roles
  createRoles(server, 'role.role.organization', organizationRoles);
  createRoles(server, 'role.role.project', projectRoles);
  createRoles(server, 'role.role.test-resource', [
    {
      id: 'roles/owner',
      version: 0,
      title: 'Owner',
      description:
        'Has all admin permissions and the ability to delete the resource, and promote/demote other owners',
      permissions: [
        'test-resource.update',
        'test-resource.create',
        'test-resource.get',
        'test-resource.delete',
        'test-resource.list',
      ],
      lowest_applicable_resource_types: [],
    },
    {
      id: 'roles/admin',
      version: 0,
      title: 'Admin',
      description: 'Full access pretty much',
      permissions: [
        'test-resource.update',
        'test-resource.create',
        'test-resource.get',
        'test-resource.delete',
        'test-resource.list',
        'resource-manager.organizations.get-iam-policy',
      ],
      lowest_applicable_resource_types: [],
    },
    {
      id: 'roles/contributor',
      version: 0,
      title: 'Contributor',
      description: 'Can do a lot, but not everything',
      permissions: [
        'test-resource.update',
        'test-resource.create',
        'test-resource.get',
        'test-resource.list',
      ],
      lowest_applicable_resource_types: [],
    },
    {
      id: 'roles/viewer',
      version: 0,
      title: 'Viewer',
      description: 'Can view some things',
      permissions: ['test-resource.get', 'test-resource.list'],
      lowest_applicable_resource_types: [],
    },
  ]);
}
