import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { variation } from 'ember-launch-darkly';
import type IntlService from 'ember-intl/services/intl';

import cloudIamRbacRoles, {
  ROLE_KEY_BROWSER,
} from '../utils/cloud-iam-rbac-roles.ts';
export * from '../utils/cloud-iam-rbac-roles.ts';
import {
  TYPE_ORGANIZATION,
  TYPE_PROJECT,
} from 'common/utils/cloud-resource-types';

const RoleType = {
  Organization: TYPE_ORGANIZATION,
  Project: TYPE_PROJECT,
} as const;

type RoleType = (typeof RoleType)[keyof typeof RoleType];

export default class RbacRoles extends Helper {
  @service declare readonly intl: IntlService;

  @action
  compute(
    [roleId]: [string | undefined],
    {
      active,
      type = RoleType.Organization,
    }: { active?: boolean; type?: RoleType }
  ) {
    let roles = cloudIamRbacRoles.map(
      ({ description, label, summary, ...role }) => {
        return {
          ...role,
          description: this.intl.t(description),
          label: this.intl.t(label),
          ...(summary
            ? {
                summary: summary.map(({ message, ...bullet }) => {
                  return {
                    ...bullet,
                    message: this.intl.t(message),
                  };
                }),
              }
            : {}),
        };
      }
    );

    if (type) {
      roles = roles.filter((role) => {
        return role.type === type;
      });
    }

    if (roleId) {
      return roles.find((role) => role.value === roleId);
    }

    if (active !== undefined) {
      roles = roles.filter((role) => {
        return role.active === active;
      });
    }

    if (!variation('hcp-ui-browser-role-enabled')) {
      roles = roles.filter((role) => role.value !== ROLE_KEY_BROWSER);
    }

    return roles;
  }
}
