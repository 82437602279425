import consulScenario from './consul';
import { DateTime } from 'luxon';
import {
  HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus,
  HashicorpCloudGlobalNetworkManager20220215TrialStatus,
  HashicorpCloudGlobalNetworkManager20220215Licensing,
  HashicorpCloudGlobalNetworkManager20220215PeeringIneligibilityReason,
  HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevel,
  HashicorpCloudGlobalNetworkManager20220215ClusterState,
} from '@clients/cloud-global-network-manager-service';
import { BILLING_ACCOUNT_STATUS } from 'billing-common/constants/status';
import { findLocationIdsForProject } from '../helpers/multi-network';

const startDate = DateTime.now().minus({ days: 93 }).toJSDate();
const endDate = DateTime.now().minus({ days: 3 }).toJSDate();

export default function (
  server,
  options = {
    billingAccountStatus: BILLING_ACCOUNT_STATUS.TRIAL,
  }
) {
  // Set up consul scenario.
  consulScenario(server, options);

  const currentUser = server.schema.findBy('iam.user-principal', {
    current_user: true,
  });
  const org = server.schema.findBy('resource-manager.organization', {
    ownerId: currentUser.id,
  });
  const location = server.schema.findBy('location.location', {
    organizationId: org.id,
  });
  const project = server.schema.findBy('resource-manager.project', {
    parent: {
      type: 'ORGANIZATION',
      id: org.id,
    },
  });
  const locationIds = findLocationIdsForProject(server.schema, {
    organizationId: org.id,
    projectId: project.id,
  });

  // Setting Consul Community Trial in the org
  const trialInfo = server.schema.findBy(
    'consul-global-network-manager.trial',
    {
      organization_id: location.organizationId,
    }
  );
  trialInfo.update({
    trial_status: HashicorpCloudGlobalNetworkManager20220215TrialStatus.EXPIRED,
    start_date: startDate,
    end_date: endDate,
  });

  let communityClusterExpiredTrial = server.create(
    'consul-global-network-manager.cluster',
    {
      id: 'community-cluster-expired-trial',
      name: 'community-cluster-expired-trial',
      consul_version: 'v1.16.1',
      location: location,
      licensing: HashicorpCloudGlobalNetworkManager20220215Licensing.COMMUNITY,
      peering_acceptor_ineligibility_codes: [
        HashicorpCloudGlobalNetworkManager20220215PeeringIneligibilityReason.INVALIDLICENSE,
      ],
    }
  );
  server.createList('consul-global-network-manager.cluster.server', 3, {
    cluster: communityClusterExpiredTrial,
  });

  // Create a Self-managed cluster with pending status and unspecified license
  server.create('consul-global-network-manager.cluster', {
    id: 'unspecified-community-trial-cluster',
    name: 'unspecified-community-trial-cluster',
    location: location,
    state: HashicorpCloudGlobalNetworkManager20220215ClusterState.PENDING,
    licensing: HashicorpCloudGlobalNetworkManager20220215Licensing.UNSPECIFIED,
    consul_version: '',
  });

  const clusters = server.schema.where(
    'consul-global-network-manager.cluster',
    (cluster) => locationIds.includes(cluster.locationId)
  );

  const source_cluster = clusters.models.find((c) => c.id === 'test-cluster');
  const target_cluster = clusters.models.find(
    (c) => c.id === 'community-cluster-expired-trial'
  );
  // Updating HCP-managed cluster model with licensing
  source_cluster.update({
    licensing: HashicorpCloudGlobalNetworkManager20220215Licensing.ENTERPRISE,
  });
  const sourceClusterLocation = server.schema.findBy('location.location', {
    organizationId: source_cluster.location.organizationId,
    projectId: source_cluster.location.projectId,
    regionId: source_cluster.location.regionId,
  });

  server.create('consul-global-network-manager.cluster.peer', {
    source_cluster,
    source_partition: `${source_cluster.id}-ap`,
    source_licensing: source_cluster.licensing,
    target_cluster: target_cluster,
    target_partition: `${target_cluster.id}-ap`,
    target_licensing: target_cluster.licensing,
    peer_name: `${source_cluster.id}-${target_cluster.id}`,
    peering_connection_status:
      HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus.UNSYNCED,
    location: sourceClusterLocation,
    peer_licensing: target_cluster.licensing,
  });

  // Read-only cluster
  let readOnlySelfManagedConsulCluster = server.create(
    'consul-global-network-manager.cluster',
    {
      id: 'read-only-with-expired-trial',
      name: 'read-only-with-expired-trial',
      consul_access_level:
        HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevel.GLOBALREADONLY,
      consul_version: 'v1.15.3',
      location: location,
      licensing: HashicorpCloudGlobalNetworkManager20220215Licensing.COMMUNITY,
      peering_acceptor_ineligibility_codes: [
        HashicorpCloudGlobalNetworkManager20220215PeeringIneligibilityReason.INVALIDLICENSE,
      ],
    }
  );
  server.createList('consul-global-network-manager.cluster.server', 3, {
    cluster: readOnlySelfManagedConsulCluster,
  });

  // Observability eligible cluster whose details are still pending
  let detailsPendingObservabilityEligibleCluster = server.create(
    'consul-global-network-manager.cluster',
    {
      id: 'version-licensing-pending',
      name: 'version-licensing-pending',
      consul_access_level:
        HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevel.GLOBALREADONLY,
      consul_version: '',
      location: location,
      licensing:
        HashicorpCloudGlobalNetworkManager20220215Licensing.UNSPECIFIED,
    }
  );
  server.createList('consul-global-network-manager.cluster.server', 3, {
    cluster: detailsPendingObservabilityEligibleCluster,
  });
}
