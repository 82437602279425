// @ts-nocheck
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface MirageDevToolsSecretsSignature {
  Args: Record<string, never>;
  Blocks: [];
  Element: null;
}

interface MirageRoleOptions {
  organization?: {
    role: string;
  };
  project?: {
    role: string;
  };
}

const STORAGE_SLUG = 'mirage::hvs-role';

export default class MirageDevToolsSecretsComponent extends Component<MirageDevToolsSecretsSignature> {
  @tracked selectedRoleOrgRoleId = '';
  @tracked selectedRoleProjRoleId = '';

  constructor(owner: unknown, args: MirageDevToolsSecretsSignature['Args']) {
    super(owner, args);

    const storedRoleJSON = sessionStorage.getItem(STORAGE_SLUG);
    if (!storedRoleJSON) return;
    const { organization, project }: MirageRoleOptions =
      JSON.parse(storedRoleJSON);

    if (organization?.role) {
      this.selectedRoleOrgRoleId = organization?.role;
    }

    if (project?.role) {
      this.selectedRoleProjRoleId = project?.role;
    }
  }

  availableRoles = [
    {
      id: 'owner',
      name: 'Owner',
    },
    {
      id: 'admin',
      name: 'Admin',
    },
    {
      id: 'contributor',
      name: 'Contributor',
    },
    {
      id: 'viewer',
      name: 'Viewer',
    },
    {
      id: 'secrets.app-manager',
      name: 'Vault Secrets App Manager',
    },
    {
      id: 'secrets.app-secret-reader',
      name: 'Vault Secrets App Secret Reader',
    },
    {
      id: 'no-role',
      name: 'No role',
    },
  ];

  get overrideIsActive() {
    return this.selectedRoleOrgRoleId || this.selectedRoleProjRoleId;
  }

  reset = () => {
    sessionStorage.removeItem(STORAGE_SLUG);
  };

  @action
  onChange(scope: string, roleId: string) {
    switch (scope) {
      case 'organization':
        this.selectedRoleOrgRoleId = roleId;
        break;
      case 'project':
        this.selectedRoleProjRoleId = roleId;
        break;
    }
  }

  @action
  onReset() {
    const ok = confirm(
      'Are you sure you want to reset mirage permissions to the scenario defaults?'
    );

    if (ok) {
      this.reset();
      location.reload();
    }
  }

  @action
  submit(e: Event) {
    e.preventDefault();

    if (!this.selectedRoleOrgRoleId && !this.selectedRoleProjRoleId) {
      // reset thngs
    } else {
      const payload: MirageRoleOptions = {};

      if (this.selectedRoleOrgRoleId)
        payload.organization = { role: this.selectedRoleOrgRoleId };
      if (this.selectedRoleProjRoleId)
        payload.project = { role: this.selectedRoleProjRoleId };

      sessionStorage.setItem(STORAGE_SLUG, JSON.stringify(payload));
    }

    location.reload();
  }
}
