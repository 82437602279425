import AuthzBuilder from 'common/utils/authz';

const VagrantFeatures = {
  Architecture: 'architecture',
  Box: 'box',
  BoxFile: 'boxfile',
  Index: 'index',
  Provider: 'provider',
  Registry: 'registry',
  Version: 'version',
};

export function allPermissions() {
  const builder = new AuthzBuilder('vagrant', VagrantFeatures);
  builder.addCRUDActions(VagrantFeatures.Architecture);
  builder.addCRUDLActions(VagrantFeatures.Box, [
    'getregistryboxforbillableboxid',
    'activate',
    'deactivate',
    'createbillableboxid',
    'deactivatebillableboxid',
    'getbillableidforbox',
    'deleteboxrecord',
    'setstateforbox',
  ]);
  builder.addCRUDActions(VagrantFeatures.BoxFile, [
    'download',
    'upload',
    'complete',
  ]);
  builder.addActions(VagrantFeatures.Index, ['search']);
  builder.addCRUDLActions(VagrantFeatures.Provider);
  builder.addCRUDLActions(VagrantFeatures.Version, ['release', 'revoke']);

  builder.addCRUDLActions(VagrantFeatures.Registry);

  return builder;
}

export function contributorPermissions() {
  const builder = new AuthzBuilder('vagrant', VagrantFeatures);
  builder.addCRUDActions(VagrantFeatures.Architecture);
  builder.addCRUDLActions(VagrantFeatures.Box, ['activate', 'deactivate']);
  builder.addCRUDActions(VagrantFeatures.BoxFile, [
    'download',
    'upload',
    'complete',
  ]);
  builder.addActions(VagrantFeatures.Index, ['search']);
  builder.addCRUDLActions(VagrantFeatures.Provider);
  builder.addCRUDLActions(VagrantFeatures.Version, ['release', 'revoke']);

  builder.addCRUDLActions(VagrantFeatures.Registry);

  return builder;
}

export function viewerPermissions() {
  const builder = new AuthzBuilder('vagrant', VagrantFeatures);
  builder.addRLActions(VagrantFeatures.Architecture);
  builder.addRLActions(VagrantFeatures.Box);
  builder.addRLActions(VagrantFeatures.BoxFile);
  builder.addActions(VagrantFeatures.Index, ['search']);
  builder.addRLActions(VagrantFeatures.Provider);
  builder.addRLActions(VagrantFeatures.Version, ['release', 'revoke']);
  builder.addRLActions(VagrantFeatures.Registry);

  return builder;
}

// Eagerly generate flat permissions list since this is the most common use case.
export const ALL_PERMISSIONS = allPermissions().list();
export const CONTRIBUTOR_PERMISSIONS = contributorPermissions().list();
export const VIEWER_PERMISSIONS = viewerPermissions().list();
