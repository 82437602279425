import userScenario from './user';

let options = {
  createBilling: true,
  hashicorpSsoConfigured: true,
  scimEnabled: false,
  hasNoOwnedOrg: true,
  hasMultipleOrgMemberships: false,
};

export default function (server) {
  return userScenario(server, options);
}
